import React, { useState } from 'react';

import { useLocation } from "react-router-dom";


import { message, Layout } from 'antd';


import { SettingsSidebarSection, SideBarSection } from '../sections';
import { useAppSelector } from '../redux';
import { useUser } from '../context/UserDataContext';


const { Sider, Content } = Layout;



export default function SiderLayout({ outlet }) {


    const { userData } = useUser();
    const { pathname } = useLocation();



    const [messageApi, contextHolder] = message.useMessage();


    const [showSettingsSidebar, setShowSettingsSidebar] = useState(false);



    const {
        userData: reduxUserData,
        contacts: reduxContacts,
        currentFolder: reduxCurrentFolder,
        conversations: reduxConversations,
        groupedFolderData: reduxGroupedFolderData,
        currentSectionToShow: reduxCurrentSectionToShow
    } = useAppSelector((state => state.app));




    console.log('reduxUserData in LAYOUT =========>>>>', reduxGroupedFolderData);


    const shouldNotRenderSidebar = (pathname.includes("/settings") || pathname.includes("/new-message"));


    return (
        <Layout style={{ height: "100vh", backgroundColor: "transparent", background: "transparent" }}>

            <Sider
                trigger={null}
                width={400}
                className="sidebar-bg"
                style={{ backgroundColor: "transparent", background: "transparent" }}
            >
                {showSettingsSidebar ? (
                    <SettingsSidebarSection
                        setShowSettingsSidebar={setShowSettingsSidebar}
                        name={reduxUserData?.userProfile?.name}
                        profileUrl={reduxUserData?.userProfile?.profile_picture}
                    />
                ) : (
                    <SideBarSection
                        bills={reduxUserData?.userProfile?.bills}
                        allContacts={reduxContacts}
                        currentSectionToShow={reduxCurrentSectionToShow}
                        foldersData={reduxGroupedFolderData}
                        shouldNotRenderSidebar={shouldNotRenderSidebar}
                        name={reduxUserData?.userProfile?.name}
                        conversations={reduxConversations}
                        contact={reduxUserData?.userProfile?.number}
                        profileUrl={reduxUserData?.userProfile?.profile_picture}
                        setShowSettingsSidebar={setShowSettingsSidebar}
                    />
                )}
            </Sider>

            <Layout style={{ backgroundColor: "transparent", background: "transparent" }}>
                {contextHolder}
                <Content style={{ height: "100%", overflow: "auto", minHeight: "100%" }}>
                    {outlet}
                </Content>
            </Layout>

        </Layout>
    )
}
