import React from 'react'

export default function DeleteIcon() {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" viewBox="0 0 13 17" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M10.6435 4.95312H2.06097C1.58719 4.95312 1.20312 5.33719 1.20312 5.81097V12.6768C1.20313 14.0987 2.35579 15.2513 3.77768 15.2513H8.92678C9.6096 15.2513 10.2644 14.9801 10.7473 14.4973C11.2301 14.0144 11.5013 13.3596 11.5013 12.6768V5.81097C11.5013 5.33719 11.1173 4.95312 10.6435 4.95312Z" stroke="white" strokeWidth="1.54473" strokeLinecap="round" strokeLinejoin="round" />
          <path fillRule="evenodd" clipRule="evenodd" d="M9.44455 2.89371L9.33539 2.67642C8.77095 1.54727 7.61694 0.833984 6.35457 0.833984C5.0922 0.833984 3.9382 1.54727 3.37376 2.67642L3.26562 2.89371H9.44455Z" stroke="white" strokeWidth="1.54473" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M5.41091 8.38362C5.41091 7.95705 5.06511 7.61125 4.63854 7.61125C4.21198 7.61125 3.86618 7.95705 3.86618 8.38362H5.41091ZM3.86618 11.816C3.86618 12.2426 4.21198 12.5884 4.63854 12.5884C5.06511 12.5884 5.41091 12.2426 5.41091 11.816H3.86618ZM8.83828 8.38362C8.83828 7.95705 8.49248 7.61125 8.06592 7.61125C7.63935 7.61125 7.29355 7.95705 7.29355 8.38362H8.83828ZM7.29355 11.816C7.29355 12.2426 7.63935 12.5884 8.06592 12.5884C8.49248 12.5884 8.83828 12.2426 8.83828 11.816H7.29355ZM9.44169 2.12021C9.01513 2.12021 8.66933 2.46601 8.66933 2.89258C8.66933 3.31914 9.01513 3.66494 9.44169 3.66494V2.12021ZM11.5013 3.66494C11.9279 3.66494 12.2737 3.31914 12.2737 2.89258C12.2737 2.46601 11.9279 2.12021 11.5013 2.12021V3.66494ZM3.26277 3.66494C3.68933 3.66494 4.03513 3.31914 4.03513 2.89258C4.03513 2.46601 3.68933 2.12021 3.26277 2.12021V3.66494ZM1.20312 2.12021C0.776559 2.12021 0.430759 2.46601 0.430759 2.89258C0.430759 3.31914 0.776559 3.66494 1.20312 3.66494V2.12021ZM3.86618 8.38362V11.816H5.41091V8.38362H3.86618ZM7.29355 8.38362V11.816H8.83828V8.38362H7.29355ZM9.44169 3.66494H11.5013V2.12021H9.44169V3.66494ZM3.26277 2.12021H1.20312V3.66494H3.26277V2.12021Z" fill="white" />
      </svg>
  )
}
