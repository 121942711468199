import React from 'react'

export default function UserPlaceholderIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
            <g filter="url(#filter0_d_633_28271)">
                <circle cx="36.3093" cy="36.2627" r="26.0359" fill="#262B23" />
                <path d="M36.3598 39.3166C41.6892 39.3166 46.0057 35.0754 46.0057 29.8391C46.0057 24.6027 41.6892 20.3615 36.3598 20.3615C31.0304 20.3615 26.7138 24.6027 26.7138 29.8391C26.7138 35.0754 31.0304 39.3166 36.3598 39.3166ZM36.3598 44.0553C29.9211 44.0553 20.2876 46.8487 19.1875 52.4096C19.1875 52.4096 25.7236 60.0096 36.5958 59.7254C47.4679 59.4413 53.4762 52.2847 53.4762 52.2847C50.6151 45.7042 42.7985 44.0553 36.3598 44.0553Z" fill="#86FFC6" />
            </g>
            <defs>
                <filter id="filter0_d_633_28271" x="0.80585" y="0.759219" width="71.0055" height="71.0069" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="4.73379" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_633_28271" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_633_28271" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}
