import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga";

import { Provider } from "react-redux";
import { APP_STORE } from "./redux";

/******** Components ********/
import App from "./App";

import reportWebVitals from "./reportWebVitals";

/******** Styles ********/
import "./styles/main.scss";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { AppThemeProvider } from "./context/theme";
import { SnackbarProvider } from "notistack";
import { UserProvider } from "./context/UserDataContext";
import { WebSocketProvider } from "./context/WebSocket";

// ----------------------------------------------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={APP_STORE}>
      <AppThemeProvider>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          classes={{
            success: "notistack-MuiContent-success",
            error: "notistack-MuiContent-error",
          }}
        >
          <UserProvider>
            <WebSocketProvider>
              <App />
            </WebSocketProvider>
          </UserProvider>
        </SnackbarProvider>
      </AppThemeProvider>
    </Provider>
  </BrowserRouter>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// ReactGA.initialize('config', 'AW-10779246142');
// ReactGA.initialize('config', 'AW-10779246142');
// ReactGA.initialize('G-S46T5E296C');
ReactGA.initialize("G-S46T5E296C");
ReactGA.initialize("G-HMV3LHQHG4");
