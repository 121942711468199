import React, { useState } from "react";

import { useNavigate } from "react-router-dom";


import { useUser } from "../../context/UserDataContext";
import { useWebSocket } from "../../context/WebSocket";
import { useAppSelector } from "../../redux";


import "../../styles/Settings.scss";

import userPlaceholder2 from "../../assets/useIcon2.svg";
import SearchIcon from "../../assets/images/SideBar/search-icon.svg";
import BackButtonIcon from "../../assets/images/Chat/BackButtonIcon.svg";
import { ProfileSection } from "./Profile";
import { BlockedAccountsSection } from "./BlockedAccounts";
import { AppActionModal } from "../../components";



// ----------------------------------------------------------------------------------------------------------------




export const SettingsSidebarSection  = (props) => {

  const { name, profileUrl, setShowSettingsSidebar } = props;



  const navigate = useNavigate();
  const { socketRef, setIsFetchingData } = useWebSocket();
  const { updateUserData } = useUser();



  const [showProfile, setShowProfile] = useState(false);
  const [showBlocked, setShowBlocked] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);


  const { initialData: appReduxData } = useAppSelector((state => state.app));



  const handleLogout = () => {
    socketRef.current = '';
    updateUserData('');
    sessionStorage.removeItem("userData");
    localStorage.removeItem("userData");
    localStorage.removeItem("qrToken");
    localStorage.removeItem("ip");
    localStorage.removeItem("port");
    setIsFetchingData(false);
    navigate("/");
    window.location.reload();
  }



  //  ================== GETTING UPDATED PROFILE DATA ==================
  // useEffect(() => {

  //   const getProfileData = async () => {

  //     const getResponseForProfile = await getProfile(appReduxData.userData.userToken);

  //     console.log('getResponseForProfile =========> ', getResponseForProfile);

  //   }


  //   getProfileData()



  // }, [appReduxData.userData.userToken])



  return (
    <>

      <AppActionModal
        heading="Logout"
        desc="Are you sure you want to logout?"
        cancelText="Cancel"
        confirmText="Yes"
        isOpenActionModal={isLogoutModalOpen}
        handleCloseModal={() => setIsLogoutModalOpen(false)}
        handleConfirm={handleLogout}
      />

      <div className="settings-div">
        <div className="settings-header-div">
          <div className="settings-title-and-back-btn-div">
            <img
              // onClick={() => dispatch(handleSectionToShow(SECTION_STATUS.CONVERSATION))}
              onClick={() => showProfile ? setShowProfile(false) : setShowSettingsSidebar(false)}
              src={BackButtonIcon}
              alt="back-button"
              style={{
                width: "12px",
                margin: "2px 2px 2px 20px",
                cursor: "pointer",
              }}
            />
            <p className="settings-title-text secondary-heading">
              {showProfile ? "Profile" : showBlocked ? "Blocked" : "Settings"}
            </p>
          </div>
          <img
            src={SearchIcon}
            alt="SearchIcon"
            style={{
              width: "25px",
              marginRight: "14px",
              display: showProfile ? "none" : showBlocked ? "block" : "block",
            }}
          />
        </div>
        {showProfile === true ? (
          <ProfileSection />
        ) : showBlocked ? (
            <BlockedAccountsSection />
            
        ) : (
          <>
            {" "}
            <div className="settings-item-div">
              <div className="settings-profile-card-div">
                <div style={{ borderRadius: '45px', height: '100%' }}>
                  <img src={profileUrl || userPlaceholder2} alt="profilePicture" className="profile-picture-2" />
                </div>


                <div className="profile-name-and-number-div">
                  <p className="name-text tertiary-heading">{name || "Salaam"}</p>
                  <p className="number-text secondary-text">{appReduxData?.userData?.userProfile?.number}</p>
                </div>
                <div className="profile-btn-div cursor-pointer" onClick={() => setShowProfile(true)}>Edit</div>
              </div>
              {/* <div className="web-and-blocked-card-div">
                <div
                  className="web-and-blocked-title-and-arrow-div"
                  onClick={() => setShowBlocked(true)}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <p className="web-and-blocked-title-text secondary-text">Blocked</p>
                  <img
                    src={ArrowIcon}
                    alt="ArrowIcon"
                    style={{ width: "7px" }}
                  />
                </div>
              </div> */}
              {/* <div
                className="accounts-to-notification-card-div"
                style={{
                  cursor: "pointer",
                }}
              >
                <div
                  className="accounts-to-notfication-title-and-arrow-div"
                // onClick={() => setShowProfile(true)}
                >
                  <p className="accounts-to-notfication-title-text secondary-text">Subscription</p>
                  <img
                    src={ArrowIcon}
                    alt="ArrowIcon"
                    style={{ width: "7px" }}
                  />
                </div>
                <div className="accounts-to-notfication-title-and-arrow-div">
                  <p className="accounts-to-notfication-title-text secondary-text">Privacy</p>
                  <img
                    src={ArrowIcon}
                    alt="ArrowIcon"
                    style={{ width: "7px" }}
                  />
                </div>
                <div className="accounts-to-notfication-title-and-arrow-div">
                  <p className="accounts-to-notfication-title-text secondary-text">
                    Help
                  </p>
                  <img
                    src={ArrowIcon}
                    alt="ArrowIcon"
                    style={{ width: "7px" }}
                  />
                </div>
                <div className="accounts-to-notfication-title-and-arrow-div">
                  <p className="accounts-to-notfication-title-text secondary-text">
                    Terms and Conditions
                  </p>
                  <img
                    src={ArrowIcon}
                    alt="ArrowIcon"
                    style={{ width: "7px" }}
                  />
                </div>
              </div> */}
              {/* <div className="help-and-share-card-div">
                <div className="help-and-share-title-and-arrow-div">
                  <p className="help-and-share-title-text secondary-text">Help</p>
                  <img
                    src={ArrowIcon}
                    alt="ArrowIcon"
                    style={{ width: "7px" }}
                  />
                </div>
                <div className="help-and-share-title-and-arrow-div">
                  <p className="help-and-share-title-text secondary-text">Share app</p>
                  <img
                    src={ArrowIcon}
                    alt="ArrowIcon"
                    style={{ width: "7px" }}
                  />
                </div>
              </div> */}
              <div className="logout-card-div" onClick={() => setIsLogoutModalOpen(true)}>
                <p className="logout-title-text secondary-text">Logout</p>
              </div>
            </div>
            {/* <div className="create-folder-button-container">
              <Button
                //   onClick={() => {
                //     props.open(true);
                //   }}
                className="create-folder-button secondary-heading"
              >
                Create Folder +
              </Button>
            </div> */}
          </>
        )}
      </div>
    </>
  );
};
