import React from 'react'

export default function UserIcon() {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M7.97702 3.89476C7.97702 5.22166 6.90135 6.29733 5.57445 6.29733C4.24754 6.29733 3.17188 5.22166 3.17188 3.89476C3.17188 2.56786 4.24754 1.49219 5.57445 1.49219C6.90135 1.49219 7.97702 2.56786 7.97702 3.89476Z" stroke="white" strokeWidth="1.54473" strokeLinecap="round" strokeLinejoin="round" />
          <path fillRule="evenodd" clipRule="evenodd" d="M10.3769 12.545C10.3769 14.3987 8.22565 15.9095 5.57077 15.9095C2.91589 15.9095 0.765625 14.4028 0.765625 12.545C0.765625 10.6872 2.91692 9.18164 5.5718 9.18164C8.22668 9.18164 10.3769 10.6872 10.3769 12.545Z" stroke="white" strokeWidth="1.54473" strokeLinecap="round" strokeLinejoin="round" />
          <path fillRule="evenodd" clipRule="evenodd" d="M13.0632 6.13902C13.0632 6.93527 12.4177 7.58076 11.6214 7.58076C10.8252 7.58076 10.1797 6.93527 10.1797 6.13902C10.1797 5.34276 10.8252 4.69727 11.6214 4.69727C12.0038 4.69727 12.3705 4.84916 12.6409 5.11954C12.9113 5.38992 13.0632 5.75664 13.0632 6.13902Z" stroke="white" strokeWidth="1.54473" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M12.3047 14.9481C13.7606 15.0677 15.0436 13.9982 15.1882 12.5445C15.0431 11.0913 13.7602 10.0224 12.3047 10.1419" stroke="white" strokeWidth="1.54473" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
  )
}
