import React from "react";


import { Navigate, useLocation } from "react-router-dom";
import { isAuthenticated } from "../api/requests";



// ----------------------------------------------------------------------------------------------------------


const PrivateRouter = ({ children }) => {

  const location = useLocation();

  return isAuthenticated() !== false ? (
    <>{children}</>
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
};

export default PrivateRouter;
