import React, { useEffect, useRef, useState } from 'react';





import dayjs from "dayjs";



/******** Full Calendar ********/
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import ResourcePlugin from "@fullcalendar/resource";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";



import '../../styles/calender.scss';
import { enqueueSnackbar } from 'notistack';




const scheduledMessagesData = [
    {
        id: '01',
        message: 'Hi, this is scheduled message.',
        start: '2023-12-14',
        end: '2023-12-15',
        time: '06:00:00',
        contactList: [
            {
                name: 'Ali',
                number: '+92 3211234567',
                contactId: '923211234567',
                isSection: false,
            },
            {
                name: 'Maaz Khan',
                number: '+92 3211234568',
                contactId: '923211234568',
                isSection: '',
            }
        ]
    },

    {
        id: '02',
        message: 'Hi, this is scheduled message.',
        start: '2023-12-14',
        end: '2023-12-15',
        time: '06:00:00',
        contactList: [
            {
                name: 'Ali',
                number: '+92 3211234567',
                contactId: '923211234567',
                isSection: false,
            },
            {
                name: 'Maaz Khan',
                number: '+92 3211234568',
                contactId: '923211234568',
                isSection: '',
            }
        ]
    },

    {
        id: '03',
        message: 'Hi, this is scheduled message.',
        start: '2023-12-14',
        end: '2023-12-15',
        time: '06:00:00',
        contactList: [
            {
                name: 'Ali',
                number: '+92 3211234567',
                contactId: '923211234567',
                isSection: false,
            },
            {
                name: 'Maaz Khan',
                number: '+92 3211234568',
                contactId: '923211234568',
                isSection: '',
            }
        ]
    },
    {
        id: '044',
        message: 'Hi, this is scheduled message.',
        start: '2023-12-14',
        end: '2023-12-15',
        time: '06:00:00',
        contactList: [
            {
                name: 'Ali',
                number: '+92 3211234567',
                contactId: '923211234567',
                isSection: false,
            },
            {
                name: 'Maaz Khan',
                number: '+92 3211234568',
                contactId: '923211234568',
                isSection: '',
            }
        ]
    },
    {
        id: '04',
        message: 'This is second scheduled message.',
        start: '2023-12-17',
        end: '2023-12-19',
        time: '06:00:00',
        contactList: [
            {
                name: 'Ali',
                number: '+92 3211234567',
                contactId: '923211234567',
                isSection: false,
            },
            {
                name: 'Maaz Khan',
                number: '+92 3211234568',
                contactId: '923211234568',
                isSection: '',
            }
        ]
    },

    {
        id: '05',
        message: 'This is second scheduled message.',
        start: '2023-12-20',
        end: '2023-12-20',
        time: '06:00:00',
        contactList: [
            {
                name: 'Ali',
                number: '+92 3211234567',
                contactId: '923211234567',
                isSection: false,
            },
            {
                name: 'Maaz Khan',
                number: '+92 3211234568',
                contactId: '923211234568',
                isSection: '',
            }
        ]
    },
]




export const ScheduleCalender = (props) => {


    const { selectedScheduledRecord, setSelectedScheduledRecord, schedulers } = props;


    const fullCalendarRef = useRef(null);




    const [workScheduleObj, setWorkScheduleObj] = useState({});
    const [eventModalData, setEventModalData] = useState({});




    const scheduledMessagesEvent = schedulers?.map((ele) => {

        return {
            id: ele?.id,
            title: ele?.message, // Change to the correct property
            resourceIds: [ele?.id], // Change to the correct property
            start: ele?.date, // Adjust the date and time properties
            // end: ele?.end, // Adjust the date and time properties
            // Add other necessary properties
        };
    });


    const scheduledMessagesResources = schedulers?.map((ele) => {

        return {
            id: ele?.id,
            title: ele?.message, // Change to the correct property
        };
    });


    const calendarDateClick = (e) => {
        // setWorkScheduleObj({
        //     startPerDate: e.dateStr,
        //     id: +e.resource._resource.id,
        // });
        // setSelectedScheduledRecord({ ...selectedScheduledRecord, isShowModal: true })

        const selectedDate = dayjs(e.dateStr);

        // Check if the selected date is in the past
        if (selectedDate.isBefore(dayjs(), 'day')) {
            enqueueSnackbar('Cannot schedule messages for past days', {
                variant: 'error',
            });
        } else {
            // Proceed with opening the modal or any other action
            setSelectedScheduledRecord({ ...selectedScheduledRecord, isShowModal: true });
        }

    };


    const handleEventClick = (e) => {
        const eventId = +e.event?._def?.publicId;
        const scheduleMessage = schedulers?.find(
            (ele) => ele?.id === eventId
        );
        setWorkScheduleObj(scheduleMessage);
        setEventModalData(e);
        setSelectedScheduledRecord({ ...selectedScheduledRecord, isShowModal: true, type: 'Edit' })
    };


    const eventContentHandler = (eventInfo) => {

        const event = eventInfo?.event?._def;
        // const backgroundColor = {
        //     MorningTest: "#FF6A6C",
        //     EveningTest: "#37B4A4",
        //     NightTest: "#FDCA64",
        // };


        // let shiftColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
        return (
            <span className="fs-14 fw-400 m-0 event-text">{event.title}</span>
        );
    };


    const getDateWithoutTime = (dt) => {
        dt.setHours(0, 0, 0, 0);
        return dt;
    }


    const handleSlotContent = (slotEvent) => {
        return (
            <>
                <div className="slot-event-wrapper">
                    <div className="d-flex align-center" style={{ gap: "5px" }}>
                        <h2 className="title-color m-0">
                            {dayjs(slotEvent.date).format("DD")}
                        </h2>
                        <div>
                            <p className="fs-14 fw-400 line-height-20 m-0">
                                {dayjs(slotEvent.date).format("dddd")}
                            </p>
                            <p className="fs-14 fw-400 line-height-20 m-0">
                                {dayjs(slotEvent.date).format("MMMM YYYY")}
                            </p>
                        </div>
                    </div>
                </div>
            </>
        );
    };



    return (
        <div className='full-calender-wrapper'>
            <FullCalendar
                ref={fullCalendarRef}
                schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                plugins={[
                    dayGridPlugin, interactionPlugin, resourceTimelinePlugin, ResourcePlugin
                ]}
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: ''
                }}
                titleFormat={{
                    month: "short",
                    day: "numeric",
                    weekday: "short",
                }}

                // initialView="dayGridMonth"
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                allDaySlot={true}
                eventMaxStack={1}
                themeSystem='Darkly'
                // validRange={() => { return { start: getDateWithoutTime(new Date()) }}}
                resources={scheduledMessagesResources}
                events={scheduledMessagesEvent || "No EVent"}
                droppable={true}
                slotMinWidth={100}
                height={'600px'}
                resourceAreaWidth={140}
                eventMinWidth={100}
                eventContent={eventContentHandler}
                //   eventBorderColor="1px solid #DBE4EF !important"
                slotDuration="24:00:00"
                slotLabelContent={handleSlotContent}
                slotLabelFormat={[
                    { day: "2-digit", month: "long", year: "numeric", weekday: "long" },
                ]}
                dateClick={calendarDateClick}
                eventClick={(e) => handleEventClick(e)}
                eventBorderColor="1px solid #DBE4EF !important"
            />
        </div>
    )
}
