const COMMON_COLORS = {
    PRIMARY_COLOR: 'var(--primary-color)',
    SECONDARY_COLOR: 'var(--secondary-color)',
    PRIMARY_TEXT_COLOR: 'var(--primary-text-color)',
    // INPUT_BG_COLOR: 'var(--input-bg-color)',
    TERTIARY_COLOR: '#86FFC6',
    INPUT_BG_COLOR: '#26262990',
    WHITE_COLOR: '#FFFFFF',
    GREY_COLOR: '##ffffffa6',
    BLACK_COLOR: '#000000',
    BG_DARK_GREY_COLOR: 'var(--chat-card-secondary-color)',
    TOOLTIP_COLOR:"#262629",
    BADGE_COLOR: '#F93535',

}

export const APP_PRIMARY_FONT = 'var(--primary-font)'

export const APP_COLORS = {
    PRIMARY_COLOR: COMMON_COLORS.PRIMARY_COLOR,
    SECONDARY_COLOR: COMMON_COLORS.SECONDARY_COLOR,
    TERTIARY_COLOR: COMMON_COLORS.TERTIARY_COLOR,
    TYPOGRAPHY: COMMON_COLORS.PRIMARY_TEXT_COLOR,
    WHITE_COLOR: COMMON_COLORS.WHITE_COLOR,
    BLACK_COLOR: COMMON_COLORS.BLACK_COLOR,
    GREY_COLOR: COMMON_COLORS.GREY_COLOR,
    BG_DARK_GREY_COLOR: COMMON_COLORS.BG_DARK_GREY_COLOR,
    TOOLTIP_COLOR: COMMON_COLORS.TOOLTIP_COLOR,
    BADGE_COLOR: COMMON_COLORS.BADGE_COLOR,

    BUTTON: {
        PRIMARY_COLOR: COMMON_COLORS.PRIMARY_COLOR,
    },

    INPUT: {
        BG_COLOR: COMMON_COLORS.INPUT_BG_COLOR,
        CHECK_COLOR: COMMON_COLORS.B,
    }

};


// ****************************
export const APP_FONT_SIZE = {
    FONT_SIZE_32: '32px',
    FONT_SIZE_25: '25px',
    FONT_SIZE_24: '24px',
    FONT_SIZE_20: '20px',
    FONT_SIZE_18: '18px',
    FONT_SIZE_16: '16px',
    FONT_SIZE_15: '15px',
    FONT_SIZE_14: '14px',
};


// ****************************
export const APP_FONT_WEIGHT = {
    FONT_WEIGHT_500: 500,
    FONT_WEIGHT_600: 600,
    FONT_WEIGHT_700: 700,
    FONT_WEIGHT_800: 800,
    FONT_WEIGHT_900: 900,
};


// ****************************
export const APP_LINE_HEIGHT = {
    LINE_HEIGHT_32: '32px',
    LINE_HEIGHT_20: '20px',
    LINE_HEIGHT_18: '18px',
    LINE_HEIGHT_16: '16px',
    LINE_HEIGHT_14: '14px',
    LINE_HEIGHT_12: '12px',
};


// ****************************
export const APP_BORDER_RADIUS = {
    BORDER_RADIUS_8: '8px',
    BORDER_RADIUS_12: '12px',
    BORDER_RADIUS_16: '16px',
    BORDER_RADIUS_18: '18px',
    BORDER_RADIUS_20: '20px',
};