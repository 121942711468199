import React from 'react'

export default function DeleteIcon() {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="23" viewBox="0 0 17 23" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M14.3147 7.26367H2.50821C1.85647 7.26367 1.32812 7.79201 1.32812 8.44376V17.8887C1.32813 19.8447 2.91378 21.4303 4.86979 21.4303H11.9531C12.8924 21.4303 13.7933 21.0572 14.4575 20.393C15.1217 19.7288 15.4948 18.828 15.4948 17.8887V8.44376C15.4948 7.79201 14.9665 7.26367 14.3147 7.26367Z" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M6.71354 11.9857C6.71354 11.6175 6.41506 11.319 6.04688 11.319C5.67869 11.319 5.38021 11.6175 5.38021 11.9857H6.71354ZM5.38021 16.7074C5.38021 17.0756 5.67869 17.3741 6.04688 17.3741C6.41506 17.3741 6.71354 17.0756 6.71354 16.7074H5.38021ZM11.4427 11.9857C11.4427 11.6175 11.1442 11.319 10.776 11.319C10.4079 11.319 10.1094 11.6175 10.1094 11.9857H11.4427ZM10.1094 16.7074C10.1094 17.0756 10.4079 17.3741 10.776 17.3741C11.1442 17.3741 11.4427 17.0756 11.4427 16.7074H10.1094ZM10.904 2.26432C11.2722 2.26432 11.5707 1.96585 11.5707 1.59766C11.5707 1.22947 11.2722 0.93099 10.904 0.93099V2.26432ZM6.18229 0.93099C5.8141 0.93099 5.51562 1.22947 5.51562 1.59766C5.51562 1.96585 5.8141 2.26432 6.18229 2.26432V0.93099ZM15.4948 5.09766C15.863 5.09766 16.1615 4.79918 16.1615 4.43099C16.1615 4.0628 15.863 3.76432 15.4948 3.76432V5.09766ZM1.32812 3.76432C0.959935 3.76432 0.661458 4.0628 0.661458 4.43099C0.661458 4.79918 0.959935 5.09766 1.32812 5.09766V3.76432ZM5.38021 11.9857V16.7074H6.71354V11.9857H5.38021ZM10.1094 11.9857V16.7074H11.4427V11.9857H10.1094ZM10.904 0.93099H6.18229V2.26432H10.904V0.93099ZM15.4948 3.76432H1.32812V5.09766H15.4948V3.76432Z" fill="white" />
      </svg>
  )
}
