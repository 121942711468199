import React from 'react'

export default function IronIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M15 11.0003L13.877 5.5293H4.053C3.005 5.5293 2.614 6.1293 2.153 7.3763L1 11.0003H15Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.8802 5.529L13.0312 1H7.65625" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
