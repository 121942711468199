

import React, { useEffect, useRef, useState, useCallback } from "react";


/******** Ant Design ********/
import { Button, Col, Modal, Row } from "antd";



/******** Socket ********/
import { addData } from "../redux/AppSlice";
import { useWebSocket } from "../context/WebSocket";
import { useAppDispatch, useAppSelector } from "../redux";



/******** Packages ********/
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import { enqueueSnackbar } from "notistack";



import { APP_REQUEST_METHODS, APP_STATUS } from "../config";



/******** Styles and Assets ********/
import "../styles/Chat.scss";
import "../styles/addToFolder.scss";
import TickIcon from "../assets/tick.svg";
import queueIcon from "../assets/svg/queue.svg";
import GroupIcon from "../assets/family_group.svg";
import ProfilePicIcon from "../assets/placeholder-user.svg";
import sendMessageIcon from "../assets/sendMessageIcon.svg";
import AddButtonIcon from "../assets/images/Chat/AddButtonIcon.svg";
import BackButtonIcon from "../assets/images/Chat/BackButtonIcon.svg";
import DoubleTickIcon from "../assets/images/Chat/DoubleTickIcon.svg";
import { AfternoonIcon, ClockIcon, ColoredDateTimePickerIcon, DateAndTimePickerIcon, EarlyMorningIcon, MorningIcon } from "../assets/svg";
import { findContactName } from "../utils";
import { AppActionModal, AppPopover } from "../components";





// ----------------------------------------------------------------------------------------------------------------

const ChatHeader = (props) => {

  const { value, name, allContacts, isShowAddToGroup, handleShowAddToGroup } = props;


  return (
    <div className="chatHeader-div">
      <div className="name-and-back-button-div" onClick={value}>
        <img
          src={BackButtonIcon}
          alt="backButtonIcon"
          style={{ width: "12px", margin: "2px 2px 2px 20px", cursor: 'pointer' }}
        />
        <img
          src={ProfilePicIcon}
          alt="Pic"
        // style={{ width: "46.5px", margin: "2px" }}
        />
        <p className="name-text">{findContactName(allContacts, name, undefined)}</p>
      </div>

      {!isShowAddToGroup ? (
        <img
          onClick={handleShowAddToGroup}
          src={AddButtonIcon}
          alt="AddButtonIcon"
          className="cursor-pointer"
          style={{ marginRight: "30px", width: "20px" }}
        />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "45%",
          }}
        >
          <img
            src={AddButtonIcon}
            alt="AddButtonIcon"
            style={{ marginRight: "30px", width: "20px" }}
          />
          <div className="addToFolder-container">
            <p className="heading-text">Add To Folder</p>
            <p className="cancel-text cursor-pointer" onClick={handleShowAddToGroup}>
              Cancel
            </p>
          </div>
        </div>
      )}
    </div>
  );
};





// ----------------------------------------------------------------------------------------------------------------


const SCHEDULED_TIMING = [
  {
    name: 'Tomorrow morning',
    time: '6 May, 9:00 AM',
    img: <MorningIcon />
  },
  {
    name: 'Tomorrow afternoon',
    time: '6 May, 1:00 PM',
    img: <AfternoonIcon />
  },
  {
    name: 'Monday morning',
    time: '8 May, 9:00 AM',
    img: <EarlyMorningIcon />
  },
  {
    name: 'Pick a date & time',
    time: '',
    img: <ColoredDateTimePickerIcon />
  }
]


export const ChatSection = (props) => {

  const { appReduxData, currentChatData, conversationData, handleBackToConversation } = props;


  const dispatch = useAppDispatch();
  const messagesEndRef = useRef(null);
  const { socketRef } = useWebSocket();

  const [newMessage, setNewMessage] = useState("");
  const [isShowAddToGroup, setIsShowAddToGroup] = useState(false);
  const [isShowLoadMore, setIsShowLoadMore] = useState(true);
  const [isBroadcastMessage, setIsBroadcastMessage] = useState(false);
  const [isScheduledMessage, setIsScheduledMessage] = useState(false);
  // const [isShowDateTimePicker, setIsShowDateTimePicker] = useState(false);
  const [setIsShowDateTimePicker] = useState(false);



  const [messagesData, setMessagesData] = useState([]);
  const { currentFolder } = useAppSelector((state => state.app));



  // -------------------------------------
  const formatMessageTime = (timestamp) => {
    const momentTimestamp = moment.unix(timestamp / 1000);
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');

    if (momentTimestamp.isSame(today, 'day')) {
      return momentTimestamp.format("MMM D hh:mm A");
    } else if (momentTimestamp.isSame(yesterday, 'day')) {
      return "Yesterday " + momentTimestamp.format("hh:mm A");
    } else {
      return momentTimestamp.format("MMM D hh:mm A");
    }
  };



  // -------------------------------------
  const updateGroupedFolderData = useCallback((userData, commonConversationNumber) => {

    console.log('conversation in updateGroupedFolderData userData', userData);


    // Update groupedFolderData state
    const groupedFolderData = userData.userData.folders.map(folder => {
      const folderConversations = userData?.conversations.filter(conversation => {

        console.log('conversation in updateGroupedFolderData', conversation);

        // const contactNumber = conversation.conversation.number;
        return folder.contactList.some(contact => contact.number === commonConversationNumber);
      });

      return { ...folder, conversations: folderConversations };
    });



    // Update existingData state
    userData = {
      ...userData,
      groupedFolderData: groupedFolderData
    };




    // Update state
    // setUserData(userData);
    // sessionStorage.setItem("userData", JSON.stringify(userData));
    // setMessagesData()
    dispatch(addData(userData));
  }, [dispatch]);


  // const updateUserWholeData = useCallback((receivedConversation, receivedMessages) => {
  //   console.log('receivedMessages ========>>>', receivedMessages);

  //   let existingData = { ...appReduxData };

  //   console.log('existingData ================= >>>>>>> ', existingData);

  //   const commonConversationNumber = receivedConversation?.number;

  //   // Check if the conversation already exists in the data
  //   if (existingData?.conversations.some(conv => conv?.conversation?.number === commonConversationNumber)) {
  //     // Conversation exists, update the messages (create a deep copy)
  //     const conversationIndex = existingData.conversations.findIndex(conv => conv?.conversation?.number === commonConversationNumber);
  //     const conversationToUpdate = existingData.conversations[conversationIndex];
  //     const oldMessages = JSON.parse(JSON.stringify(conversationToUpdate.message));

  //     receivedMessages.forEach(receivedMessage => {
  //       if (receivedMessage.type === 2) {
  //         // Find the message with type 6 and update it to type 2
  //         const messageIndex = oldMessages.findIndex(oldMessage => oldMessage.id === receivedMessage.id && oldMessage.type === 6);
  //         if (messageIndex !== -1) {
  //           oldMessages[messageIndex] = {
  //             ...oldMessages[messageIndex],
  //             ...receivedMessage
  //           };
  //         } else {
  //           // If message with type 6 not found, add the message with type 2
  //           oldMessages.push(receivedMessage);
  //         }
  //       } else if (receivedMessage.type === 1) {
  //         // Simply add the received message
  //         oldMessages.push(receivedMessage);
  //       }
  //     });

  //     // Create a new conversation object with updated messages
  //     const updatedConversation = {
  //       ...conversationToUpdate,
  //       message: oldMessages
  //     };

  //     // Create a new copy of existingData with updated conversation
  //     const updatedData = {
  //       ...existingData,
  //       conversations: [
  //         ...existingData.conversations.slice(0, conversationIndex),
  //         updatedConversation,
  //         ...existingData.conversations.slice(conversationIndex + 1)
  //       ]
  //     };

  //     console.log('updatedData ----------- >>>>>> ', updatedData);

  //     // Update the Redux state with the new data
  //     updateGroupedFolderData(updatedData);
  //   } else {
  //     // Conversation doesn't exist, create a new one
  //     const newConversation = {
  //       conversation: receivedConversation,
  //       message: receivedMessages
  //     };

  //     // Create a new copy of existingData with added conversation
  //     const updatedData = {
  //       ...existingData,
  //       conversations: [...existingData.conversations, newConversation]
  //     };

  //     // Update the Redux state with the new data
  //     updateGroupedFolderData(updatedData, commonConversationNumber);
  //   }
  // }, [appReduxData, updateGroupedFolderData]);


  const updateUserWholeData = useCallback((receivedConversation, receivedMessages) => {
    console.log('receivedMessages ========>>>', receivedMessages);

    let existingData = { ...appReduxData };

    console.log('existingData ================= >>>>>>> ', existingData);

    const commonConversationNumber = receivedConversation?.number;

    if (existingData?.conversations.some(conv => conv?.conversation?.number === commonConversationNumber)) {
      const conversationIndex = existingData.conversations.findIndex(conv => conv?.conversation?.number === commonConversationNumber);
      const conversationToUpdate = existingData.conversations[conversationIndex];
      const oldMessages = [...conversationToUpdate.message];

      receivedMessages.forEach(receivedMessage => {
        if (receivedMessage.type === 2) {
          const messageIndex = oldMessages.findIndex(oldMessage => oldMessage.id === receivedMessage.id && oldMessage.type === 6);
          if (messageIndex !== -1) {
            oldMessages[messageIndex] = {
              ...oldMessages[messageIndex],
              ...receivedMessage,
              type: 2 // Update to type 2
            };
          } else {
            oldMessages.push(receivedMessage);
          }
        } else if (receivedMessage.type === 1) {
          oldMessages.push(receivedMessage);
        }
      });

      const updatedConversation = {
        ...conversationToUpdate,
        message: oldMessages
      };

      const updatedData = {
        ...existingData,
        conversations: [
          ...existingData.conversations.slice(0, conversationIndex),
          updatedConversation,
          ...existingData.conversations.slice(conversationIndex + 1)
        ]
      };

      updateGroupedFolderData(updatedData);
    } else {
      const newConversation = {
        conversation: receivedConversation,
        message: receivedMessages
      };

      const updatedData = {
        ...existingData,
        conversations: [...existingData.conversations, newConversation]
      };

      updateGroupedFolderData(updatedData, commonConversationNumber);
    }
  }, [appReduxData, updateGroupedFolderData]);



  // -------------------------------------
  const handleSendMessage = async (e) => {

    e.preventDefault();


    if (newMessage.trim() === "") {
      return;
    }


    const currentTime = new Date().getTime();
    const uniqueID = uuidv4();

    const message = {
      type: 'Chat',
      data: {
        text: newMessage,
        number: currentChatData?.data?.conversation?.number,
        delivered: false,
        id: uniqueID,
        subscription: -1,
        time: currentTime,
        type: 6
      }
    };


    // -------------------------------------
    if (socketRef.current && socketRef.current.readyState === 1) {

      setMessagesData(prevMessages => [...prevMessages, message.data]);
      setNewMessage("");
      socketRef.current.send(JSON.stringify(message));


      socketRef.current.onclose = (event) => {
        console.error("WebSocket closed ======================", event);
      };

      socketRef.current.onerror = (event) => {
        console.error("WebSocket error: ======================", event);
        // showMessage({ status: 'error', messageToDisplay: 'Some error occurred.' });
      }

    }


    socketRef.current.onclose = (event) => {
      console.error("WebSocket closed ======================", event);
      // showMessage({ status: 'error', messageToDisplay: 'Some error occurred.' });
    };

  };


  const groupMessagesByDate = (messages) => {
    let groupedMessages = {};

    messages.forEach((message) => {
      const messageDate = moment.unix(message.time / 1000);
      const formattedDate = messageDate.format("YYYY-MM-DD");
      if (!groupedMessages[formattedDate]) {
        groupedMessages[formattedDate] = [];
      }
      groupedMessages[formattedDate].push(message);
    });

    // Sort messages within each date group by timestamp
    for (const date in groupedMessages) {
      groupedMessages[date].sort((a, b) => a.time - b.time);
    }

    // Sort dates, putting today's messages at the bottom
    const sortedDates = Object.keys(groupedMessages).sort((a, b) => {
      const isTodayA = moment(a).isSame(moment(), 'day');
      const isTodayB = moment(b).isSame(moment(), 'day');

      if (isTodayA && !isTodayB) {
        return 1;
      } else if (!isTodayA && isTodayB) {
        return -1;
      } else {
        return moment(b).format("YYYY-MM-DD") - moment(a).format("YYYY-MM-DD");
      }
    });

    // Reverse the order of sorted dates
    const reversedDates = sortedDates.reverse();

    const sortedGroupedMessages = {};
    reversedDates.forEach((date) => {
      sortedGroupedMessages[date] = groupedMessages[date];
    });

    return sortedGroupedMessages;
  };


  const sortMessagesByDate = (messages) => {
    return Object.keys(messages).sort((a, b) => {
      const isTodayA = moment(a).isSame(moment(), 'day');
      const isTodayB = moment(b).isSame(moment(), 'day');

      if (isTodayA && !isTodayB) {
        return 1;
      } else if (!isTodayA && isTodayB) {
        return -1;
      } else {
        return moment(b).format("YYYY-MM-DD") - moment(a).format("YYYY-MM-DD");
      }
    });
  };


  // -------------------------------------
  const handleBroadcastMessage = () => {

    if (newMessage.trim() === "") {
      return;
    }


    if (conversationData && currentFolder?.contactList) {
      // Iterate through each contact in the contact list
      currentFolder?.contactList.forEach((contact) => {
        // Check if the phone number starts with a letter
        const phoneNumber = contact.number;
        if (/^[a-zA-Z]/.test(phoneNumber)) {
          return;
        }

        // Send the message to the current phone number
        const currentTime = new Date().getTime();
        const messageSendToSocket = {
          type: 'Chat',
          data: {
            text: newMessage,
            number: phoneNumber,
            delivered: false,
            id: 34534535,
            subscription: -1,
            time: 1704785966550,
            type: 2
          }
        };

        const newMessageData = {
          text: newMessage,
          time: currentTime,
          number: phoneNumber,
          delivered: false,
          subscription: -1,
          type: 2,
          id: uuidv4()
        };

        if (socketRef.current && socketRef.current.readyState === 1) {
          // Update local storage with the new message
          // updateUserWholeData(phoneNumber, newMessageData);

          console.log('Broadcasting final data sent to websocket ------------------> ', messageSendToSocket);
          socketRef.current.send(JSON.stringify(messageSendToSocket));
        }
      });
    }

    enqueueSnackbar(`Broadcast message sent successfully.`, { variant: "success" });

    setIsBroadcastMessage(false);
    setNewMessage('')


  }


  const groupMessageData = groupMessagesByDate(messagesData || []);
  const groupMessageDates = sortMessagesByDate(groupMessageData).reverse();


  // -------------------------------------
  const handleScheduleTime = (item) => {
    if (item === 'Pick a date & time') {
      setIsShowDateTimePicker(true)
    }
  }

  const handleLoadMoreMessagesData = () => {

    const reqMessageData = {
      type: APP_REQUEST_METHODS.MESSAGE_REQUEST,
      data: {
        number: currentChatData?.data?.conversation?.number,
        // #TODO: find length here
        available: appReduxData?.conversations[currentChatData?.data?.conversation?.number]?.messages?.length
      }
    }



    if (socketRef.current && socketRef.current.readyState === 1) {

      console.log("reqMessageData has been sent to the server -------------_> ", JSON.stringify(reqMessageData));

      socketRef.current.send(JSON.stringify(reqMessageData));



      socketRef.current.onerror = (event) => {
        console.error("WebSocket error: ======================", event);

      }


    } else {
      console.error("WebSocket connection not open.");
      // enqueueSnackbar('WebSocket is Closed, Try connecting again.', {
      //   variant: 'error',
      // });
    }



    socketRef.current.onclose = (event) => {
      console.error("WebSocket closed ======================", event);
      // enqueueSnackbar('WebSocket is Closed, Try connecting again.', {
      //   variant: 'error',
      // });
    };
  }





  // #checking
  useEffect(() => {



    console.log('currentChatData =========?>>>>', currentChatData);
    console.log('currentChatData =========?>>>> messagesData', messagesData);



    // Find conversation based on number ID
    const conversation = appReduxData?.conversations?.find(
      (conv) => conv?.conversation?.number === currentChatData?.data?.conversation?.number
    );


    console.log('conversation======= >>>>> in chat', conversation);

    // if (conversation?.message?.length) {
    // Update messages data if conversation is found and message length differs
    // if (conversation && conversation.messages?.length !== messagesData?.length) {
    // setMessagesData(conversation.message);
    // }
    // }

    if (conversation?.message.length >= messagesData?.length) {
      setMessagesData(conversation.message);
    }



  }, [currentChatData?.data?.conversation?.number, messagesData?.length, appReduxData?.conversations]);



  // // // -------------------------------------
  useEffect(() => {

    console.log(' ------------- Effect is running in chat --------------');


    if (socketRef.current && socketRef.current.readyState === 1) {

      socketRef.current.onmessage = (event) => {
        const receivedEvent = JSON.parse(event.data);
        const parsedData = JSON.parse(receivedEvent);


        console.log(' ============ ANDROID DATA RECEIVED IN CHAT ------> ', parsedData);


        // ======================================================================================================================
        if (parsedData?.conversation?.number) {

          if (parsedData?.message?.length > 0) {
            setIsShowLoadMore(true);
          }
          else {
            setIsShowLoadMore(false);
          }

          updateUserWholeData(parsedData.conversation, parsedData.message);
        }


      };

      // Set up event handlers to check for success or failure
      socketRef.current.onclose = (event) => {
        console.error("WebSocket closed ======================", event);
        // enqueueSnackbar('WebSocket is Closed, Try connecting again.', {
        //   variant: 'error',
        // });
      };

      socketRef.current.onerror = (event) => {
        console.error("WebSocket error: ======================", event);
      };
    }


  }, [socketRef, updateUserWholeData]);




  return (
    <div className="chat-container">
      {/* <Col xs={24}> */}
      <ChatHeader
        value={handleBackToConversation}
        allContacts={appReduxData?.contacts}
        isShowAddToGroup={isShowAddToGroup}
        handleShowAddToGroup={() => setIsShowAddToGroup(!isShowAddToGroup)}
        name={currentChatData?.data?.conversation?.name || currentChatData?.data?.conversation?.number}
      />
      {isShowAddToGroup ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            // height: isShowAddToGroup ? '100%' : 'calc(100% - 77px)'
            height: 'calc(100% - 77px)'
          }}
        >
          <div
            className="chats"
            style={{
              height: isShowAddToGroup ? '100%' : 'calc(100% - 77px)',
              width: isShowAddToGroup ? '100%' : "auto",
              background: isShowAddToGroup && 'rgba(0,0,0,0.2)'
            }}
          >
            <div style={{ height: 'calc(100% - 92px)', overflow: 'auto' }}>

            </div>
            <div className="message-input" style={{ background: isShowAddToGroup && 'none' }}>
              <input
                className="send-message"
                placeholder={newMessage}
                onInput={(e) => setNewMessage(e.target.value)}
              />
              <img
                onClick={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    handleSendMessage();
                  }
                }}
                src={sendMessageIcon}
                alt="sendMessage"
                className="cursor-pointer"
                style={{
                  height: 50,
                  width: 50,
                }}
              />
            </div>
          </div>
          <div
            className={"addToFolder-section"}
          >

            <div
              className="input-divs"
              style={{
                width: "90%",
              }}
            >
              <div className="single-input">
                <img src={GroupIcon} width={63} alt="group-cion" />
                <div className="input-group">
                  <p className="group-name-label">Family</p>
                  <img src={TickIcon} width={23} alt="tick" />
                </div>
              </div>
              <div className="single-input">
                <img src={GroupIcon} width={63} alt="group-cion" />
                <div className="input-group">
                  <p
                    className="group-name-label"
                    style={{
                      color: "#FFFFFF",
                    }}
                  >
                    Banks
                  </p>
                </div>
              </div>
              <div className="single-input">
                <img src={GroupIcon} width={63} alt="group-cion" />
                <div className="input-group">
                  <p className="group-name-label">Family</p>
                  <img src={TickIcon} width={23} alt="tick" />
                </div>
              </div>
            </div>
            <div className="add-to-folder-button">Add to Folder</div>
          </div>
        </div>
      ) : (
        <>

          <div className="chats" style={{ width: isShowAddToGroup && '100%' }}>
            {groupMessageDates.map((date) => {
              const messages = groupMessageData[date];
              const reversedMessages = [...messages].reverse();
              const today = moment().format("YYYY-MM-DD");
              const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");

              return (
                <React.Fragment key={uuidv4()}>

                  {reversedMessages.map((mess) => {
                    return (
                      <React.Fragment key={uuidv4()}>
                        <div
                          className={mess.type === APP_STATUS.INBOX ? "chat-left-text" : "chat-right-text"}
                        >
                          <p className="chat-text">{mess.text}</p>
                          <p className="chat-time-text">
                            {/* {moment.unix(mess.time / 1000).format("hh:mm A")} */}
                            {formatMessageTime(mess.time)}
                            {mess.type === APP_STATUS.SENT && (
                              <img
                                src={DoubleTickIcon}
                                alt="doubleTickIcon"
                                style={{ marginLeft: "8px" }}
                              />
                            )}
                            {mess.type === APP_STATUS.QUEUED && (
                              <img
                                src={queueIcon}
                                alt="queueIcon"
                                style={{ marginLeft: "8px" }}
                              />
                            )}
                          </p>
                        </div>
                      </React.Fragment>
                    );
                  })}
                  <div className="date-text-div">
                    <p className="date-text">
                      {date === today ? "Today" : date === yesterday ? "Yesterday" : date}
                    </p>
                  </div>
                </React.Fragment>
              );
            })}
            {isShowLoadMore && messagesData?.length > 49 && (
              <div className="load-more" style={{ marginTop: '1rem' }}>
                <Button className="add-folder-btn-div" onClick={handleLoadMoreMessagesData}>Load More</Button>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>

          <form onSubmit={handleSendMessage} className="message-input">
            <input
              className="send-message"
              placeholder="Message"
              value={newMessage}
              onInput={(e) => setNewMessage(e.target.value)}
            />
            <AppPopover content='Schedule Message'>
              <div className="cursor-pointer" style={{ color: '#fff', paddingRight: '0.5rem' }} onClick={() => setIsScheduledMessage(true)}>
                <DateAndTimePickerIcon />
              </div>
            </AppPopover>

            <AppPopover content='Broadcast Message'>
              <div className="cursor-pointer" style={{ color: '#fff', paddingRight: '0.5rem' }} onClick={() => setIsBroadcastMessage(true)}>
                <ClockIcon />
              </div>
            </AppPopover>
            <img
              className="cursor-pointer"
              onClick={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault()
                  handleSendMessage();
                  setNewMessage("");
                }
              }}
              src={sendMessageIcon}
              alt="sendMessage"
              style={{
                height: 50,
                width: 50,
              }}
            />
          </form>
        </>

      )}
      <Modal
        open={isScheduledMessage}
        footer={null}
        closable={false}
        centered={true}
        destroyOnClose
        width={718}
        rootClassName="app-schedule-modal"
        maskClosable={true}
        onCancel={() => setIsScheduledMessage(false)}
        styles={{
          body: {
            borderRadius: "20px",
            background: '#010103',
            backdropFilter: "blur(119.13749694824219px)",
          },
        }}
      >
        <div className="top-heading">
          <h2 className="heading">Schedule send</h2>
          <button className="save-btn">Save</button>
        </div>

        <div className="body-content">

          <Row gutter={48}>
            {
              SCHEDULED_TIMING?.map((singleItem) => {
                return (
                  <Col key={uuidv4()} xs={6} className="cursor-pointer" style={{ textAlign: 'center' }} onClick={() => handleScheduleTime(singleItem)}>
                    <div style={{ width: '52px', height: '52px', margin: 'auto' }}>{singleItem?.img}</div>
                    <h5 className="schedule-heading">{singleItem?.name}</h5>
                    <p className="schedule-desc">{singleItem?.time}</p>
                  </Col>
                )
              })
            }

          </Row>

          <div className="time-info">Pakistan Standard Time</div>
        </div>

      </Modal>

      <AppActionModal
        heading="Are you Sure?"
        desc="Are you sure you want to send the message to all contacts in this group?"
        cancelText="Cancel"
        confirmText="Yes"
        isOpenActionModal={isBroadcastMessage}
        handleCloseModal={() => setIsBroadcastMessage(false)}
        handleConfirm={handleBroadcastMessage}
      />

    </div>
  );
};