import { createContext, useContext, useState } from 'react';

const UserContext = createContext();


// ----------------------------------------------------------------------------------------------------------------



export function useUser() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
}


// ----------------------------------------------------------------------------------------------------------------



export function UserProvider({ children }) {

  const userStoredData = JSON.parse(sessionStorage.getItem('userData'));

  const [userData, setUserData] = useState(userStoredData?.userData || null);


  const updateUserData = (newData) => {
    setUserData(newData);
  };

  return (
    <UserContext.Provider value={{ userData, updateUserData }}>
      {children}
    </UserContext.Provider>
  );
}