import React, { useCallback, useEffect, useRef, useState } from 'react';


/******** Ant Design ********/
import { Button, Modal, Input, Space, Dropdown, Form, TimePicker, Select, Menu, DatePicker } from 'antd';



import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

import { CustomPopover } from "../../components";



/******** Styles and Assets ********/
import "../../styles/Chat.scss";
import "../../styles/addToFolder.scss";
import "../../styles/schedule.scss";
import TickIcon from "../../assets/tick.svg";
import Microphone from "../../assets/microphone.svg";
import GroupIcon from "../../assets/family_group.svg";
import sendMessageIcon from "../../assets/sendMessageIcon.svg";
import { AddContactIcon, DateAndTimePickerIcon, DeleteIcon, EditIcon, UserPlaceholderIcon } from '../../assets/svg';
import AddButtonIcon from "../../assets/images/Chat/AddButtonIcon.svg";
import BackButtonIcon from "../../assets/images/Chat/BackButtonIcon.svg";
import ProfilePicIcon from "../../assets/placeholder-user.svg";
import DoubleTickIcon from "../../assets/images/Chat/DoubleTickIcon.svg";
import UserIcon from "../../assets/useIcon2.svg";
import { useWebSocket } from '../../context/WebSocket';
import { enqueueSnackbar } from 'notistack';
import { useAppDispatch, useAppSelector } from '../../redux';
import { addData, handleSectionToShow } from '../../redux/AppSlice';
import { APP_INTERNAL_ROUTES, APP_STATUS, SECTION_STATUS } from '../../config';
import { Link } from 'react-router-dom';
import { ScheduleCalender } from './Calender';







const { TextArea } = Input;
const { useForm } = Form;
const format = 'HH:mm';


const { Option } = Select;



// const 






const ScheduleHeader = (props) => {

    const { value, name, isShowAddToGroup, isShowScheduler, showAddToGroup, hideAddToGroup, toggleShowScheduler } = props;



    const dispatch = useAppDispatch();

    return (
        <div className="chatHeader-div">
            <Link className="name-and-back-button-div"
                to={APP_INTERNAL_ROUTES.CONVERSATION}
                // onClick={toggleShowScheduler}
                // onClick={() => dispatch(handleSectionToShow(SECTION_STATUS.CONVERSATION))}

                style={{ gap: '1rem' }}
            >
                <img
                    src={BackButtonIcon}
                    alt="backButtonIcon"
                    style={{ width: "12px", margin: "2px 2px 2px 20px", cursor: 'pointer' }}
                />
                {/* <img
                    src={ProfilePicIcon}
                    alt="Pic"
                    style={{ width: "46.5px", margin: "2px" }}
                /> */}
                <p className="name-text primary-heading">Scheduler</p>
            </Link>

            {!isShowAddToGroup ? (
                <img
                    onClick={() => toggleShowScheduler()}
                    src={AddButtonIcon}
                    alt="AddButtonIcon"
                    className="cursor-pointer"
                    style={{ marginRight: "30px", width: "20px" }}
                />
            ) : (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "45%",
                    }}
                >
                    <img
                        src={AddButtonIcon}
                        onClick={toggleShowScheduler}
                        alt="AddButtonIcon"
                        style={{ marginRight: "30px", width: "20px" }}
                    />
                    <div className="addToFolder-container">
                        <p className="heading-text">Add To Folder</p>
                        <p className="cancel-text cursor-pointer " onClick={() => hideAddToGroup()}>
                            Cancel
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}



function formatDate(dateString) {
    const date = new Date(dateString);

    const options = {
        weekday: 'long',
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    }

    return date.toLocaleDateString('en-US', options);
}




export const ScheduleSection = (props) => {

    const { isShowScheduler, setIsShowScheduler, toggleShowScheduler, schedulers } = props;

    const { socketRef } = useWebSocket();


    const [selectedScheduledRecord, setSelectedScheduledRecord] = useState({
        isShowModal: false,
        type: 'Add',
        data: {}
    });



    const reduxData = useAppSelector(state => state.app)


    const handleFormateOnlyDate = (dateTime) => {
        if (!dateTime) return ""; // Return empty string if dateTime is not provided
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const day = dateTime.getDate();
        const month = months[dateTime.getMonth()];
        const year = dateTime.getFullYear();
        const formattedDate = `${dateTime.toLocaleDateString('en-US', { day }, { weekday: 'long' })}, ${month}, ${year}`;

        return formattedDate;
    };


    const handleFormateOnlyDate2 = (dateTime) => {
        if (!dateTime) return ""; // Return empty string if dateTime is not provided
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const day = dateTime.getDate();
        const month = months[dateTime.getMonth()];
        const year = dateTime.getFullYear();
        const dayOfWeek = dateTime.toLocaleDateString('en-US', { weekday: 'long' });

        const formattedDate = `${dayOfWeek}, ${month} ${day}, ${year}`;

        return formattedDate;
    };



    const handleFormateOnlyTime = (dateTime) => {
        if (!dateTime) return ""; // Return empty string if dateTime is not provided
        const formattedDate = new Intl.DateTimeFormat('en-US', {
            // weekday: 'long',
            // day: '2-digit',
            // month: 'short',
            // year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            // second: '2-digit',
            // timeZoneName: 'short'
        }).format(dateTime);
        return formattedDate;
    };






    const [form] = useForm();

    const onFinish = (values) => {
        // Handle form submission logic here
        // message.success('Form submitted successfully');

        const currentTime = new Date();

        if (selectedDate <= currentTime) {
            enqueueSnackbar("Selected time has already passed.", { variant: "error" });
            return;
        }

        const refactoredContacts = values?.contacts?.map(singleContact => ({
            contactId: singleContact?.title?.contactId,
            name: singleContact?.title?.name,
            number: singleContact?.title?.number,
            isSection: false,
            // profilePicUrl: singleContact?.title?.profilePicUrl,
            // conversationData: []
        }));



        // console.log('selectedDate ========>', selectedDate);



        const sendMessageToAndroid = {
            type: 'Schedule',
            data: {
                contacts: refactoredContacts,
                // showDate: handleFormateOnlyDate(new Date(selectedDate)),
                time: handleFormateOnlyDate2(new Date(selectedDate)) + ' ' + handleFormateOnlyTime(selectedDate),
                message: values?.message,
            }


        }



        // const finalMockDataSentToAndroid = {
        //     type: "Schedule",
        //         data: {
        //         contacts: [
        //             {
        //                 "contactId": 24408,
        //                 "name": "Huawei Customer Service",
        //                 "number": "mobilepkhuaweicom",
        //                 "isSection": false
        //             }
        //         ],
        //             time: "Monday, Jan 8, 2024 12:01 AM",
        //                 message: "message"
        //     }
        // }

        if (socketRef.current && socketRef.current.readyState === 1) {

            // addMessageWithDateToData(newMessageData);
            // updatesessionStorageWithNewMessage(currentChatData?.conversation?.number, newMessageData);


            console.log('final data sent to websocket schedule', sendMessageToAndroid);
            socketRef.current.send(JSON.stringify(sendMessageToAndroid));

            //      // Set up event handlers to check for success or failure
            socketRef.current.onclose = (event) => {
                console.error("WebSocket closed ======================", event);
                enqueueSnackbar('WebSocket is Closed, Try connecting again.', {
                    variant: 'error',
                });
            };

            socketRef.current.onerror = (event) => {
                console.error("WebSocket error: ======================", event);

            }

            enqueueSnackbar(`Message Scheduled Successfully.`, { variant: "success" });
            handleResetScheduledMessage()

        }

    };




    const handleResetScheduledMessage = () => {
        setSelectedScheduledRecord({ isShowModal: false, type: 'Add', data: {} })
        form.resetFields();
    }




    const dummyArray = [
        {
            id: '1',
            text: '',
            date: '2023-04-13',
            desc: 'Happy Birthday...Have fun:)',
            contact: {
                pic: ProfilePicIcon,
            }
        },
        {
            id: '2',
            text: '',
            date: '2023-05-13',
            desc: 'Iftar Invite for Office group',
            contact: {
                pic: ProfilePicIcon,
            }
        },
        {
            id: '3',
            text: '',
            date: '2023-06-13',
            desc: 'Iftar Invite for Ofiice group',
            contact: {
                pic: ProfilePicIcon,
            }
        },
        {
            id: '4',
            text: '',
            date: '2023-07-13',
            desc: 'Iftar Invite for Ofiice group',
            contact: {
                pic: ProfilePicIcon,
            }
        },
    ]

    const items = [
        {
            label: (
                <div
                    style={{ display: "flex", alignItems: "center", gap: "8px", borderBottom: '1px solid rgb(202 202 202 / 34%)', padding: '0 2rem' }}
                    onClick={() => setSelectedScheduledRecord({ ...selectedScheduledRecord, isShowModal: true, type: 'Edit' })}
                >
                    <p>Edit</p>
                </div>
            ),
            key: "1",
        },
        {
            label: (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        paddingTop: "10px", padding: '0 2rem'
                    }}
                >
                    <p>Cancel</p>
                </div>
            ),
            key: "2",
        },
    ];

    const usersArray = [
        {
            contactName: "Atif",
            contactNumber: '03347404607',
            profilePicUrl: ProfilePicIcon
        },
        {
            contactName: "Abdullah",
        },
        {
            contactName: "Zia",
        },
        {
            contactName: "Awais",
        },
        {
            contactName: "Bilal",
        },
        {
            contactName: "Ali",
        },
        {
            contactName: "Faraz",
        },
        {
            contactName: "Hassan",
        },
        {
            contactName: "Salman",
        },
    ];





    // ======================================

    const currentDate = new Date();
    const dispatch = useAppDispatch();
    const datePickerRef = useRef(null);
    const [currentYear, setYear] = useState(currentDate.getFullYear());
    const [currentMonth, setMonth] = useState(currentDate.getMonth() + 1);
    const [selectedTime, setSelectedTime] = useState('12:00 PM');
    const [selectedDate, setSelectedDate] = useState(currentDate);
    const [isDateAndTimeSelected, setIsDateAndTimeSelected] = useState(false);
    const [scheduleData, setScheduleData] = useState([]);

    const [showDatePicker, setShowDatePicker] = useState(false);



    // console.log(' handleFormateOnlyDate(selectedDate)', handleFormateOnlyDate());;




    const goToPreviousMonth = () => {
        setMonth(prevMonth => (prevMonth === 1 ? 12 : prevMonth - 1));
        setYear(prevYear => (currentMonth === 1 ? prevYear - 1 : prevYear));
    };

    const goToNextMonth = () => {
        setMonth(prevMonth => (prevMonth === 12 ? 1 : prevMonth + 1));
        setYear(prevYear => (currentMonth === 12 ? prevYear + 1 : prevYear));
    };

    const generateCalendar = () => {
        const calendar = [];
        const daysInMonth = new Date(currentYear, currentMonth, 0).getDate();



        for (let day = 1; day <= daysInMonth; day++) {
            calendar.push(day);
        }



        return calendar;
    };

    // Update your generateCalendar function
    // const generateCalendar = () => {
    //     const calendar = [];
    //     const daysInMonth = new Date(currentYear, currentMonth, 0).getDate();

    //     for (let day = 1; day <= daysInMonth; day++) {
    //         calendar.push(
    //             <div className="date-div" key={uuidv4()} onClick={() => hanldeDateClick(currentYear, currentMonth, day)}>
    //                 <p>{day ? new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(new Date(currentYear, currentMonth - 1, day)) : ''}</p>
    //                 <p>{day}</p>
    //             </div>
    //         );
    //     }

    //     return calendar;
    // };






    const userSearch = [];
    (reduxData?.contacts || usersArray)?.map((item, index) =>
        userSearch.push(
            <Option key={uuidv4()} value={item?.name || item?.number} label={item?.name || item?.number} title={item}>
                <Space
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        color: "#fff",
                        // width:"100%",
                        // background:"none"
                    }}
                >
                    <img
                        src={item?.profilePicUrl || UserIcon}
                        alt="Pic"
                        style={{ width: "30px" }}
                    />
                    {item?.name || item?.number}

                </Space>
            </Option>
        )
    );



    // -------------------------------------
    const updateGroupedFolderData = useCallback((userData, filteredScheduler) => {

        // Get conversations from userData
        // const allConversations = Object.values(userData?.conversation || {});


        // Update groupedFolderData state
        const groupedFolderData = userData?.userData?.folders.map(folder => {
            const folderConversations = userData?.conversations?.filter(conversation => {
                const contactNumber = conversation.conversation.number;
                return folder.contactList.some(contact => contact.number === contactNumber);
            });

            return { ...folder, conversations: folderConversations };
        });


        // const filteredScheduler = scheduleData.filter(item => item.id !== scheduleData?.id);
        // setScheduleData(filteredScheduler);


        // Update existingData state
        userData = {
            ...userData,
            groupedFolderData: groupedFolderData,
            scheduler: filteredScheduler
        };


        // If sessionStorage had no data, or if you want to update with socket data regardless
        // setScheduleData(prev => [...prev, ...scheduler]);

        // const updatedUserData = {
        //     ...reduxData,
        //     scheduler: [
        //         ...scheduler
        //     ]
        // };

        // // Dispatch action to update user data in Redux state
        // dispatch(addData(updatedUserData));


        // Update state
        // setUserData(userData);
        // sessionStorage.setItem("userData", JSON.stringify(userData));
        // setMessagesData()
        dispatch(addData(userData));


    }, [dispatch]);




    // -------------------------------------
    const updateUserWholeData = useCallback((conversationNumber, newMessage, scheduleData, conversationData) => {

        // Retrieve existing data from sessionStorage
        // const existingDataString = sessionStorage.getItem("userData");
        const existingData = { ...reduxData };


        // const commonConversationNumber = conversationNumber?.number ?? conversationNumber;
        const commonConversationNumber = conversationNumber;


        // Check if the conversation already exists in the data
        if (existingData?.conversations[commonConversationNumber]) {
            // Conversation exists, update the messages (create a deep copy)
            const oldMessages = JSON.parse(JSON.stringify(existingData?.conversations[commonConversationNumber].message));

            // Check if the message ID is already present in the existing messages
            const isMessageExists = oldMessages.find(message => message.id === newMessage.id);




            // && isMessageExists?.type === 6
            // if (isMessageExists !== undefined && isMessageExists?.type === 6) {
            if (isMessageExists && isMessageExists?.type === 6) {



                // Message ID found, update the message
                const updatedMessages = oldMessages.map(message => {
                    // if (message.id === newMessage.id) {
                    //   return newMessage;
                    // }
                    // return message;
                    // Check if the message ID needs a status update and type is not equal to 1
                    if (newMessage.id === message.id && message.type === 6) {
                        return { ...message, type: 2 };
                    }
                    return message;
                });
                // Set the updated messages with status
                existingData.conversations[commonConversationNumber].message = updatedMessages;



                // // Update the message statuses based on message IDs
                // const updatedMessagesWithStatus = oldMessages.map(message => {
                //   // Check if the message ID needs a status update and type is not equal to 1
                //   if (newMessage.id === message.id && message.type === 6) {
                //     return { ...message, type: 2 };
                //   }
                //   return message;
                // });



                // console.log('old message has been updated with new status 8888888888888', updatedMessagesWithStatus);



                // // Set the updated messages with status
                // existingData.conversation[commonConversationNumber].messages = updatedMessagesWithStatus;

            } else {


                // Message ID not found, push the new message to the array
                existingData.conversations[commonConversationNumber].message.push(newMessage);
            }

            // Update the conversation with the new messages
            existingData.conversations[commonConversationNumber] = {
                ...existingData.conversations[commonConversationNumber],
                message: existingData.conversations[commonConversationNumber].message,
            };
        }
        else {
            // Conversation doesn't exist, create a new one

            const newwww = []
            // If conversation doesn't exist, create a new one
            newwww.push({
                conversation: {
                    number: commonConversationNumber,
                    ...conversationData
                },
                message: [newMessage],
            });


            existingData.conversations = [...existingData.conversations, ...newwww]





            //   existingData.conversations?.map(item => item?.conversation?.number === commonConversationNumber);


            // existingData.conversations[commonConversationNumber] = {
            //     conversation: {
            //         ...conversationData
            //     },
            //     message: [newMessage],
            // };
        }

        // Set the messages data in the state
        // setMessagesData(existingData.conversation[conversationNumber].messages);

        // Update sessionStorage with the modified data
        // sessionStorage.setItem("userData", JSON.stringify(existingData));




        const filteredScheduler = scheduleData.filter(item => item.date !== newMessage?.time);
        setScheduleData(filteredScheduler);



        updateGroupedFolderData(existingData, filteredScheduler);
    }, [updateGroupedFolderData]);


    // const updateUserWholeData = useCallback((conversationNumber, newMessage, scheduleData, conversationData) => {
    //     // Retrieve existing data from sessionStorage
    //     const existingData = { ...reduxData };

    //     // Check if existingData.conversations is defined and is an array
    //     if (!existingData.conversations || !Array.isArray(existingData.conversations)) {
    //         console.error("existingData.conversations is not properly initialized or is not an array");
    //         return; // Exit function to avoid further errors
    //     }

    //     // Convert conversationNumber to string for object access
    //     const commonConversationNumber = conversationNumber;

    //     // Check if the conversation already exists in the data
    //     const existingConversationIndex = existingData.conversations.findIndex(conv => conv.conversation.number === commonConversationNumber);

    //     if (existingConversationIndex !== -1) {
    //         // Conversation exists, update the messages (create a deep copy)
    //         const oldMessages = existingData.conversations[existingConversationIndex].message;

    //         // Check if the message ID is already present in the existing messages
    //         const isMessageExists = oldMessages.find(message => message.id === newMessage.id);

    //         if (isMessageExists && isMessageExists.type === 6) {
    //             // Message ID found and type is 6, update the message status
    //             const updatedMessages = oldMessages.map(message => {
    //                 if (newMessage.id === message.id && message.type === 6) {
    //                     return { ...message, type: 2 };
    //                 }
    //                 return message;
    //             });

    //             // Update the messages with the new status
    //             existingData.conversations[existingConversationIndex].message = updatedMessages;
    //         } else {

    //             console.log('newMessage =--====== > > > > >',newMessage);
    //             // Message ID not found or type is not 6, push the new message to the array
    //             existingData.conversations[existingConversationIndex].message.push({ ...newMessage });
    //         }
    //     } else {
    //         // Conversation doesn't exist, create a new one
    //         existingData.conversations.push({
    //             conversation: {
    //                 number: commonConversationNumber,
    //                 ...conversationData
    //             },
    //             message: [newMessage],
    //         });
    //     }

    //     // Check if newMessage has a time property
    //     if (newMessage && newMessage.time) {
    //         // Remove the scheduled message from scheduleData
    //         const filteredScheduler = scheduleData.filter(item => item.date !== newMessage.time);

    //         // Update the state with filtered scheduler data
    //         setScheduleData(filteredScheduler);

    //         // Update groupedFolderData with the modified existingData
    //         updateGroupedFolderData(existingData, filteredScheduler);
    //     } else {
    //         console.error("newMessage or newMessage.time is undefined");
    //     }
    // }, [reduxData, updateGroupedFolderData]);




    const handleTimeSelect = (selectedTime) => {
        setSelectedTime(selectedTime)
        // You can use the selectedTime as needed, e.g., set it to state or perform other actions.


        form.setFieldsValue({
            time: selectedTime,
        });


    };



    const handleFormateDate = (dateTime) => {
        if (!dateTime) return ""; // Return empty string if dateTime is not provided
        const formattedDate = new Intl.DateTimeFormat('en-US', {
            weekday: 'long',
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            // second: '2-digit',
            // timeZoneName: 'short'
        }).format(dateTime);
        return formattedDate;
    };

    const handleFormateDate2 = (dateTime) => {
        if (!dateTime) return ""; // Return empty string if dateTime is not provided
        const formatter = new Intl.DateTimeFormat('en-US', {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            hour: '2-digit',
            minute: '2-digit',
            // second: '2-digit',
            // timeZoneName: 'short'
        });
        const formattedDate = formatter.format(dateTime);
        return formattedDate;
    };






    // const hanldeDateClick = (year, month, day) => {
    //     console.log('selectedDate ------->', day);

    //     const selectedDate = new Date(year, month - 1, day);


    //     const formattedDate = handleFormateDate(selectedDate)


    //     form.setFieldsValue({
    //         dateTimeRange: formattedDate,
    //     });


    //     setSelectedDate(selectedDate)


    // }


    // const onChange = (value, dateString) => {
    //     console.log('Selected Time: ', value);
    //     console.log('Formatted Selected Time: ', dateString);
    // };

    const onOk = (value) => {
        console.log('onOk: ', value);
    };


    const onDatePickerChange = (date) => {
        setSelectedDate(date);
        form.setFieldsValue({
            dateTimeRange: handleFormateDate(date),
        });
        setShowDatePicker(false); // Close the DatePicker after selecting a date
    };

    const toggleDatePicker = () => {
        setShowDatePicker(!showDatePicker);
    };


    useEffect(() => {

        setScheduleData(reduxData?.scheduler)
    }, [reduxData?.scheduler])



    useEffect(() => {
        console.log(' ------------- Effect is running in Scheduler --------------');


        // setScheduleData(scheduler);

        // Set up event handlers for the socket
        if (socketRef.current && socketRef.current.readyState === 1) {
            socketRef.current.onmessage = (event) => {
                const receivedEvent = JSON.parse(event.data);

                console.log('receivedEvent after Scheduler sending to Android scheduler ------> ', receivedEvent);



                const parsedData = JSON.parse(receivedEvent);

                console.log('Data received after Scheduler sending to Android scheduler ------> ', parsedData);

                if (parsedData?.scheduler && parsedData?.scheduler?.length > 0) {
                    const scheduler = parsedData.scheduler;

                    // If sessionStorage had no data, or if you want to update with socket data regardless
                    setScheduleData(prev => [...prev, ...scheduler]);

                    const updatedUserData = {
                        ...reduxData,
                        scheduler: [
                            ...scheduler
                        ]
                    };

                    // Dispatch action to update user data in Redux state
                    dispatch(addData(updatedUserData));
                    // Update sessionStorage with the new merged data
                    // sessionStorage.setItem('userData', JSON.stringify({ scheduler: [...prev, ...scheduler] }));
                }


                if (parsedData?.action === "deleteScheduler") {





                    const filteredScheduler = scheduleData.filter(item => item.id !== parsedData?.id);


                    console.log('filteredScheduler in EFFECT< ', filteredScheduler);

                    setScheduleData(filteredScheduler);


                    const updatedUserData = {
                        ...reduxData,
                        scheduler: filteredScheduler
                    };

                    // Dispatch action to update user data in Redux state
                    dispatch(addData(updatedUserData));

                }

                if (parsedData?.conversation) {




                    // Message sending
                    if (parsedData?.message[0]?.type === APP_STATUS.QUEUED) {
                        enqueueSnackbar('Scheduled message are sending... !', { variant: 'info' });
                    }

                    // Send
                    if (parsedData?.message[0]?.type === APP_STATUS.SENT) {
                        enqueueSnackbar('Scheduled message has been sent successfully!', { variant: 'success' });
                    }




                    console.log('Data received after Scheduler sending to Android scheduler parsedData?.conversation ------> ', parsedData?.conversation);

                    const receivedNumber = parsedData.conversation.number;
                    const receivedMessage = parsedData.message[0];

                    updateUserWholeData(receivedNumber, receivedMessage, scheduleData, parsedData?.conversation);


                }



            };

            // Set up event handlers to check for success or failure
            socketRef.current.onclose = (event) => {
                console.error("WebSocket closed ======================", event);
                enqueueSnackbar('WebSocket is Closed, Try connecting again.', {
                    variant: 'error',
                });
            };

            socketRef.current.onerror = (event) => {
                console.error("WebSocket error: ======================", event);
            };
        }

    }, [socketRef]);



    console.log('================ reduxDatareduxData', reduxData);



    return (
        <div className="chat-container">
            <ScheduleHeader
                handleGoBack={setIsShowScheduler}
                contacts={reduxData?.contacts}
                isShowScheduler={isShowScheduler}
                toggleShowScheduler={() => setSelectedScheduledRecord({ ...selectedScheduledRecord, isShowModal: true, data: {} })}
            />

            {/*  */}


            <div style={{ height: 'calc(100% - 77px)', maxHeight: 'calc(100% - 77px)', overflow: 'auto' }}>
                <ScheduleCalender selectedScheduledRecord={selectedScheduledRecord} schedulers={schedulers} setSelectedScheduledRecord={setSelectedScheduledRecord} />
                <div className='scheduled-list'>
                    <div className='heading'>
                        <h2>Type Now, Send Later</h2>

                        <img
                            onClick={() => setSelectedScheduledRecord({ ...selectedScheduledRecord, isShowModal: true, data: {} })}
                            src={AddButtonIcon}
                            alt="AddButtonIcon"
                            className="cursor-pointer"
                            style={{ marginRight: "30px", width: "20px" }}
                        />
                    </div>

                    {/*  */}
                    {!!scheduleData?.length ? scheduleData?.map(item => {
                        return (
                            <div className='scheduled-list-item' key={uuidv4()}>
                                <div className='item-heading'>
                                    {/* <img src={item?.contact?.pic} alt='Pic' style={{ borderRadius: "45px" }} /> */}
                                    <div className='item-text'>
                                        <h3 className='text'>{formatDate(item?.date)}</h3>
                                        <h3 className='desc'>{item?.message}</h3>
                                    </div>
                                </div>
                                <Dropdown
                                    menu={{ items }}
                                    placement="bottomRight"
                                    trigger={["click"]}
                                    overlayClassName=""
                                    className=" "
                                    rootClassName='dropdown-styling'
                                    dropdownRender={(menu) => (
                                        <span >
                                            {React.cloneElement(menu)}
                                        </span>
                                    )}
                                >
                                    <Space className='cursor-pointer' onClick={
                                        (e) => {
                                            e.preventDefault();
                                        }
                                    }>
                                        <div style={{ color: '#fff' }}>
                                            Edit
                                        </div>
                                        <EditIcon />
                                    </Space>
                                </Dropdown>
                                {/* <Dropdown arrow rootClassName="custom-dropdown-styling" placement='bottom' dropdownRender={() => (
                                items.map(item => (
                                    <Menu.Item key={item.key}>
                                        {item.label}
                                    </Menu.Item>
                                ))
                            )}>
                                <a onClick={(e) => e.preventDefault()}>CLick</a>
                            </Dropdown> */}

                            </div>
                        )
                    }) : (
                        <div>
                            <p className='no-item'>No Schedule at the moment</p>
                        </div>
                    )}
                </div>
            </div>



            <Modal rootClassName="app-scheduler-modal" open={!!selectedScheduledRecord.isShowModal}
                footer={false}
                closable={false}
                mask={true}
                destroyOnClose
                maskClosable={true}
                onCancel={() => handleResetScheduledMessage()}
            >
                <Form form={form} onFinish={onFinish}>


                    <div className='heading'>
                        <h2>{selectedScheduledRecord.type === "Edit" ? "Update Message" : "Message"}</h2>
                        <Button htmlType="submit" className='save-btn'>{selectedScheduledRecord.type === "Edit" ? "Update" : "Save"}</Button>
                    </div>

                    <div className='text-area'>
                        <Form.Item
                            name="message"
                            rules={[{ required: true, message: 'Please enter a message' }]}
                        >
                            <TextArea rows={5} placeholder="Enter message ..." className="app-textarea" />
                        </Form.Item>
                    </div>

                    <div className='date-picker'>
                        <div className='flex-with-gap j-between'>
                            <div className='time-picker flex-with-gap w-100'>

                                <DateAndTimePickerIcon />
                                <label className='time-picker-header'>
                                    <h3 className='ternary-heading' style={{ marginTop: 0 }}>Pick Date & Time</h3>
                                    {/* <p className='primary-text'>Thursday, 13 April 2023</p> */}

                                    {/* <Form.Item
                                        name="time"
                                        rules={[{ required: true, message: 'Please select time' }]}
                                        style={{ marginBottom: 0 }}
                                    > */}
                                    <p className='primary-text'>{`${handleFormateDate(selectedDate)}`}</p>
                                    {/* </Form.Item> */}



                                </label>
                            </div>

                            {/* onClick={() => setIsDateAndTimeSelected(!isDateAndTimeSelected)} */}
                            <div className='edit cursor-pointer' onClick={toggleDatePicker}>
                                <h4>Edit</h4>
                                <EditIcon />
                            </div>
                            <DatePicker
                                // showTime
                                ref={datePickerRef}
                                // onChange={onChange}
                                onChange={onDatePickerChange}
                                onOk={onOk}
                                open={showDatePicker}
                                // onBlur={() => { }} // Necessary to prevent DatePicker from closing on blur
                                placeholder='Select'
                                bordered={false}
                                showTime={{ format: 'HH:mm' }}
                                suffixIcon={false}
                                rootClassName='schedule-date-time-picker'
                                style={{ visibility: 'hidden', position: 'absolute' }}
                            />
                        </div>




                        {/* <div className='date-and-time-picker-container' style={{ display: `${isDateAndTimeSelected ? 'block' : 'none'}` }}>

                            <div className='flex-with-gap' >
                                <h3 className='tertiary-heading' style={{ width: '6rem' }}>{new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' }).format(new Date(currentYear, currentMonth - 1, 1))}</h3>

                                <div className='flex-with-gap'>
                                    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: 'pointer' }} onClick={goToPreviousMonth}>
                                        <path d="M9.79298 2.03796L1.90625 9.92469L9.79298 17.8114" stroke="#86FFC6" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: 'pointer' }} onClick={goToNextMonth}>
                                        <path d="M2.38281 2.03796L10.2695 9.92469L2.38281 17.8114" stroke="#86FFC6" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>


                            </div>

                            <Form.Item
                                name="dateTimeRange"
                                rules={[{ required: true, message: 'Please select date and time' }]}
                            >
                                <div className='date-wrapper'>
                                    {generateCalendar().map((day) => (
                                        <div className={`date-div ${selectedDate && selectedDate.getDate() === day ? 'selected-date' : ''}`} key={uuidv4()} onClick={() => hanldeDateClick(currentYear, currentMonth, day)}>
                                            <p>{day ? new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(new Date(currentYear, currentMonth - 1, day)) : ''}</p>
                                            <p>{day}</p>
                                        </div>
                                    ))}
                                </div>
                            </Form.Item>

                            <h3 className='tertiary-heading' style={{ width: '6rem' }}>Time</h3>

                            <Form.Item
                                name="time"
                                rules={[{ required: true, message: 'Please select time' }]}
                            >
                                <div className='time-wrapper'>
                                    <CustomPopover content={<DateTimePicker onSelect={handleTimeSelect} />} open trigger='click' isTimePicker arrow={false} placement="rightBottom">
                                        <p className='time'>{selectedTime}</p>
                                    </CustomPopover>
                                </div>
                            </Form.Item>


                        </div> */}



                    </div>

                    <div className='contacts'>
                        <div className='contact-heading flex-with-gap'>
                            {/* <img src={ProfilePicIcon} alt='contact-icon' style={{ borderRadius: '45px' }} /> */}

                            <div><AddContactIcon /></div>

                            {/* <h3 className='ternary-heading' style={{ margin: 0, width:'100%', whiteSpace:'nowrap' }}>Add Contacts</h3> */}





                        </div>
                        <Form.Item
                            name="contacts"
                            rules={[{ required: true, message: "Add at least one contact" }]}
                            style={{ width: '100%', height: '100%', margin: 0 }}
                        >
                            <Select
                                // ref={dropdownRef}
                                // onDropdownVisibleChange={handleDropdownVisibleChange}
                                mode="multiple"
                                style={{ width: "100%" }}
                                // open={true}
                                // popupClassName="folder-dropdown"
                                className="add-contacts-search-div"
                                // onChange={(x) => handleContactChange(x)}
                                // clearIcon={<DeleteIcon />}
                                removeIcon={<DeleteIcon />}
                                // suffixIcon={false}
                                // removeIcon={false}
                                listHeight={"100px"}
                                labelInValue
                                dropdownStyle={{
                                    background: " rgba(38, 38, 41, 1)",
                                    color: "#fff",
                                    // width: "90%",
                                    fontSize: '15px',
                                    // maxHeight:150,
                                    // zIndex:1
                                    // background:"none"
                                }}
                            // optionLabelProp="label"
                            // dropdownRender={(menu) => (
                            //   <div
                            //     style={{
                            //       ...menu,
                            //       backgroundColor: "rgba(38, 38, 41, 0.60)",
                            //       color: "#fff",
                            //       background: "none",
                            //     }}
                            //   >
                            //     {menu}
                            //   </div>
                            // )}
                            >
                                {userSearch}
                            </Select>
                        </Form.Item>

                        <div className='edit'>
                            <h4>Edit</h4>
                            <EditIcon />
                        </div>
                    </div>



                </Form>


            </Modal>

        </div>
    )
}




const DateTimePicker = ({ onSelect }) => {
    const [selectedHour, setSelectedHour] = useState('12');
    const [selectedMinute, setSelectedMinute] = useState('00');
    const [selectedPeriod, setSelectedPeriod] = useState('AM');

    useEffect(() => {
        // Call onSelect whenever the user makes a selection
        onSelect(`${selectedHour}:${selectedMinute} ${selectedPeriod}`);
    }, [selectedHour, selectedMinute, selectedPeriod, onSelect]);

    const hours = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));
    const minutes = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));

    return (
        <div style={{ height: '193px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-around', padding: '10px' }}>
                <div className='schedule-time-text' style={{ marginRight: '1rem' }}>
                    {hours.map(hour => (
                        <div key={hour} onClick={() => setSelectedHour(hour)} style={{ cursor: 'pointer', marginBottom: '1rem' }}>
                            {hour}
                        </div>
                    ))}
                </div>
                <div className='schedule-time-text' style={{ marginRight: '1rem' }}>
                    {minutes.map(minute => (
                        <div key={minute} onClick={() => setSelectedMinute(minute)} style={{ cursor: 'pointer', marginBottom: '1rem' }}>
                            {minute}
                        </div>
                    ))}
                </div>
                <div className='schedule-time-text'>
                    {['AM', 'PM'].map(period => (
                        <div key={period} onClick={() => setSelectedPeriod(period)} style={{ cursor: 'pointer', marginBottom: '1rem' }}>
                            {period}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};


// Helper function to format the selected date
const formatSelectedDate = (selectedDate) => {
    console.log('selectedDate', selectedDate);
    return `${selectedDate.toLocaleDateString('en-US', { weekday: 'long' })}, ${selectedDate.getDate()} ${selectedDate.toLocaleDateString('en-US', { month: 'long' })} ${selectedDate.getFullYear()}`;
};