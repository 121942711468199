import React, { createContext, useContext, useRef, useEffect, useCallback, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";


import { SOCKET_URL, getQrToken } from "../api/requests";


import { jwtDecode } from "jwt-decode";
import { addData } from "../redux/AppSlice";
import { useAppDispatch } from "../redux";



const WebSocketContext = createContext();


// ----------------------------------------------------------------------------------------------------------------


export const WebSocketProvider = ({ children }) => {
    const socketRef = useRef();
    const navigate = useNavigate();
    const location = useLocation();

    const dispatch = useAppDispatch();

    const [qrToken, setQrToken] = useState('');
    const [userData, setUserData] = useState({
        userData: { folders: [], userProfile: {}, userToken: '' },
        conversations: [],
        contacts: [],
        scheduler: [],
        groupedFolderData: []
    });
    const [isFetchingQRCode, setIsFetchingQRCode] = useState(false);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [isSocketConnected, setIsSocketConnected] = useState(null);
    const [isSocketDataExists, setIsSocketDataExists] = useState(null);
    const [isFolderDataCompleted, setIsFolderDataCompleted] = useState('');



    const fetchDataAfterNavigation = async (userToken) => {

        setIsFetchingData(false);

        if (userToken) {
            navigate('/home');
            // dispatch({ type: actionTypes.SET_FETCHING_DATA, payload: false });
        }
    };


    const initializeWebSocket = useCallback(async () => {
        let body = {
            device_type: "Web",
        };


        try {
            setIsFetchingData(false);
            setIsFetchingQRCode(true);

            const response = await getQrToken(body);

            if (response.success) {
                const { token } = response;
                setQrToken(token);
                localStorage.setItem("qrToken", token);

            } else {
                console.error("Failed to fetch QR token:", response);
                return;
            }
        } catch (error) {
            console.error("Error fetching QR token:", error);
            return;
        } finally {
            setIsFetchingQRCode(false);
        }

        return Promise.resolve(); // Resolve the promise when the asynchronous operations are complete

    }, []);



    useEffect(() => {

        const setupWebSocket = async () => {

            const ipFromLocalStorage = localStorage.getItem("ip") || null;
            const portFromLocalStorage = localStorage.getItem("port") || null;
            const tokenFromLocalStorage = localStorage.getItem("qrToken") || null;
            const userDataFromLocalStorage = JSON.parse(localStorage.getItem("userData"));


            if (!qrToken && !userDataFromLocalStorage?.id) {
                await initializeWebSocket();
                console.error("QR token not available");
                return;
            }


            console.log('userDataFromLocalStorage ======== > > > > > > > ', userDataFromLocalStorage);

            let socketUrl;


            if (userDataFromLocalStorage?.id) {
                console.log('POSTS EXITS ALREADY ======== > > > > > > > ', userDataFromLocalStorage);

                socketUrl = `wss://${SOCKET_URL}?user_id=${userDataFromLocalStorage?.id}?device_type=web?ip=::ffff:${ipFromLocalStorage}?port=${portFromLocalStorage}`;
                setQrToken(tokenFromLocalStorage);
            } else {

                console.log('POSTS NOT AVAILABLE ======== > > > > > > > ', userDataFromLocalStorage);
                console.log('POSTS NOT AVAILABLE qrToken ======== > > > > > > > ', qrToken);

                const decode = jwtDecode(qrToken);
                const ipAndPort = decode.ipAndPort.split(":");

                localStorage.setItem("ip", ipAndPort[3]);
                localStorage.setItem("port", ipAndPort[4]);
                localStorage.setItem("qrToken", qrToken);
                localStorage.setItem("userData", JSON.stringify({ id: null }));
                socketUrl = `wss://${SOCKET_URL}?ip=::ffff:${ipAndPort[3]}?port=${ipAndPort[4]}?device_type=web`;

            }

            socketRef.current = new WebSocket(socketUrl);

            socketRef.current.onopen = () => {
                setIsSocketConnected(true);
            };


            let conversationsArray = [];
            let updatedUserData = { ...userData }; // Make a copy of userData



            // console.log('userData?.groupedFolderData',oldConversationForFolderData);


            // Inside the WebSocketProvider
            socketRef.current.onmessage = (event) => {
                setIsFetchingData(true);
                setIsSocketDataExists(true);


                const firstParsedData = JSON.parse(JSON.parse(event.data));

                if (location.pathname !== '/home' && firstParsedData?.userToken) {
                    // setIsFetchingData(true);
                    setIsFetchingData(false);
                    fetchDataAfterNavigation(firstParsedData?.userToken);
                }

                if (JSON.parse(JSON.parse(event.data)) === "Connected") {
                } else {
                    const userData = JSON.parse(event.data);
                    const parsedData = JSON.parse(userData);



                    console.group('PARSED DATA FROM MOBILE  IN WEB SOCKET');


                    console.log('PARSED DATA =>', parsedData);



                    if (parsedData?.contacts && parsedData?.contacts.length > 0) {
                        const userContacts = parsedData.contacts;
                        updatedUserData.contacts = [...updatedUserData.contacts, ...userContacts].filter(Boolean);
                    }

                    if (parsedData?.folders && parsedData?.folders.length > 0) {
                        const userAndFoldersData = {
                            folders: [...updatedUserData.userData.folders, ...parsedData?.folders],
                            userProfile: parsedData.userProfile,
                            userToken: parsedData.userToken,
                            conversationCount: parsedData?.conversationCount
                        };
                        updatedUserData.userData = userAndFoldersData;
                    }


                    // =================
                    if (parsedData?.inboxConversation && parsedData?.inboxConversation.length > 0) {
                        const inboxMessages = parsedData.inboxConversation;
                        updatedUserData.inboxConversation = [...updatedUserData.inboxConversation, ...inboxMessages].filter(Boolean);
                    }

                    // if (parsedData?.conversations?.length > 0) {

                    //     console.log('conversationsArray ========== > > >  >  >>', conversationsArray);
                    //     // Iterate over each conversation object in the parsedData.conversation array
                    //     parsedData.conversations.forEach(conversationData => {
                    //         const { number } = conversationData?.conversation;
                    //         // Check if a conversation with the same number already exists in the conversationsArray
                    //         const existingConversationIndex = conversationsArray.findIndex(conv => conv.conversation.number === number);
                    //         if (existingConversationIndex !== -1) {
                    //             // Update conversation and messages of existing conversation
                    //             conversationsArray[existingConversationIndex].conversation = conversationData?.conversation;
                    //             conversationsArray[existingConversationIndex].message.push(...conversationData?.message);
                    //         } else {
                    //             // Add new conversation to the conversationsArray
                    //             conversationsArray = [...conversationsArray, {
                    //                 conversation: conversationData?.conversation,
                    //                 message: conversationData?.message
                    //             }];
                    //         }
                    //     });
                    // }

                    if (parsedData?.conversations?.length > 0) {
                        console.log('conversationsArray ========== > > >  >  >>', conversationsArray);

                        // Make a mutable copy of the conversationsArray
                        let updatedConversationsArray = [...conversationsArray];

                        // Iterate over each conversation object in the parsedData.conversations array
                        parsedData.conversations.forEach(conversationData => {
                            const { number } = conversationData?.conversation;
                            // Check if a conversation with the same number already exists in the updatedConversationsArray
                            const existingConversationIndex = updatedConversationsArray.findIndex(conv => conv.conversation.number === number);
                            if (existingConversationIndex !== -1) {
                                // Create a mutable copy of the existing conversation
                                let updatedConversation = {
                                    ...updatedConversationsArray[existingConversationIndex],
                                    conversation: conversationData.conversation,
                                    message: [...updatedConversationsArray[existingConversationIndex].message, ...conversationData.message]
                                };

                                // Update the conversation in the updatedConversationsArray
                                updatedConversationsArray[existingConversationIndex] = updatedConversation;
                            } else {
                                // Add new conversation to the updatedConversationsArray
                                updatedConversationsArray = [...updatedConversationsArray, {
                                    conversation: conversationData?.conversation,
                                    message: conversationData?.message
                                }];
                            }
                        });
                        conversationsArray = updatedConversationsArray
                    }

                    // FOR FOLDER DATA WHEN REQUESTS FOR MORE
                    // if (parsedData?.conversation?.number) {


                    //     console.log('PARSED DATA FROM MOBILE  IN WEB SOCKET folders data ', parsedData);

                    //     const commonConversationNumber = parsedData?.conversation?.number;
                    //     const receivedMessages = parsedData?.message;

                    //     const shallowConversations = { ...updatedUserData?.conversations };

                    //     if (shallowConversations.hasOwnProperty(commonConversationNumber)) {

                    //         console.log('Conversation doesnt exist, push a new one');



                    //         // Conversation exists, update the messages (create a deep copy)
                    //         const oldMessages = JSON.parse(JSON.stringify(shallowConversations[commonConversationNumber].messages));

                    //         // Check if the message ID is already present in the existing messages
                    //         const isMessageExists = oldMessages.find(oldMessage => receivedMessages.some(receivedMessage => receivedMessage.id === oldMessage.id));

                    //         if (isMessageExists && isMessageExists?.type === 6) {
                    //             // Message ID found, update the message
                    //             const updatedMessages = oldMessages.map(message => {
                    //                 // Check if the message ID needs a status update and type is not equal to 1
                    //                 if (isMessageExists.id === message.id && message.type === 6) {
                    //                     return { ...message, type: 2 };
                    //                 }
                    //                 return message;
                    //             });
                    //             // Set the updated messages with status
                    //             shallowConversations[commonConversationNumber] = {
                    //                 ...shallowConversations[commonConversationNumber],
                    //                 messages: updatedMessages,
                    //             };
                    //         } else {
                    //             // Message ID not found, push the new message to the array
                    //             shallowConversations[commonConversationNumber] = {
                    //                 ...shallowConversations[commonConversationNumber],
                    //                 messages: [...oldMessages, ...receivedMessages],
                    //             };
                    //         }
                    //     } 
                    //     // else {
                    //     //     // Conversation doesn't exist, push a new one

                    //     //     console.log('Conversation doesnt exist, push a new one');
                    //     //     shallowConversations[commonConversationNumber] = {
                    //     //         conversation: parsedData?.conversation,
                    //     //         messages: receivedMessages,
                    //     //     };
                    //     // }

                    //     // Update the redux state with a new object containing the updated conversations
                    //     updatedUserData = {
                    //         ...updatedUserData,
                    //         conversations: shallowConversations,
                    //     };

                    //     console.log('updatedUserData final after updating the folders one', updatedUserData);
                    // }


                    // FOR FOLDER DATA WHEN REQUESTS FOR MORE
                    if (parsedData?.conversation?.number) {
                        // const commonConversationNumber = parsedData?.conversation?.number;
                        // const receivedMessages = parsedData?.message;


                        conversationsArray = [...updatedUserData?.conversations, { ...parsedData }];

                        // const existingConversationIndex = conversationsArray.findIndex(conv => conv.conversation.number === commonConversationNumber);

                        // console.log('≈≈≈≈≈≈ ---- >>>>> ', existingConversationIndex);
                        // if (existingConversationIndex !== -1) {
                        //     // Conversation exists, update the messages (create a deep copy)
                        //     const oldMessages = JSON.parse(JSON.stringify(conversationsArray[existingConversationIndex].messages));

                        //     // Check if the message ID is already present in the existing messages
                        //     const isMessageExists = oldMessages.find(oldMessage => receivedMessages.some(receivedMessage => receivedMessage.id === oldMessage.id));

                        //     if (isMessageExists && isMessageExists?.type === 6) {
                        //         // Message ID found, update the message
                        //         const updatedMessages = oldMessages.map(message => {
                        //             // Check if the message ID needs a status update and type is not equal to 1
                        //             if (isMessageExists.id === message.id && message.type === 6) {
                        //                 return { ...message, type: 2 };
                        //             }
                        //             return message;
                        //         });
                        //         // Set the updated messages with status
                        //         conversationsArray[existingConversationIndex].messages = updatedMessages;
                        //     } else {
                        //         // Message ID not found, push the new message to the array
                        //         conversationsArray[existingConversationIndex].messages.push(...receivedMessages);
                        //     }
                        // } else {
                        //     // Conversation doesn't exist, push a new one
                        //     conversationsArray.push({
                        //         conversation: parsedData?.conversation,
                        //         messages: receivedMessages,
                        //     });
                        // }










                        // Update the redux state with the updated conversations array
                        updatedUserData = {
                            ...updatedUserData,
                            conversations: conversationsArray,
                        };

                        // setInterval(() => {

                        //     // const hasMoreFolderData =  userData?.groupedFolderData?.find(item => item?.conversation?.number === parsedData?.conversation?.number)


                        // // Update groupedFolderData state
                        // const hasGroupEnded = oldConversationForFolderData?.map(folder => {
                        //     // const folderConversations = userData?.conversations.filter(conversation => {

                        //     // console.log('conversation in updateGroupedFolderData', conversation);

                        //     // const contactNumber = conversation.conversation.number;
                        //     return folder.conversation.length + 15 < reduxData?.groupedFolderData?.length;
                        //     // });

                        //     // return { ...folder, conversations: folderConversations };
                        // });


                        // console.log('hasGroupEnded= ============> > > > > >',hasGroupEnded);


                    }




                    if (parsedData?.scheduler && parsedData?.scheduler?.length > 0) {
                        const scheduler = parsedData.scheduler;
                        updatedUserData.scheduler = [...updatedUserData.scheduler, ...scheduler].filter(Boolean);
                    }


                    // if (parsedData?.conversation && parsedData?.scheduler?.length > 0) {
                    //     const scheduler = parsedData.scheduler;
                    //     updatedUserData.scheduler = [...updatedUserData.scheduler, ...scheduler].filter(Boolean);
                    // }


                    if (Array.isArray(parsedData?.conversation) && parsedData?.conversationDeleted) {

                        console.log('userData in delete conversation --> ', userData);
                        console.log('userData in delete conversation updatedUserData --> ', updatedUserData);

                        if (parsedData?.conversationDeleted) {
                            conversationsArray = updatedUserData?.conversations.filter(singleItem => singleItem?.conversation?.number !== parsedData?.conversation[0]?.number);

                            console.log('userData in delete conversation -->', conversationsArray);



                            // Update groupedFolderData state
                            const groupedFolderData = updatedUserData.userData.folders.map(folder => {
                                const folderConversations = conversationsArray.filter(conversation => {
                                    const contactNumber = conversation.conversation.number;
                                    return folder.contactList.some(contact => contact.number === contactNumber);
                                });

                                console.log('folderConversations============= ', folderConversations);

                                return { ...folder, conversations: folderConversations };
                            });


                            // Update updatedUserData state
                            updatedUserData = {
                                ...updatedUserData,
                                conversations: conversationsArray,
                                groupedFolderData: groupedFolderData
                            };


                        }
                    }





                    // Update groupedFolderData state
                    const groupedFolderData = updatedUserData.userData.folders.map(folder => {
                        const folderConversations = conversationsArray.filter(conversation => {
                            const contactNumber = conversation.conversation.number;
                            return folder.contactList.some(contact => contact.number === contactNumber);
                        });

                        console.log('folderConversations============= ', folderConversations);

                        return { ...folder, conversations: folderConversations };
                    });




                    // INCASE IF GOT COMPLETED LIST OR NO FURTHER DATA
                    if (parsedData?.value === 'empty') {
                        setIsFolderDataCompleted('true');
                    }





                    // Update updatedUserData state
                    updatedUserData = {
                        ...updatedUserData,
                        conversations: conversationsArray,
                        groupedFolderData: groupedFolderData
                    };




                }

                console.groupEnd();

                console.log('updatedUserData', updatedUserData);

                setUserData(updatedUserData);

                // sessionStorage.setItem("userData", JSON.stringify(updatedUserData));
                localStorage.setItem("userData", JSON.stringify(updatedUserData?.userData?.userProfile));

            };
        };

        setupWebSocket();


        return () => {
            if (socketRef.current) {
                socketRef.current.close();
                setIsFetchingData(false);

            }
        };


    }, [qrToken, initializeWebSocket]);




    useEffect(() => {
        dispatch(addData({ ...userData }));
    }, [userData])



    return (
        <WebSocketContext.Provider value={{ socketRef, userData, qrToken, isFetchingQRCode, isFetchingData, isSocketConnected, isSocketDataExists, isFolderDataCompleted, setIsFolderDataCompleted, setIsSocketConnected, setIsFetchingData }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => {
    return useContext(WebSocketContext);
};