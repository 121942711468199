import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";


import { useAppDispatch, useAppSelector } from "../../redux";
import { handleChatSection } from "../../redux/AppSlice";


import { v4 as uuidv4 } from 'uuid';


import { enqueueSnackbar } from "notistack";


import { APP_REQUEST_METHODS } from "../../config";



import { ChatSection } from "../Chat";
import { getShortCode } from "../../api/requests";
import { useWebSocket } from "../../context/WebSocket";


import "../../styles/conversations.scss";
import "../../styles/CreateFolder.scss";
import { AppActionModal, AppLazyPagination, AppLoading, AppSingleConversation, AppTextAreaHeader } from "../../components";




// ----------------------------------------------------------------------------------------------------------





export const ConversationSection = () => {

  const { folderId } = useParams();

  const dispatch = useAppDispatch();
  const { socketRef, isFolderDataCompleted, setIsFoldrDataCompleted } = useWebSocket()

  const [hasMoreData, setHasMoreData] = useState(isFolderDataCompleted === 'true' ? false : true);
  const [currentPage, setCurrentPage] = useState(0);
  const [appShortCodes, setAppShortCodes] = useState([]);
  const [conversationValues, setConversationValues] = useState({});
  const [isDeleteConversationModalOpen, setIsDeleteConversationModalOpen] = useState(false);
  const [currentChatData, setCurrentChatData] = useState({ isChatActive: false, data: [] });


  const {
    userData: reduxUserData,
    contacts: reduxContacts,
    currentFolder: reduxCurrentFolder,
    conversations: reduxConversations,
    groupedFolderData: reduxGroupedFolderData,
  } = useAppSelector((state => state.app));


  const wholeReduxData = useAppSelector((state => state.app));



  const isFolderConversation = folderId; // if exists means folder is selected, if undefined mean no folder

  // console.group('CONVERSATION SECTION LOGS STARTS HERE')


  // IF FOLDER SELECTED
  const currentRouteFolder = reduxGroupedFolderData?.find(singleFolder => singleFolder?.id === parseInt(folderId));




  // IF NO FOLDER SELECTED, SHOW INBOX
  const CONVERSATION_TO_RENDER = isFolderConversation ? currentRouteFolder?.conversations : reduxConversations;



  // ------------------------------------- 
  const sortedConversations = useMemo(() => {

    // const iterateMe = CONVERSATION_TO_RENDER === undefined ? [] : Array.isArray(CONVERSATION_TO_RENDER) ? CONVERSATION_TO_RENDER : [...CONVERSATION_TO_RENDER];
    const iterateMe = CONVERSATION_TO_RENDER === undefined ? [] : Array.isArray(CONVERSATION_TO_RENDER) ? CONVERSATION_TO_RENDER : [...CONVERSATION_TO_RENDER];

    console.log('THIS IS IN CONVERSATION TO RENDER ============  >> > > > > > >', iterateMe);


    return iterateMe.map(conversation => {

      // Ensure conversation.message is an array
      let messages = Array.isArray(conversation.message) ? conversation.message : [conversation.message];

      // let sortedMessages = conversation.message.sort((a, b) => {
      // let sortedMessages = [...messages].sort((a, b) => b.time - a.time);
      let sortedMessages = [...messages].sort((a, b) => b.time - a.time);


      // return { ...conversation, message: [sortedMessages[0]] };


      // Return the conversation with only the latest message
      return { ...conversation, message: sortedMessages.slice(0, 1) };

    }).sort((a, b) => b.message[0]?.time - a.message[0]?.time);

  }, [CONVERSATION_TO_RENDER]);




  // -------------------------------------
  const handleConversationClick = (e, itemOne) => {

    e.preventDefault()

    if (e.button === 0) {
      console.log('handleConversationClick Left click');
      setCurrentChatData({ isChatActive: true, data: itemOne })
      dispatch(handleChatSection(true));
    } else if (e.button === 2) {
      console.log('handleConversationClick Right click');
    }


  };




  const handleRemoveChatSection = () => {
    setCurrentChatData({ ...currentChatData, isChatActive: false });
    dispatch(handleChatSection(false));
  }



  const handleConversationDelete = (event) => {
    event.stopPropagation();

    setIsDeleteConversationModalOpen(true)
  }



  const handleConversationValue = (singleConversationItem) => {
    setConversationValues(singleConversationItem)
  }


  const handleCancelConversationDelete = (event) => {
    event.stopPropagation();
  }



  const handleLoadMoreConversation = (availableConversationCount) => {

    // 
    const reqConversationData = {
      type: APP_REQUEST_METHODS.CONVERSATION_REQUEST,
      data: {
        available: availableConversationCount
      }
    }



    const folderInfo = reduxGroupedFolderData?.find(item => item?.name.toLowerCase() === reduxCurrentFolder?.name.toLowerCase());


    const reqFolderConversationData = {
      type: APP_REQUEST_METHODS.FOLDER_CONVERSATION_REQUEST,
      data: {
        folderName: reduxCurrentFolder?.name,
        available: folderInfo?.conversations?.length
      }
    }

    if (socketRef.current && socketRef.current.readyState === 1) {

      const dataToSend = currentRouteFolder === undefined ? reqConversationData : reqFolderConversationData;


      // console.log('DATA TO SEND FOR  ========= > > > > > >', dataToSend);
      // console.log('hasMoreData  REQ CONVERSATION DATA  ========= > > > > > >', reqConversationData?.data?.available);
      // console.log('REQ FOLDER CONVERSATION DATA  ========= > > > > > >', reqFolderConversationData);



      socketRef.current.send(JSON.stringify(dataToSend));

      const totalConversations = reduxUserData?.conversationCount;
      const loadedConversations = currentRouteFolder === undefined ? availableConversationCount : folderInfo?.conversations?.length;


      // console.log('hasMoreData availableConversationCount', availableConversationCount);
      // console.log('hasMoreData loadedConversations', loadedConversations);
      // console.log('hasMoreData loadedConversations', loadedConversations);

      // console.log('hasMoreData loadedConversations < totalConversations, ', loadedConversations < totalConversations);
      setHasMoreData(loadedConversations < totalConversations);

      socketRef.current.onerror = (event) => {
        console.error("WebSocket error: ======================", event);

      }

    } else {
      // console.log("WebSocket connection not open in conversation");
      // enqueueSnackbar('WebSocket is Closed, Try connecting again.', {
      //   variant: 'error',
      // });
    }
  }



  const handleDeleteConversation = () => {
    enqueueSnackbar(`Conversation deleted Successfully.`, { variant: "success" });

    const reqConversationDelete = {
      type: APP_REQUEST_METHODS.CONVERSATION_DELETE,
      data: {
        number: conversationValues?.conversation?.number
      }
    }




    if (socketRef.current && socketRef.current.readyState === 1) {

      socketRef.current.send(JSON.stringify(reqConversationDelete));

      setIsDeleteConversationModalOpen(false)

      socketRef.current.onerror = (event) => {
        console.error("WebSocket error: ======================", event);

      }


    } else {
      console.error("WebSocket connection not open.");
      enqueueSnackbar('WebSocket is Closed, Try connecting again.', {
        variant: 'error',
      });
    }



    socketRef.current.onclose = (event) => {
      console.error("WebSocket closed ======================", event);
      enqueueSnackbar('WebSocket is Closed, Try connecting again.', {
        variant: 'error',
      });
    };

  }




  useEffect(() => {
    const fetchCodes = async () => {
      try {
        // Fetch short codes using the provided token
        const SHORT_CODES = await getShortCode(reduxUserData?.userToken);

        setAppShortCodes(SHORT_CODES)

      } catch (error) {
        console.error('Error fetching short code name:', error);
        return [];
      }
    };


    if (reduxUserData?.userToken && !appShortCodes?.data?.length) {
      fetchCodes();
    }


  }, [reduxUserData?.userToken, appShortCodes?.data?.length])







  useEffect(() => {
    if (currentPage > 0) {
      handleLoadMoreConversation(reduxConversations?.length);
      setHasMoreData(isFolderDataCompleted === 'true' ? false : true)
    }

  }, [currentPage, isFolderConversation, isFolderDataCompleted]);


  useEffect(() => {
    if (sortedConversations.length < 15 * (currentPage + 1)) {
      setHasMoreData(false);
    }
  }, [sortedConversations, currentPage]);



  console.log(';currentPage', currentPage);



  if (currentChatData.isChatActive) {
    return (
      <ChatSection
        appReduxData={wholeReduxData}
        currentChatData={currentChatData}
        conversationData={sortedConversations}
        // handleBackToConversation={() => setIsChatActive(false)}
        handleBackToConversation={handleRemoveChatSection}
      />
    )
  }




  console.log('isFolderDataCompleted in conversation ', isFolderDataCompleted);


  // console.log('hasMoreData currentPage ========= > > > > > >', currentPage);
  console.log('isFolderDataCompleted hasMoreData ========= > > > > > >', hasMoreData);
  // console.log('hasMoreData hasMoreData conversation count ========= > > > > > >', appReduxData?.conversations?.length);
  // console.log('hasMoreData hasMoreData total conversation count ========= > > > > > >', appReduxData?.userData?.conversationCount);
  // console.log('CONVERSATION_TO_RENDER ========= > > > > > >', CONVERSATION_TO_RENDER);
  // console.log('sortedConversations ========= > > > > > >', sortedConversations);





  // console.groupEnd()


  return (
    <div className="conversation-container">
      <AppTextAreaHeader
        foldername={currentRouteFolder?.name || "Inbox"}
      />

      <div className="conversation-elements" style={{ overflowX: 'hidden' }}>

        <AppLazyPagination
          // fetchNextPage={handleLoadMoreConversation}
          fetchNextPage={() => setCurrentPage((page) => page + 1)}
          // hasNextPage={conversationCount < appReduxData?.userData?.conversationCount}
          hasNextPage={hasMoreData}
          loadingMessage={<div style={{ display: 'flex', justifyContent: 'center', margin: '1rem auto' }}><AppLoading /></div>}
          // endingMessage={<p className="text-center my-2">You've reached the end of list.</p>}
          className="overflow-auto w-full"
          showLoading={isFolderConversation}

        >
          {sortedConversations?.length ? sortedConversations?.map((singlePost) => (
            <AppSingleConversation
              key={uuidv4()}
              folderId={folderId}
              singlePost={singlePost}
              contacts={reduxContacts}
              appShortCodes={appShortCodes}
              handleConversationClick={handleConversationClick}
              handleConversationValue={handleConversationValue}
              handleConversationDelete={handleConversationDelete}
              handleCancelConversationDelete={handleCancelConversationDelete}
            />
          )) : (
            <p className="text-center my-2" style={{ display: 'flex', justifyContent: 'center', margin: '1rem auto' }}>No Conversation Available.</p>
          )}

        </AppLazyPagination>

      </div>
      <AppActionModal
        heading="Delete"
        desc="Are you sure you want to delete this conversation?"
        cancelText="Cancel"
        confirmText="Yes"
        isOpenActionModal={isDeleteConversationModalOpen}
        handleCloseModal={() => setIsDeleteConversationModalOpen(false)}
        handleConfirm={handleDeleteConversation}
      />
    </div >
  );
};

export default ConversationSection;
