import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";



import appSlice from "./AppSlice";



export const APP_STORE = configureStore({
    reducer: {
        app: appSlice.reducer,
    },
    // middleware: (defaultMiddleware) => defaultMiddleware({ serializableCheck: false }).concat(WebsocketMiddleware),
});



export const useAppDispatch = useDispatch;
export const useAppSelector = useSelector;