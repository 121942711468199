import React, { useState, useEffect, useCallback } from "react";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";



/******** Ant Components ********/
import { Button, Col, Popover, Row, Input, Modal } from "antd";
import { CloseCircleOutlined } from '@ant-design/icons';



/******** Contexts ********/
import { useTheme } from "../context/theme";
import { useUser } from "../context/UserDataContext";
import { useWebSocket } from "../context/WebSocket";
import { useAppDispatch, useAppSelector } from "../redux";
import { addData, handleCurrentFolderData, handleHasFolderCreated, handleSectionToShow, setContactToSendMessage } from "../redux/AppSlice";




/******** Packages ********/
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';




import { getFolderIcon } from "../utils";
import { APP_INTERNAL_ROUTES, SECTION_STATUS } from "../config";
import { DUMMY_BILLS } from "../mock/Bills";



/******** Assets ********/
import "../styles/SideBar.scss";
import userPin from "../assets/userPin.svg";
import Logo from "../assets/logo-black.png";
import UserIcon from "../assets/useIcon2.svg";
import SearchIcon from "../assets/svg/search";
import LogoutIcon from "../assets/Logout.svg";
import MenuIcon from "../assets/three-dots.svg";
import { MessageIcon, SchedulerIcon, SettingsIcon } from "../assets/svg";
import { SettingsSidebarSection } from "./Settings";
import { AppActionModal, AppPopover } from "../components";



// ----------------------------------------------------------------------------------------------------------------



const Header = (props) => {

  const { contact, name, currentSectionToShow, setShowSettingsSidebar } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { updateUserData } = useUser();
  const { socketRef, setIsFetchingData } = useWebSocket();


  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);




  const handleLogout = () => {
    socketRef.current = '';
    updateUserData('');
    sessionStorage.removeItem("userData");
    localStorage.removeItem("userData");
    localStorage.removeItem("qrToken");
    localStorage.removeItem("ip");
    localStorage.removeItem("port");
    setIsFetchingData(false)
    navigate("/");
    window.location.reload();
    // Add cache-busting query parameter to navigate to home page
    // navigate(`/?cache=${Date.now()}`);
  };






  const content = (
    <div className="popmenu-div">
      <div className="menuItem-div"
        // onClick={() => dispatch(handleSectionToShow(SECTION_STATUS.SETTINGS))}
        onClick={() => setShowSettingsSidebar(true)}
        style={{ cursor: "pointer" }}
      >
        Settings
        <SettingsIcon />
      </div>
      <div
        onClick={() => setIsLogoutModalOpen(true)}
        className="menuItem-div"
        style={{ cursor: "pointer", color: "rgba(228, 83, 67, 1)" }}
      >
        Logout <img src={LogoutIcon} alt="LogoutIcon" />
      </div>
    </div>
  );




  return (
    <Row gutter={[16, 16]} align='middle' className="header-container">
      <Col xs={24} md={14} className="name-and-phone-container">
        <p className="name-container primary-heading">{name || "Salaam"}</p>
        <p className="phone-container secondary-text">
          {contact || "none"}
        </p>
      </Col>
      <Col xs={24} md={10} className="header-buttons">

        <AppPopover content="Inbox">
          <Link to={APP_INTERNAL_ROUTES.HOME} className={`${currentSectionToShow === SECTION_STATUS.INBOX && 'active'} svg-div`}
            // onClick={() => dispatch(handleSectionToShow(SECTION_STATUS.INBOX))}
            style={{ marginRight: '0.8rem' }}
          >
            <MessageIcon />
          </Link>
        </AppPopover>



        <AppPopover content="Schedule New Message">
          <Link to={APP_INTERNAL_ROUTES.SCHEDULER} className={`&& 'active'} svg-div`}
            // onClick={() => dispatch(handleSectionToShow(SECTION_STATUS.SCHEDULER))}
            style={{ marginRight: '0.8rem' }}
          >
            <SchedulerIcon />
          </Link>
        </AppPopover>

        <AppPopover content="Write New Message">
          <Link to={APP_INTERNAL_ROUTES.NEW_MESSAGE} className={`$'active'} svg-div`}>
            <MessageIcon />
          </Link>
        </AppPopover>
        <AppPopover content="Settings">
          <Popover
            placement="leftTop"
            content={content}
            color="rgba(1, 1, 3, 1)"
            width="200px"
            style={{
              backgroundColor: "rgba(1, 1, 3, 0.8)",
            }}
            trigger="click"
          >
            <img
              src={MenuIcon}
              style={{ cursor: "pointer", paddingLeft: '0.8rem', paddingRight: '0.5rem' }}
              alt="menu-icon"
            />
          </Popover>
        </AppPopover>
      </Col>
      <AppActionModal
        heading="Logout"
        desc="Are you sure you want to logout?"
        cancelText="Cancel"
        confirmText="Yes"
        isOpenActionModal={isLogoutModalOpen}
        handleCloseModal={() => setIsLogoutModalOpen(false)}
        handleConfirm={handleLogout}
      />
    </Row>
  );
};




// ----------------------------------------------------------------------------------------------------------------

const BillCards = (props) => {

  const [isVideoPopupVisible, setIsVideoPopupVisible] = useState(false);

  return (
    <div className="billCards-container">
      {props?.bills?.length > 0 || DUMMY_BILLS?.length > 0 ? (
        (props?.bills || DUMMY_BILLS)?.map((item) => {
          return (
            <div className="billCard" key={uuidv4()}>
              <div className="billCard-left">
                <h1 className="text-two">{item?.billTitle}</h1>
                <span className="billCard-desc">Your messages, your rules: Make every text count with our smart SMS app.</span>
                <div className="billCard-tags">
                  <button className="learn-more-btn cursor-pointer" onClick={() => setIsVideoPopupVisible(!isVideoPopupVisible)}>
                    Learn more
                  </button>
                </div>
              </div>
              <div className="billCard-right">
                <p className="right-text-one">
                  {moment(new Date()).format("DD")}
                </p>
                <p className="right-text-two">
                  {moment(new Date()).format("MMMM, YY")}
                </p>
                <p className="right-text-three">
                  {moment(new Date()).format("dddd")}
                </p>
                <img src={Logo} className="right-text-four" alt="site-logo" />

              </div>
            </div>
          );
        })
      ) : (
        <div className="no-bills-message-div">
          There are no bills at the moment
        </div>
      )}

      {/* isVideoPopupVisible */}
      <Modal Modal
        footer={null}
        className="modal-div"
        open={isVideoPopupVisible}
        closable={false}
        centered={true}
        rootClassName="app-video-modal"
        maskClosable={true}
        destroyOnClose
        onCancel={() => setIsVideoPopupVisible(false)}
        styles={
          {
            body: {
              borderRadius: "20px",
              backdropFilter: "blur(119.13749694824219px)",
              padding: 0
            },

          }
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <video width="100%" controls autoPlay loop playsinline muted>
            <source src="https://ersaal.pk/video/web_to_sms.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>

        </div>
      </Modal >


    </div>
  );
};




// ----------------------------------------------------------------------------------------------------------------


const Folder = ({ foldersData }) => {


  const defaultFolder = 'Jazz';

  const { folderId } = useParams();


  const dispatch = useAppDispatch();
  const { socketRef } = useWebSocket();
  const { secondaryColor } = useTheme().token;


  // const { initialData: appReduxData, currentFolder, isFolderCreated } = useAppSelector((state => state.app));

  const {
    userData: reduxUserData,
    contacts: reduxContacts,
    currentFolder: reduxCurrentFolder,
    conversations: reduxConversations,
    isFolderCreated: reduxIsFolderCreated,
    groupedFolderData: reduxGroupedFolderData,
    currentSectionToShow: reduxCurrentSectionToShow
} = useAppSelector((state => state.app));




  const [folderSearchText, setFolderSearchText] = useState('');
  const [isSearchInputVisible, setIsSearchInputVisible] = useState(false);
  const [currentFolderData, setCurrentFolderData] = useState([]);



  // ------------------------------------
  const handleSearchInputChange = (e) => {
    const searchText = e.target.value.toLowerCase();
    setFolderSearchText(searchText);


    // Filter folders based on search text
    const filteredFolders = foldersData.filter((folder) =>
      folder.name.toLowerCase().includes(searchText)
    );

    setCurrentFolderData(filteredFolders);
  };



  // ========= UPDATE FOLDER DATA ===========
  useEffect(() => {
    setCurrentFolderData(reduxGroupedFolderData)
  }, [reduxGroupedFolderData])



  // ------------------------------------
  const updateGroupedFolderData = useCallback((userData) => {

    // Get conversations from userData
    const allConversations = Object.values(userData?.conversation || {});


    // Update groupedFolderData state
    const groupedFolderData = userData.userData.folders.map(folder => {
      const folderConversations = allConversations.filter(conversation => {
        const contactNumber = conversation.conversation.number;
        return folder.contactList.some(contact => contact.number === contactNumber);
      });

      return { ...folder, conversations: folderConversations };
    });

    // Update existingData state
    userData = {
      ...userData,
      groupedFolderData: groupedFolderData
    };


    // sessionStorage.setItem("userData", JSON.stringify(userData));

    const updatedFolderData = groupedFolderData?.find(item => item?.name.toLowerCase() === reduxCurrentFolder?.name.toLowerCase());

    console.log('updatedFolderData ========>>>>>', updatedFolderData);


    dispatch(handleCurrentFolderData(updatedFolderData))

    dispatch(addData(userData));

  }, [reduxCurrentFolder, dispatch]);



  // ------------------------------------
  const updateUserWholeData = useCallback((conversationNumber, newMessage) => {

    // Retrieve existing data from sessionStorage
    const existingDataString = sessionStorage.getItem("userData");
    const existingData = existingDataString ? JSON.parse(existingDataString) : {};


    // const commonConversationNumber = conversationNumber?.number ?? conversationNumber;
    const commonConversationNumber = conversationNumber;


    // Check if the conversation already exists in the data
    if (existingData?.conversation[commonConversationNumber]) {
      // Conversation exists, update the messages (create a deep copy)
      const oldMessages = JSON.parse(JSON.stringify(existingData?.conversation[commonConversationNumber].messages));


      // Check if the message ID is already present in the existing messages
      const isMessageExists = oldMessages.find(message => message.id === newMessage.id);





      // && isMessageExists?.type === 6
      if (isMessageExists !== undefined) {




        // Message ID found, update the message
        const updatedMessages = oldMessages.map(message => {
          if (message.id === newMessage.id) {
            return newMessage;
          }
          return message;
        });
        // Set the updated messages with status
        existingData.conversation[commonConversationNumber].messages = updatedMessages;



        // // Update the message statuses based on message IDs
        // const updatedMessagesWithStatus = oldMessages.map(message => {
        //   // Check if the message ID needs a status update and type is not equal to 1
        //   if (newMessage.id === message.id && message.type === 6) {
        //     return { ...message, type: 2 };
        //   }
        //   return message;
        // });



        // console.log('old message has been updated with new status 8888888888888', updatedMessagesWithStatus);



        // // Set the updated messages with status
        // existingData.conversation[commonConversationNumber].messages = updatedMessagesWithStatus;

      } else {


        // Message ID not found, push the new message to the array
        existingData.conversation[commonConversationNumber].messages.push(newMessage);
      }

      // Update the conversation with the new messages
      existingData.conversation[commonConversationNumber] = {
        ...existingData.conversation[commonConversationNumber],
        messages: existingData.conversation[commonConversationNumber].messages,
      };
    } else {
      // Conversation doesn't exist, create a new one
      existingData.conversation[commonConversationNumber] = {
        conversation: {
          // ...commonConversationNumber
        },
        messages: [newMessage],
      };
    }

    // Set the messages data in the state
    // setMessagesData(existingData.conversation[conversationNumber].messages);

    // Update sessionStorage with the modified data
    // sessionStorage.setItem("userData", JSON.stringify(existingData));



    updateGroupedFolderData(existingData);
  }, [updateGroupedFolderData]);




  // ========= NEED TO UPDATE FROM REDUX DIRECTLY =========
  useEffect(() => {

    // Function to fetch data from sessionStorage
    const getsessionStorageData = () => {
      const sessionStorageData = sessionStorage.getItem('userData');
      return sessionStorageData ? JSON.parse(sessionStorageData) : null;
    };


    // Fetch data from sessionStorage
    const sessionStorageData = getsessionStorageData();



    // Set up event handlers for the socket
    if (socketRef.current && socketRef.current.readyState === 1 && reduxIsFolderCreated) {
      socketRef.current.onmessage = (event) => {
        const receivedEvent = JSON.parse(event.data);
        const parsedData = JSON.parse(receivedEvent);

        console.log(' ============ ANDROID DATA RECEIVED IN SIDEBAR-> FOLDER ------> ', parsedData);


        if (parsedData?.folders && parsedData?.folders?.length > 0) {
          const updatedFolders = [...reduxUserData?.folders, ...parsedData.folders];
          const updatedUserAndFoldersData = {
            ...reduxUserData,
            folders: updatedFolders,
          };


          // Update sessionStorage with the new user and folders data
          // sessionStorage.setItem('userData', JSON.stringify({
          //   ...sessionStorageData,
          //   userData: updatedUserAndFoldersData,
          // }));

          const allConversations = reduxConversations;

          // Update groupedFolderData state
          const groupedFolderData = updatedFolders.map(folder => {
            const folderConversations = allConversations.filter(conversation => {
              const contactNumber = conversation.conversation.number;
              return folder.contactList.some(contact => contact.number === contactNumber);
            });

            return { ...folder, conversations: folderConversations };
          });

          // Update sessionStorage with the new groupedFolderData


          const updatedData = {
            ...sessionStorageData,
            userData: updatedUserAndFoldersData,
            groupedFolderData,
          };

          // sessionStorage.setItem('userData', JSON.stringify(updatedData));

          // Dispatch an action if needed
          dispatch(addData(updatedData));
          dispatch(handleHasFolderCreated(false));

        }


        // if (parsedData?.conversation) {
        // if (parsedData?.conversation && parsedData?.conversation?.number) {
        //   const receivedNumber = parsedData.conversation.number;
        //   const receivedMessage = parsedData.message[0];

        //   updateUserWholeData(receivedNumber, receivedMessage);
        // }



      };

      // Set up event handlers to check for success or failure
      socketRef.current.onclose = (event) => {
        console.error("WebSocket closed ======================", event);
        // enqueueSnackbar('WebSocket is Closed, Try connecting again.', {
        //   variant: 'error',
        // });
      };

      socketRef.current.onerror = (event) => {
        console.error("WebSocket error: ======================", event);
      };
    }

  }, [reduxConversations, reduxUserData, dispatch, reduxIsFolderCreated, socketRef, updateUserWholeData]);




  return (
    <div className="folder-container">
      <div className="folder-header-container">
        <p className="folder-header-text">Folders</p>

        {isSearchInputVisible ? (
          <div style={{ display: 'flex', alignItems: 'center', height: "37px" }}>
            <Input
              value={folderSearchText}
              onChange={handleSearchInputChange}
              placeholder="Search..."
              className="search-input"
              style={{ width: '200px', marginRight: '8px', transition: 'margin-left 0.3s', borderColor: 'none', height: '40px' }}
            />
            <Button className="search-input-btn" icon={<CloseCircleOutlined style={{ color: 'rgb(180,180,180)' }} />}
              onClick={() => {
                setFolderSearchText('');
                setIsSearchInputVisible(false);
                setCurrentFolderData(foldersData);
              }}
            />
          </div>
        ) : (
          <div className="cursor-pointer" style={{ width: "25px" }} onClick={() => setIsSearchInputVisible(true)}><SearchIcon /></div>
        )}

      </div>
      <Row className="folders">
        {(currentFolderData?.length > 0) ? currentFolderData
          .slice()
          .sort((a, b) => {
            const folderOrder = { Jazz: -1, Others: 1 };
            return (
              (folderOrder[a?.name] || 0) - (folderOrder[b?.name] || 0) ||
              a?.name.localeCompare(b?.name)
            );
          })
          ?.map((item) => {

            return (
              <Col key={uuidv4()} xs={24} md={12} lg={12}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                {/* <a onClick={(e) => e.preventDefault()}> */}
                <Link to={`${APP_INTERNAL_ROUTES.CONVERSATION}/${item?.id}`}>
                  <p
                    className="folders-button primary-text"
                    style={{
                      borderBottom: item?.id === parseInt(folderId) && `2px solid ${secondaryColor}`,
                      gap: '7px',
                      paddingLeft: ' 1rem',
                      paddingRight: ' 1rem'
                    }}
                    onClick={() => dispatch(handleCurrentFolderData(item))}
                  >
                    <img
                      src={getFolderIcon(item?.icon)}
                      style={{
                        marginRight: "7px",
                        width: `${item?.icon === 2131231584 ? '25px' : '25px'}`,
                        height: '25px',
                        transform: `rotate(${item?.name === defaultFolder ? '-45' : '0'}deg)`,
                      }}
                      alt={item?.icon}
                    />
                    {item?.name}
                  </p>
                  <img
                    src={userPin}
                    alt="userpin"
                    style={{
                      height: 20,
                      position: 'absolute',
                      top: '-5px',
                      right: '-5px',
                      width: 15,
                      marginLeft: 10,
                      display: (item?.name === "Jazz" || item?.isPinned === true) ? "flex" : "none",
                      filter:
                        (item?.name === "Jazz" || item?.id === reduxCurrentFolder?.id) &&
                        "invert(0) drop-shadow(1px 1px 10px #000)",
                    }}
                  />
                </Link>
                {/* </a> */}
              </Col>
            );
          }) : (
          <Col>
            <p style={{ color: '#fff', paddingLeft: '1rem' }}>There is currenntly no folder at the moment.</p>
          </Col>
        )
        }
      </Row>
    </div>
  );
};




// ----------------------------------------------------------------------------------------------------------------


export const SideBarSection = ({
  name,
  bills,
  contact,
  profileUrl,
  allContacts,
  foldersData,
  conversations,
  currentSectionToShow,
  setShowSettingsSidebar,
  showSettingsSidebar,
  shouldNotRenderSidebar
}) => {


  const dispatch = useAppDispatch();


  const [folderSearchText, setFolderSearchText] = useState('');
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [isSearchInputVisible, setIsSearchInputVisible] = useState(false);




  const handleSearchInputChange = (e) => {
    e.preventDefault();
    const inputValue = e.target.value;
    setFolderSearchText(inputValue)
    // Filter contacts based on the search input
    const filteredContacts = allContacts.filter(contact =>
      contact.name.toLowerCase().includes(inputValue.toLowerCase()) ||
      contact.number.includes(inputValue)
    );
    setFilteredContacts(filteredContacts);
  };




  if (showSettingsSidebar) {
    return (
      <SettingsSidebarSection
        name={name}
        profileUrl={profileUrl}
      />
    );
  }


  const contactToFilter = (filteredContacts?.length > 0 && filteredContacts) || (allContacts?.length > 0 && allContacts)






  return (
    <Row className="sideBar-container">
      <Col xs={24}>
        <Row>
          <Col span={24}>
            <Header
              name={name}
              contact={contact}
              currentSectionToShow={currentSectionToShow}
              setShowSettingsSidebar={setShowSettingsSidebar}
            />
          </Col>

          {shouldNotRenderSidebar ? (
            <Col span={24}>
              <div className="new-message-container">
                <div className="message-head">
                  {!isSearchInputVisible && (
                    <h3 className="message-heading">New Message</h3>
                  )}
                  {isSearchInputVisible ? (
                    <div style={{ display: 'flex', alignItems: 'center', height: "90px", marginLeft: "auto", width: '100%' }}>
                      <Input
                        value={folderSearchText}
                        onChange={handleSearchInputChange}
                        placeholder="Search..."
                        className="search-input"
                        style={{ width: '100%', marginRight: '8px', transition: 'margin-left 0.3s', borderColor: 'none', height: '40px' }}
                      />
                      <Button className="search-input-btn" icon={<CloseCircleOutlined style={{ color: 'rgb(180,180,180)' }} />} onClick={() => { setFolderSearchText(''); setIsSearchInputVisible(false); setFilteredContacts(allContacts) }} />
                    </div>
                  ) : (
                    <div className="cursor-pointer" style={{ width: "25px" }} onClick={() => setIsSearchInputVisible(true)}><SearchIcon /></div>
                  )}
                </div>


                <div className="contacts">
                  {contactToFilter?.length > 0 ? contactToFilter?.map(singleContact => {
                    return (
                      <div className="single-contact cursor-pointer"
                        onClick={() => dispatch(setContactToSendMessage(singleContact))}>
                        <img
                          src={UserIcon}
                          alt="Pic"
                          key={uuidv4()}
                        />
                        <div className="contacts-name">
                          <h4 className="name">{singleContact?.name}</h4>
                          <p className="number">{singleContact?.number}</p>
                        </div>
                      </div>
                    )
                  }) : (
                    <p style={{ color: "#fff", width: '100%', textAlign: 'center' }}>
                      No Contact is available
                    </p>
                  )}
                </div>
              </div>

            </Col>
          ) : (
            <>
              <Col span={24}>
                <BillCards bills={bills} />
              </Col>
              <Col span={24} className=" -wrapper">
                <Folder
                  foldersData={foldersData}
                  conversations={conversations}
                />
              </Col>
            </>
          )}
        </Row>
      </Col>

      {!shouldNotRenderSidebar && (
        <Col span={24} style={{ marginTop: 'auto', marginBottom: "2rem" }}>
          <Link to={APP_INTERNAL_ROUTES.CREATE_FOLDER} className="create-folder-button-container">
            <Button className="create-folder-button secondary-heading">
              Create Folder +
            </Button>
          </Link>
        </Col>
      )}
    </Row>
  );
};
