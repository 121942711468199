// import { createSlice } from '@reduxjs/toolkit';
// import { SECTION_STATUS } from '../config';


// const initialState = {
//     userData:
//         { folders: [], userProfile: {}, userToken: '' },
//     conversation: [],
//     contacts: [],
//     scheduler: [],
//     groupedFolderData: [],
//     isChatSectionActive: false,
//     currentSectionToShow: SECTION_STATUS.INBOX,
//     currentConversation: {},
//     currentFolder: null,
//     isFolderCreated: false
// }


// export const appSlice = createSlice({
//     name: 'app',
//     initialState,
//     reducers: {
//         addData: (state, action) => {
//             // Use object spreading to create a new state object with the updated data
//             state.initialData = action.payload
//             // state.initialData = { ...action.payload };
//             console.log('dispatch called in store ->', action.payload);
//         },

//         handleChatSection: (state, action) => {
//             state.isChatSectionActive = action.payload
//         },

//         handleUserContactsAction: (state, action) => {
//             state.contacts = action.payload
//         },

//         handleSectionToShow: (state, action) => {
//             state.currentSectionToShow = SECTION_STATUS[action.payload] || SECTION_STATUS.INBOX;
//         },

//         handleCurrentConversationData: (state, action) => {
//             state.currentConversation = action.payload;
//         },

//         handleCurrentFolderData: (state, action) => {
//             state.currentFolder = action.payload;
//         },

//         handleHasFolderCreated: (state, action) => {
//             state.isFolderCreated = action.payload;
//         },
//         setContactToSendMessage: (state, action) => {
//             state.contactToSendMessage = action.payload;
//         }


//     },

// })


// // Action creators are generated for each case reducer function
// export const
//     {



//         handleUserContactsAction,






//         addData,
//         handleChatSection,
//         handleSectionToShow,
//         handleHasFolderCreated,
//         handleCurrentFolderData,
//         setContactToSendMessage,
//         handleCurrentConversationData
//     } = appSlice.actions


// export default appSlice;

import { createSlice } from '@reduxjs/toolkit';
import { SECTION_STATUS } from '../config';

const initialState = {
    userData: { folders: [], userProfile: {}, userToken: '' },
    conversations: [],
    contacts: [],
    scheduler: [],
    groupedFolderData: [],
    isChatSectionActive: false,
    currentSectionToShow: SECTION_STATUS.INBOX,
    currentConversation: {},
    currentFolder: null,
    isFolderCreated: false,
    contactToSendMessage: null,
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {

        addData: (state, action) => {
            console.log('action payload in store', action.payload);
            const newState = { ...state };

            // Check if action.payload values are valid before updating state
            if (action.payload) {
                // Update userData if action.payload.userData is valid
                if (action.payload?.userData?.userToken) {
                    newState.userData = action.payload.userData;
                }

                // Update conversations if action.payload.conversations is valid
                if (action.payload.conversations?.length) {
                    newState.conversations = action.payload.conversations;
                }

                // Update contacts if action.payload.contacts is valid
                if (action.payload.contacts?.length) {
                    newState.contacts = action.payload.contacts;
                }

                // Update scheduler if action.payload.scheduler is valid
                if (action.payload.scheduler?.length) {
                    newState.scheduler = action.payload.scheduler;
                }

                // Update groupedFolderData if action.payload.groupedFolderData is valid
                if (action.payload.groupedFolderData?.length) {
                    newState.groupedFolderData = action.payload.groupedFolderData;
                }
            }

            return newState;
        },




        // NEW SEPARATE DISPATCHES

        handleConversationsAction: (state, action) => {
            state.conversations = action.payload;
        },








        handleChatSection: (state, action) => {
            state.isChatSectionActive = action.payload;
        },

        handleSectionToShow: (state, action) => {
            state.currentSectionToShow = SECTION_STATUS[action.payload] || SECTION_STATUS.INBOX;
        },

        handleCurrentConversationData: (state, action) => {
            state.currentConversation = action.payload;
        },

        handleCurrentFolderData: (state, action) => {
            state.currentFolder = action.payload;
        },

        handleHasFolderCreated: (state, action) => {
            state.isFolderCreated = action.payload;
        },

        setContactToSendMessage: (state, action) => {
            state.contactToSendMessage = action.payload;
        },

        setIsFolderDataCompleted: (state, action) => {
            state.isFolderDataCompleted = action.payload;
        },
    }
});

// Action creators are generated for each case reducer function
export const {
    addData,
    handleChatSection,
    handleSectionToShow,
    handleHasFolderCreated,
    handleCurrentFolderData,
    setContactToSendMessage,
    setIsMessagesDataCompleted,
    handleCurrentConversationData
} = appSlice.actions;

export default appSlice;
