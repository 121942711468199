import React, { useEffect, useState } from "react";
import MainLogo from "../assets/logo.svg";
import "../styles/LoginSubscription.scss";
import { checkHe, loginGetOtpSub, loginVerifyOtpSub } from "../api/requests";
import OTPInput, { ResendOTP } from "otp-input-react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const LoginSubscription = () => {
  const [number, setNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtp, setIsOtp] = useState(false);
  const [error, setError] = useState("");
  const [resendTimer, setResendTimer] = useState(60);
  const [resendStatus, setResendStatus] = useState(false);
  const [token, setToken] = useState("");
  const [isCheck, setIsCheck] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const getHe = async () => {
      const response = await checkHe();
      if (response) {
        if (response.success === true) {
          if (number !== response.msisdn) {
            // Check if number is different
            setNumber(response.msisdn);
            getOtp(response.msisdn);
          }
        } else {
          console.log("could not get He");
        }
      } else {
        console.log("on https so could not get he");
      }
    };

    getHe();
  }, []);

  useEffect(() => {
    if (resendStatus) {
      resendTimer > 0 &&
        setTimeout(() => setResendTimer(resendTimer - 1), 1000);
    }
  }, [resendTimer, resendStatus]);

  const getOtp = async (number) => {
    if (number === "") {
      setError("Please enter your number");
    } else if (number.length < 10) {
      setError("Please enter your complete number");
    } else if (isCheck === false) {
      setError("Please confirm that you have read the terms and conditions");
    } else {
      setIsLoading(true);
      setError("");
      let body = {
        msisdn: "92" + number,
        companyName: "",
        package_id: 1,
      };

      const response = await loginGetOtpSub(body);
      if (response.success === true) {
        if (response.message === "Subscribed successfully") {
          console.log("response login is :::", response);
          setIsOtp(true);
          setToken(response.token);
          setResendStatus(true);
          setIsLoading(false);
        } else {
          // setIsLoading(false);
          setError(`${response.message}. Redirecting you to login`);
          setTimeout(() => {
            navigate("/login", {
              state: {
                isSubscribed: true,
              },
            });
          }, 3000);
        }
      } else {
        if (response.message === "Subscriber is from Other Network") {
          setError(response.message + ".Redirecting you to login");
          setTimeout(() => {
            navigate("/login", {
              state: {
                isSubscribed: true,
              },
            });
          }, 3000);

          setIsLoading(false);
        } else {
          setError(response.message);
          setIsLoading(false);
        }
      }
    }
  };

  const verifyOtp = async () => {
    if (otp === "") {
      setError("Please enter the otp sent to your number");
    } else if (otp.length < 4) {
      setError("Please enter complete otp");
    } else if (isCheck === false) {
      setError("Please confirm that you have read the terms and conditions");
    } else {
      setIsLoading(true);
      setError("");
      let body = {
        number: 92 + number,
        otp: otp,
        companyName: "",
      };

      const response = await loginVerifyOtpSub(body, token);
      if (response.success === true) {
        if (response.message === "OTP verified successfully") {
          setError("Package is Subscribed");
          setTimeout(() => {
            navigate("/login", {
              state: {
                isSubscribed: true,
              },
            });
          }, 3000);
        } else {
          setError(response.message);
        }
      } else {
        setError(response.message);
        setIsLoading(false);
      }
    }
  };
  const renderButton = () => {
    return resendTimer <= 0 ? (
      <button
        style={{
          background: "transparent",
          color: "#fff",
          fontSize: "10px",
          fontFamily: "Poppins",
          border: "none",
          fontWeight: "400",
          marginTop: "10px",
        }}
        // style={{ cursor: "pointer", direction: lang === "AR" ? "rtl" : "ltr" }}
        onClick={() => {
          getOtp(number);
          setResendTimer(60);
          setResendStatus(true);
        }}
      >
        Resend Otp
      </button>
    ) : (
      <button
        // style={{ direction: lang === "AR" ? "rtl" : "ltr" }}
        style={{
          background: "transparent",
          color: "#fff",
          fontSize: "10px",
          fontFamily: "Poppins",
          border: "none",
          fontWeight: "400",
          marginTop: "10px",
        }}
      >
        Your Otp will expire in
        <span
          style={{
            color: "#86FFC6",
            // fontFamily: "Manrope",
            fontSize: "12px",
            // direction: lang === "AR" ? "rtl" : "ltr",
          }}
        >
          {" "}
          {resendTimer} seconds
        </span>
      </button>
    );
  };

  const renderTime = () => React.Fragment;

  const antBtnIcon = (
    <LoadingOutlined
      style={{
        fontSize: 23,
        color: "black",
      }}
      spin
    />
  );

  return (
    <div className="main-container-div">
      <div className="left-container-div">
        <img src={MainLogo} alt="logo" />
        <div className="subscribe-text-and-input-container">
          <p className="subscribe-text">Login Now</p>
          <p className="mobile-number-text">Enter your mobile number</p>
          <div className="mobile-number-input-div">
            <p style={{ color: "white", margin: "0" }}>+92</p>
            <div
              style={{ height: "25px", border: "0.5px solid #86FFC6" }}
            ></div>
            <input
              value={number}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                let lengthCheck = 10;
                if (
                  e.target.value === "" ||
                  (regex.test(e.target.value) &&
                    e.target.value.length <= lengthCheck)
                ) {
                  setNumber(e.target.value);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    isOtp ? verifyOtp() : getOtp(number);
                }
            }}
              className="mobile-number-input"
              type="text"
              placeholder="XXXXXXXXXX"
            />
          </div>

          {isOtp && (
            <>
              <p className="mobile-number-text" style={{ marginTop: "25px" }}>
                Enter the OTP sent to your number to subscribe
              </p>
              <OTPInput
                value={otp}
                onChange={setOtp}
                placeholder="----"
                className="otp-div"
                OTPLength={4}
                otpType="number"
                inputClassName="otp-inputs"
                // className="otp-div"
              />
              <ResendOTP
                renderButton={renderButton}
                renderTime={renderTime}
                onResendClick={() => console.log("Resend clicked")}
              />
            </>
          )}
          {error !== "" && <p className="error-text">{error}</p>}
          <button
            className="subscribe-btn"
            style={{ marginTop: error !== "" && "25px" }}
            disabled={isLoading}
            onClick={() => {
              isOtp ? verifyOtp() : getOtp(number);
            }}
          >
            {isLoading ? (
              <Spin indicator={antBtnIcon} />
            ) : isOtp ? (
              "Submit"
            ) : (
              "Send Otp"
            )}
          </button>
          <div className="checkbox-terms-div">
            <input
              type="checkbox"
              className="checkbox-input"
              checked={isCheck}
              onChange={(e) => {
                // console.log("check boc value is :::", e.target.value);
                setIsCheck(!isCheck);
              }}
            />
            <p className="checkbox-text">
              I agree to{" "}
              <a
                style={{
                  textDecoration: "none",
                  color: "#86ffc6",
                  fontWeight: "600",
                }}
                href="https://ersaal.pk/ErsaalTermsandConditions.html"
                target="_blank"
              >
                Terms and Conditions
              </a>
            </p>
          </div>
        </div>
        <div className="subscription-plan-text-div">
          <p className="subscribe-text">Subscription Plan</p>
          <p className="plans-text">
            Daily: Rs 3+T(3.58) Weekly: Rs 10+T(11.95) Monthly: Rs 30+T(35.85)
          </p>
        </div>
      </div>
      <div className="right-container-div"></div>
    </div>
  );
};

export default LoginSubscription;
