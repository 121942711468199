import React from 'react';

import { Popover } from 'antd';


// ----------------------------------------------------------------------------------------------------------------


export const AppPopover = ({ arrow = true, title, trigger = 'hover', content, children, isTimePicker, placement = 'top' }) => {
    return (
        <Popover
            arrow={arrow}
            title={title}
            trigger={trigger}
            content={content}
            placement={placement}
            destroyTooltipOnHide
            rootClassName={`${isTimePicker ? 'time-picker-popup' : 'custom - popover'}`}
        >
            {children}
        </Popover>
    )
}