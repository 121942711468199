import React from 'react'

export default function ClockSvg() {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M23.4978 44.9994C33.7476 45 42.5724 37.7647 44.581 27.7136C46.5896 17.6625 41.2233 7.59121 31.7605 3.6525C22.2977 -0.286221 11.3702 3.00312 5.65404 11.511C-0.062156 20.0188 1.02273 31.3789 8.24592 38.6511C12.2815 42.714 17.7712 44.999 23.4978 44.9994Z" stroke="#B0B0B0" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M21.9492 14.2766V24.7557H31.983" stroke="#B0B0B0" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
  )
}
