import React from 'react'

export default function AddContactIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="44" viewBox="0 0 50 44" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M22.8181 8.37121C22.8181 12.2646 19.6619 15.4209 15.7684 15.4209C11.875 15.4209 8.71875 12.2646 8.71875 8.37121C8.71875 4.47778 11.875 1.32153 15.7684 1.32153C19.6619 1.32153 22.8181 4.47778 22.8181 8.37121Z" fill="#898989" stroke="#898989" strokeWidth="2.25789" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M29.8658 32.1405C29.8658 37.5796 23.5534 42.0125 15.7634 42.0125C7.97342 42.0125 1.66406 37.5917 1.66406 32.1405C1.66406 26.6893 7.97644 22.2715 15.7664 22.2715C23.5564 22.2715 29.8658 26.6893 29.8658 32.1405Z" fill="#898989" stroke="#898989" strokeWidth="2.25789" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M40.9655 1.84967V9.20922V1.84967ZM40.9655 9.20999V16.5695V9.20999ZM40.9689 9.20881H33.6094H40.9689ZM40.9677 9.20881H48.3273H40.9677Z" fill="#898989" />
      <path d="M42.0945 1.84967C42.0945 1.22617 41.589 0.720727 40.9655 0.720727C40.342 0.720727 39.8366 1.22617 39.8366 1.84967H42.0945ZM39.8366 9.20922C39.8366 9.83272 40.342 10.3382 40.9655 10.3382C41.589 10.3382 42.0945 9.83272 42.0945 9.20922H39.8366ZM42.0945 9.20999C42.0945 8.5865 41.589 8.08105 40.9655 8.08105C40.342 8.08105 39.8366 8.5865 39.8366 9.20999H42.0945ZM39.8366 16.5695C39.8366 17.193 40.342 17.6985 40.9655 17.6985C41.589 17.6985 42.0945 17.193 42.0945 16.5695H39.8366ZM40.9689 10.3378C41.5924 10.3378 42.0979 9.83231 42.0979 9.20881C42.0979 8.58531 41.5924 8.07987 40.9689 8.07987V10.3378ZM33.6094 8.07987C32.9859 8.07987 32.4804 8.58531 32.4804 9.20881C32.4804 9.83231 32.9859 10.3378 33.6094 10.3378V8.07987ZM40.9677 8.07987C40.3442 8.07987 39.8388 8.58531 39.8388 9.20881C39.8388 9.83231 40.3442 10.3378 40.9677 10.3378V8.07987ZM48.3273 10.3378C48.9508 10.3378 49.4562 9.83231 49.4562 9.20881C49.4562 8.58531 48.9508 8.07987 48.3273 8.07987V10.3378ZM39.8366 1.84967V9.20922H42.0945V1.84967H39.8366ZM39.8366 9.20999V16.5695H42.0945V9.20999H39.8366ZM40.9689 8.07987H33.6094V10.3378H40.9689V8.07987ZM40.9677 10.3378H48.3273V8.07987H40.9677V10.3378Z" fill="#898989" />
    </svg>
  )
}
