import React from 'react';


import { Button, Modal } from 'antd';


// ----------------------------------------------------------------------------------------------------------------


export const AppActionModal = (props) => {
    const { heading, desc, cancelText, confirmText, isOpenActionModal, handleCloseModal, handleConfirm } = props;

    return (
        <Modal
            footer={null}
            className="modal-div"
            open={isOpenActionModal}
            closable={false}
            centered={true}
            rootClassName="app-action-modal"
            maskClosable={true}
            destroyOnClose
            onCancel={handleCloseModal}
            styles={
                {
                    // mask: {
                    //     backdropFilter: "blur(2px)",
                    // },
                    body: {
                        borderRadius: "20px",
                        backdropFilter: "blur(119.13749694824219px)",
                    }
                }
            }
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <h3 className="heading primary-heading">{heading}</h3>
                <p className="desc primary-text">{desc}</p>
                <div className="modal-footer-btn">
                    <Button className="secondary-heading btn" onClick={handleCloseModal}>
                        {cancelText}
                    </Button>

                    <Button className="secondary-heading btn" onClick={handleConfirm}>
                        {confirmText}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}