import React from 'react'

export default function SortIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="18" viewBox="0 0 12 18" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.84363 6.24214L5.04106 2.01386C5.23822 1.7368 5.55726 1.57227 5.89731 1.57227C6.23736 1.57227 6.5564 1.7368 6.75356 2.01386L10.2489 6.24214C10.5351 6.59984 10.602 7.08574 10.423 7.50746C10.2441 7.92918 9.84817 8.21872 9.39205 8.26144H2.69821C2.24249 8.21799 1.84728 7.92817 1.66887 7.50658C1.49045 7.085 1.55755 6.59952 1.84363 6.24214Z" stroke="white" strokeWidth="1.54473" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.2489 12.5115L7.04921 16.7398C6.8521 17.0173 6.53279 17.1822 6.1924 17.1822C5.852 17.1822 5.53269 17.0173 5.33558 16.7398L1.84363 12.5115C1.55755 12.1541 1.49045 11.6686 1.66887 11.247C1.84728 10.8255 2.24249 10.5356 2.69821 10.4922H9.39205C9.84817 10.5349 10.2441 10.8244 10.423 11.2462C10.602 11.6679 10.5351 12.1538 10.2489 12.5115Z" stroke="white" strokeWidth="1.54473" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
