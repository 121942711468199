const protocol = window.location.protocol;
// let URL;
// console.log("protocol", protocol);
// if (protocol === "https") {
//   URL = "https://15.184.26.55:3300";
// } else {
//   URL = "http://15.184.26.55:3300";
// }

const URL = 'https://node.ersaal.pk:3300';
// const URL = 'https://stagingnode.ersaal.pk:3300';
// export const SOCKET_URL = 'stagingnode.ersaal.pk:3300';
export const SOCKET_URL = 'node.ersaal.pk:3300';


export const getSubscribers = (token, number) => {
  return fetch(`${URL}/get-subscriber`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(number),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log("error x is :::", error);
    });
};

export const getPackages = (token) => {
  return fetch(`${URL}/package-details`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    // body: JSON.stringify(body),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log("error is :::", error);
    });
};

export const otpForSubscription = (token, body) => {
  return fetch(`${URL}/Send-otp-for-subscription`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log("error is suv=bscruotino:::", error);
    });
};

export const verifyOtpSubscription = (token, body) => {
  return fetch(`${URL}/verify-subscriber-otp`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log("error is :::", error);
    });
};

export const unSubscribe = (token, body) => {
  return fetch(`${URL}/unsubscribe`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log("error is :::", error);
    });
};

export const getQrToken = (body) => {
  return fetch(`${URL}/qr-code-token`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log("error is :::", error);
    });
};

// Already sorted in socket
// export const authenticate = (token, userId, next) => {
//   if (typeof window !== "undefined") {
//     sessionStorage.setItem("token", JSON.stringify(token));
//     sessionStorage.setItem("userId", JSON.stringify(userId));
//     if(typeof next === 'function')
//       next();
//   }
// };

export const isAuthenticated = () => {
  if (typeof window === "undefined") {
    return false;
  }

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const userDataFromLocalStorage = JSON.parse(localStorage.getItem("userData"));

  // Check if a token exists and it's not empty or null
  if (
    // userData?.userData?.userToken &&
    // userData?.userData?.userToken !== "null" &&
    // userData?.userData?.userToken !== "undefined"
    userDataFromLocalStorage?.id &&
    userDataFromLocalStorage?.id !== "null" &&
    userDataFromLocalStorage?.id !== "undefined"
  ) {
    return true;
  } else {
    return false;
  }
};

export const checkOperator = (body) => {
  return fetch(`${URL}/checkOperatorType`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log("error is :::", error);
    });
};

export const updateProfile = (body, token) => {
  return fetch(`${URL}/update-profile`, {
    method: "POST",
    headers: {
      // "Accept": "application/json",
      // "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body,
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log("error in updating profile :::", error);
    });
};

export const getProfile = (body, token) => {
  return fetch(`${URL}/profile`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log("error in getting profile :::", error);
    });
};

export const getShortCode = (token) => {
  return fetch(`${URL}/shortCode-data`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log("error in getting shortcode :::", error);
    });
};

export const checkHe = () => {
  return fetch("http://node.ersaal.pk:3301/getMsisdn", {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log("error is :::", error);
    });
};

export const loginGetOtpSub = (body) => {
  return fetch(`${URL}/subscriberFromLandingPage`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
    })
    .catch((error) => {
      console.log("error is ;::", error);
    });
};

export const loginVerifyOtpSub = (body, token) => {
  return fetch(`${URL}/verify-subscriber-otp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token} `,
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .catch((error) => {
      console.log("error is :::", error);
    });
};
