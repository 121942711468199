import React from 'react'

export default function SchedulerIcon() {
    return (
        <svg width="37" height="38" viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.1436 1.42871V5.47581M18.9359 1.42871V5.47581M2.07295 10.9934H25.0065M25.681 10.1974V21.6642C25.681 25.7113 23.6575 28.4094 18.9359 28.4094H8.1436C3.42199 28.4094 1.39844 25.7113 1.39844 21.6642V10.1974C1.39844 6.15033 3.42199 3.45226 8.1436 3.45226H18.9359C23.6575 3.45226 25.681 6.15033 25.681 10.1974Z" stroke="white" strokeWidth="1.70417" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M23.6114 37.8504C29.7211 37.8508 34.9815 33.5379 36.1788 27.5465C37.3761 21.5552 34.1773 15.5518 28.5366 13.204C22.896 10.8562 16.3823 12.8169 12.975 17.8884C9.56766 22.9598 10.2143 29.7314 14.52 34.0662C16.9255 36.4881 20.1979 37.8502 23.6114 37.8504Z" fill="#13150F" />
            <path fillRule="evenodd" clipRule="evenodd" d="M23.6754 34.21C28.0166 34.2103 31.7542 31.1458 32.6049 26.8888C33.4556 22.6318 31.1828 18.3663 27.175 16.6981C23.1671 15.0299 18.5389 16.4231 16.1179 20.0264C13.6969 23.6298 14.1564 28.4412 17.2157 31.5213C18.9249 33.2421 21.25 34.2098 23.6754 34.21Z" stroke="white" strokeWidth="1.70417" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M23.0703 20.8535V25.7098H27.9266" stroke="white" strokeWidth="1.70417" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}
