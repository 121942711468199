import React, { createContext, useContext } from "react";

import { ConfigProvider } from "antd";

import { APP_THEME_CONFIG } from "../theme";


// Context to store the theme variables
const ThemeContext = createContext();


// ----------------------------------------------------------------------------------------------------------------


const AppThemeProvider = ({ children }) => {
    return (
        <ThemeContext.Provider value={APP_THEME_CONFIG}>
            <ConfigProvider theme={APP_THEME_CONFIG}> {children}</ConfigProvider>
        </ThemeContext.Provider>
    )
};


// ----------------------------------------------------------------------------------------------------------------


// Custom hook to access the theme variables
const useTheme = () => {
    const themeVariables = useContext(ThemeContext);
    return themeVariables;
};


export { AppThemeProvider, useTheme };
