import React from 'react'

export default function MessageIcon() {
    return (
        <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M25.2873 1.76742L28.9424 4.70569C29.2954 5.04106 29.4943 5.50949 29.4917 5.99882C29.489 6.48816 29.285 6.95436 28.9284 7.28581L26.1509 10.6411L16.7005 22.0196C16.5352 22.2107 16.3148 22.345 16.0703 22.4037L11.1689 23.5252C10.5258 23.5577 9.96599 23.0859 9.88281 22.4414V17.3565C9.8995 17.1114 9.99732 16.879 10.1606 16.6968L19.4359 6.11003L22.6195 2.27166C23.2494 1.4382 24.4015 1.22044 25.2873 1.76742Z" stroke="white" strokeWidth="1.89352" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.0289 3.9741C15.5518 3.9741 15.9757 3.55022 15.9757 3.02734C15.9757 2.50446 15.5518 2.08059 15.0289 2.08059V3.9741ZM9.1468 3.02734V2.08058L9.14453 2.08059L9.1468 3.02734ZM1.30471 10.9052H2.25147L2.25146 10.9031L1.30471 10.9052ZM1.30471 22.7219L2.25146 22.7239V22.7219H1.30471ZM9.1468 30.5997L9.14453 31.5464H9.1468V30.5997ZM20.911 30.5997V31.5464L20.9133 31.5464L20.911 30.5997ZM28.7531 22.7219H27.8063L27.8063 22.7239L28.7531 22.7219ZM29.6999 14.8441C29.6999 14.3212 29.276 13.8973 28.7531 13.8973C28.2302 13.8973 27.8063 14.3212 27.8063 14.8441H29.6999ZM20.7639 5.76978C20.5864 5.27796 20.0438 5.02318 19.552 5.20071C19.0601 5.37825 18.8054 5.92086 18.9829 6.41268L20.7639 5.76978ZM25.8807 11.2813C26.4011 11.2302 26.7815 10.7669 26.7304 10.2465C26.6793 9.72616 26.216 9.34575 25.6956 9.39686L25.8807 11.2813ZM15.0289 2.08059H9.1468V3.9741H15.0289V2.08059ZM9.14453 2.08059C4.28105 2.09227 0.347538 6.0437 0.357949 10.9072L2.25146 10.9031C2.24329 7.08521 5.33117 3.98327 9.14908 3.9741L9.14453 2.08059ZM0.357947 10.9052V22.7219H2.25146V10.9052H0.357947ZM0.357949 22.7198C0.347538 27.5833 4.28105 31.5348 9.14453 31.5464L9.14908 29.6529C5.33117 29.6438 2.24329 26.5418 2.25146 22.7239L0.357949 22.7198ZM9.1468 31.5464H20.911V29.6529H9.1468V31.5464ZM20.9133 31.5464C25.7768 31.5348 29.7103 27.5833 29.6999 22.7198L27.8063 22.7239C27.8145 26.5418 24.7266 29.6438 20.9087 29.6529L20.9133 31.5464ZM29.6999 22.7219V14.8441H27.8063V22.7219H29.6999ZM18.9829 6.41268C19.306 7.30783 20.0144 8.58952 21.129 9.61824C22.261 10.663 23.8556 11.4802 25.8807 11.2813L25.6956 9.39686C24.3342 9.53059 23.2519 9.00074 22.4133 8.22678C21.5573 7.43681 21.0016 6.42825 20.7639 5.76978L18.9829 6.41268Z" fill="white" />
        </svg>
    )
}
