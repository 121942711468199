import React from 'react'

export default function GiftIcon() {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="19" viewBox="0 0 14 19" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M0.859562 9.2004C0.8505 8.372 1.17092 7.57393 1.75031 6.98178C2.32971 6.38964 3.12062 6.05193 3.94903 6.04297H10.128C10.9564 6.05193 11.7473 6.38964 12.3267 6.98178C12.9061 7.57393 13.2265 8.372 13.2174 9.2004V14.4628C13.2357 16.1875 11.8526 17.6005 10.128 17.6192H3.94903C3.12062 17.6102 2.32971 17.2725 1.75031 16.6804C1.17092 16.0882 0.8505 15.2902 0.859562 14.4618V9.2004Z" stroke="white" strokeWidth="1.54473" strokeLinecap="round" strokeLinejoin="round" />
          <path fillRule="evenodd" clipRule="evenodd" d="M7.75262 3.04217L8.18721 4.75271L8.36125 5.33147L8.6084 4.35828C8.70226 3.93913 8.96254 3.57617 9.32944 3.35281C9.69633 3.12945 10.1383 3.0649 10.5537 3.17399C11.4185 3.44499 11.9146 4.35081 11.6773 5.22539C11.5773 5.68093 11.1871 6.01478 10.7216 6.04307H4.98343C4.03121 5.99883 3.23479 5.30478 3.06076 4.36755C2.69444 3.02994 3.45348 1.64254 4.77747 1.22969C5.41303 1.06283 6.08912 1.1617 6.65029 1.50357C7.21145 1.84543 7.6094 2.40088 7.75262 3.04217Z" stroke="white" strokeWidth="1.54473" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M9.10623 5.51142C9.2053 5.09652 8.94927 4.67986 8.53437 4.58079C8.11947 4.48171 7.70281 4.73774 7.60374 5.15265L9.10623 5.51142ZM7.43381 5.86425C7.33474 6.27915 7.59077 6.69581 8.00567 6.79488C8.42058 6.89396 8.83723 6.63792 8.93631 6.22302L7.43381 5.86425ZM7.81143 6.04385C7.81143 5.61728 7.46563 5.27148 7.03906 5.27148C6.6125 5.27148 6.2667 5.61728 6.2667 6.04385H7.81143ZM6.2667 17.6201C6.2667 18.0466 6.6125 18.3924 7.03906 18.3924C7.46563 18.3924 7.81143 18.0466 7.81143 17.6201H6.2667ZM7.60374 5.15265L7.43381 5.86425L8.93631 6.22302L9.10623 5.51142L7.60374 5.15265ZM6.2667 6.04385V17.6201H7.81143V6.04385H6.2667Z" fill="white" />
      </svg>
  )
}
