import React from 'react'

export default function CameraIcon() {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M0.828125 6.84727V11.3095C0.832485 12.261 1.21471 13.1717 1.8907 13.8413C2.56668 14.5109 3.48102 14.8845 4.4325 14.8799H11.6412C12.5927 14.8845 13.5071 14.5109 14.183 13.8413C14.859 13.1717 15.2413 12.261 15.2456 11.3095V6.84727C15.2413 5.89579 14.859 4.98503 14.183 4.31542C13.5071 3.64581 12.5927 3.27222 11.6412 3.27688C11.1415 3.21059 10.7145 2.88399 10.5198 2.41903C10.2004 1.84526 9.59461 1.49028 8.93797 1.4922H7.13578C6.47914 1.49028 5.8733 1.84526 5.55397 2.41903C5.3592 2.88399 4.93223 3.21059 4.4325 3.27688C3.48102 3.27222 2.56668 3.64581 1.8907 4.31542C1.21471 4.98503 0.832485 5.89579 0.828125 6.84727Z" stroke="white" strokeWidth="1.54473" strokeLinecap="round" strokeLinejoin="round" />
          <path fillRule="evenodd" clipRule="evenodd" d="M8.03812 11.3088C6.80838 11.2969 5.82006 10.2924 5.82817 9.0626C5.83629 7.83283 6.83778 6.8414 8.06757 6.84572C9.29736 6.85003 10.2919 7.84845 10.2914 9.07825C10.2884 9.67285 10.0493 10.2419 9.62673 10.6602C9.20416 11.0785 8.63272 11.3119 8.03812 11.3088Z" stroke="white" strokeWidth="1.54473" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
  )
}
