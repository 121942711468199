import React from 'react'

export default function MoneyBagIcon() {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
          <path d="M8.06957 13.6396C7.965 13.4156 7.74846 13.2522 7.4864 13.2231C7.26453 13.1985 7.0473 13.1521 6.8407 13.0853C6.74803 13.0549 6.6611 13.0217 6.58065 12.9862L6.71432 12.6308C6.80873 12.6719 6.90604 12.7095 7.00562 12.7435L7.00978 12.7449L7.01396 12.7463C7.33361 12.8492 7.6481 12.9014 7.94869 12.9014C8.25036 12.9014 8.46914 12.862 8.63719 12.7774L8.64724 12.7724L8.65705 12.7669C8.91164 12.6245 9.05766 12.3796 9.05766 12.0951C9.05766 11.7794 8.88297 11.5173 8.57822 11.3757C8.42502 11.3029 8.20731 11.2336 7.89372 11.1576C7.57212 11.0783 7.30191 10.9941 7.09241 10.9079C6.93917 10.8404 6.80634 10.7325 6.68656 10.5784C6.5835 10.4408 6.53125 10.2339 6.53125 9.96316C6.53125 9.63656 6.6256 9.36696 6.8197 9.13893C6.99255 8.93909 7.23793 8.80344 7.56728 8.7273C7.8115 8.67082 8.0021 8.50083 8.08777 8.28125C8.18459 8.50386 8.39072 8.66933 8.6471 8.70917C8.86132 8.74247 9.05803 8.79542 9.23485 8.86725L9.11175 9.21554C8.77786 9.08486 8.44927 9.01879 8.13148 9.01879C7.07596 9.01879 7.0225 9.7288 7.0225 9.87114C7.0225 10.1797 7.19339 10.4384 7.49137 10.581L7.49974 10.585L7.50823 10.5887C7.65868 10.6542 7.87229 10.7173 8.18052 10.7874C8.49918 10.8576 8.7649 10.938 8.97372 11.0272L8.97801 11.029L8.98235 11.0308C9.13741 11.0937 9.27041 11.1988 9.38895 11.3519L9.39084 11.3544L9.39275 11.3568C9.49637 11.4874 9.5489 11.6894 9.5489 11.9571C9.5489 12.2662 9.45711 12.5278 9.26826 12.7569C9.10452 12.9557 8.8758 13.0932 8.56898 13.1775C8.33205 13.2425 8.14824 13.4191 8.06957 13.6396Z" fill="white" />
          <path d="M8.33475 14.5642H7.8262C7.71246 14.5642 7.62024 14.472 7.62024 14.3583V13.9448C7.62024 13.836 7.5356 13.7466 7.42751 13.7346C7.17001 13.706 6.9209 13.6528 6.68019 13.5749C6.44634 13.4982 6.24376 13.4071 6.07242 13.3016C5.99367 13.2531 5.96503 13.1546 5.99758 13.068L6.3464 12.1407C6.39338 12.0158 6.54498 11.968 6.66176 12.0326C6.81935 12.1197 6.98869 12.1941 7.16979 12.2559C7.43963 12.3428 7.69858 12.3863 7.94665 12.3863C8.15991 12.3863 8.31223 12.3633 8.40362 12.3172C8.49502 12.2661 8.54071 12.192 8.54071 12.0948C8.54071 11.9824 8.47979 11.898 8.35793 11.8418C8.24042 11.7855 8.04458 11.7242 7.77039 11.6577C7.41787 11.5708 7.1241 11.4788 6.88909 11.3816C6.65407 11.2794 6.44952 11.1158 6.27544 10.8908C6.10134 10.6608 6.0143 10.3514 6.0143 9.96288C6.0143 9.51299 6.1514 9.12698 6.42558 8.80489C6.67265 8.51919 7.01387 8.326 7.44927 8.22535C7.54727 8.20268 7.62024 8.11769 7.62024 8.0171V7.56143C7.62024 7.44768 7.71246 7.35547 7.8262 7.35547H8.33475C8.4485 7.35547 8.54071 7.44768 8.54071 7.56143V7.99106C8.54071 8.09628 8.62016 8.18393 8.72413 8.20009C9.10551 8.25936 9.44061 8.37153 9.72944 8.5366C9.81134 8.58341 9.84391 8.68316 9.81247 8.7721L9.48601 9.69578C9.44274 9.81817 9.29854 9.86997 9.18261 9.81153C8.81486 9.62613 8.46381 9.53343 8.12944 9.53343C7.73339 9.53343 7.53537 9.6459 7.53537 9.87086C7.53537 9.97823 7.59413 10.06 7.71163 10.1163C7.82915 10.1674 8.02282 10.2236 8.29264 10.285C8.64081 10.3617 8.93458 10.4511 9.17395 10.5534C9.41331 10.6505 9.62004 10.8116 9.79413 11.0365C9.97257 11.2615 10.0618 11.5682 10.0618 11.9568C10.0618 12.3863 9.92905 12.762 9.66356 13.0841C9.43226 13.3649 9.11218 13.5614 8.70332 13.6737C8.60934 13.6995 8.54071 13.7828 8.54071 13.8802V14.3583C8.54071 14.472 8.4485 14.5642 8.33475 14.5642Z" fill="white" />
          <path fillRule="evenodd" clipRule="evenodd" d="M5.81853 1.17578H10.2777C11.4197 1.17578 10.7926 2.8894 10.3302 3.93467L9.80394 5.13338L9.73288 5.29507C10.2313 5.27088 10.7242 5.40891 11.1376 5.68846C12.6838 7.10326 13.9386 8.80657 14.8315 10.7027C15.1795 11.4038 15.3176 12.1905 15.229 12.9683C15.1228 14.9506 13.5325 16.5302 11.5495 16.6231H4.5467C2.56321 16.533 0.970499 14.955 0.862002 12.9724C0.773482 12.1947 0.91152 11.4079 1.25951 10.7068C2.1535 8.80878 3.41013 7.10399 4.95863 5.68846C5.37201 5.40891 5.86486 5.27088 6.36331 5.29507L6.28092 5.10867L5.76601 3.93467C5.30568 2.8894 4.67543 1.17578 5.81853 1.17578Z" stroke="white" strokeWidth="1.54473" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M9.72923 6.06769C10.1558 6.06769 10.5016 5.72189 10.5016 5.29532C10.5016 4.86876 10.1558 4.52296 9.72923 4.52296V6.06769ZM6.35965 4.52296C5.93309 4.52296 5.58729 4.86876 5.58729 5.29532C5.58729 5.72189 5.93309 6.06769 6.35965 6.06769V4.52296ZM12.5387 4.93904C12.9106 4.73015 13.0428 4.25931 12.8339 3.88739C12.625 3.51548 12.1541 3.38332 11.7822 3.59221L12.5387 4.93904ZM9.797 5.13376L9.94244 5.89232L9.94461 5.89189L9.797 5.13376ZM6.27399 5.10905L6.43005 4.35258L6.42316 4.35123L6.27399 5.10905ZM4.28825 3.58569C3.91273 3.38334 3.44428 3.52373 3.24194 3.89925C3.03959 4.27477 3.17998 4.74322 3.5555 4.94556L4.28825 3.58569ZM9.72923 4.52296H6.35965V6.06769H9.72923V4.52296ZM11.7822 3.59221C11.1176 3.96548 10.3976 4.22996 9.6494 4.37563L9.94461 5.89189C10.8546 5.71472 11.7304 5.39304 12.5387 4.93904L11.7822 3.59221ZM9.65157 4.37521C8.58667 4.57938 7.49198 4.5717 6.43005 4.35261L6.11793 5.86548C7.37862 6.12558 8.67821 6.13469 9.94244 5.89231L9.65157 4.37521ZM6.42316 4.35123C5.67721 4.20438 4.95754 3.94632 4.28825 3.58569L3.5555 4.94556C4.36097 5.37958 5.22707 5.69015 6.12481 5.86687L6.42316 4.35123Z" fill="white" />
      </svg>
  )
}
