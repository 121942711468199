import { APP_COLORS, APP_FONT_SIZE, APP_PRIMARY_FONT } from "./theme-utils";


export const APP_THEME_CONFIG = {

    token: {
        colorPrimary: APP_COLORS.PRIMARY_COLOR,
        colorPrimaryBg: APP_COLORS.PRIMARY_COLOR,
        colorPrimaryHover: APP_COLORS.PRIMARY_COLOR,
        colorWhite: APP_COLORS.WHITE_COLOR,
        colorPrimarySpin: APP_COLORS.TYPOGRAPHY,

        secondaryColor: APP_COLORS.TERTIARY_COLOR,


        ...APP_FONT_SIZE,
    },

    /******** OVERWRITE ANT DESIGN COMPONENTS BUILT-IN THEME ********/
    components: {
        Button: {
            primaryShadow: 'none',
        },
        Typography: {
            colorText: APP_COLORS.TYPOGRAPHY,
            fontFamilyCode: APP_PRIMARY_FONT,
        },
        Input: {
            colorPrimaryHover: APP_COLORS.PRIMARY_COLOR,
            colorBgContainer: APP_COLORS.INPUT.BG_COLOR,
            hoverBorderColor: APP_COLORS.PRIMARY_COLOR,
            activeBorderColor: APP_COLORS.PRIMARY_COLOR,
            colorText: APP_COLORS.WHITE_COLOR,
            colorTextPlaceholder: APP_COLORS.GREY_COLOR,
        },
        Checkbox: {
            colorPrimary: APP_COLORS.SECONDARY_COLOR,
            colorPrimaryHover: APP_COLORS.SECONDARY_COLOR,
            colorBorder: APP_COLORS.SECONDARY_COLOR,
            colorWhite: APP_COLORS.WHITE_COLOR,
            borderRadiusSM: 4,
        },
        Select: {
            colorPrimary: APP_COLORS.INPUT.CHECK_COLOR,
            // colorText: APP_COLORS.INPUT.CHECK_COLOR,
            fontSize: APP_COLORS.FONT_SIZE_15,
            colorText: APP_COLORS.WHITE_COLOR,

        },
        Spin: {
            colorPrimary: APP_COLORS.TYPOGRAPHY,
            colorWhite: APP_COLORS.WHITE_COLOR,
            // dotSize: APP_FONT_SIZE.FONT_SIZE_25,
            // fontSize: APP_FONT_SIZE.FONT_SIZE_25,
        },
        Modal: {
            colorBgElevated: APP_COLORS.BG_DARK_GREY_COLOR,
        },
        Popover: {
            colorBgElevated: APP_COLORS.TOOLTIP_COLOR,
            colorText: APP_COLORS.WHITE_COLOR,
            colorTextHeading: APP_COLORS.WHITE_COLOR,
        },
        Badge: {
            colorPrimary: APP_COLORS.BADGE_COLOR,
            colorError: APP_COLORS.BADGE_COLOR,
            colorBorderBg:APP_COLORS.BADGE_COLOR
        },
        Dropdown: {
            colorBgElevated: APP_COLORS.BLACK_COLOR,
            colorText: APP_COLORS.WHITE_COLOR,
            

        }
    },
};
