export const DUMMY_BILLS = [
    {
        billTitle: 'Ersaal',
        desc: '',
        tags: [
            {
                tagsname: 'Gas Bill'
            },
            {
                tagsname: 'Bill'
            },
            {
                tagsname: 'Tomorrow'
            },
        ]

    },
    // {
    //   billTitle: 'Ersaal',
    //   desc: '',
    //   tags: [
    //     {
    //       tagsname: 'Gas Bill'
    //     },
    //     {
    //       tagsname: 'Bill'
    //     },
    //     {
    //       tagsname: 'Tomorrow'
    //     },
    //   ]

    // }
]