import React from 'react'

export default function PizzaSliceIcon() {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M14.3371 5.80096C15.4417 8.02121 15.8372 10.5273 15.4699 12.9798L12.8706 13.0828L1.17188 13.5668L7.42907 3.45605L8.81933 1.20898C11.0957 2.12133 13.0265 3.72815 14.3371 5.80096Z" stroke="white" strokeWidth="1.54473" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M12.8713 13.0849C12.929 11.0938 12.4449 9.12442 11.4707 7.38694C10.5303 5.71461 9.12713 4.34933 7.42969 3.45508" stroke="white" strokeWidth="1.54473" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M10.2024 10.7154C9.91849 10.7154 9.6875 10.4844 9.6875 10.2005C9.6875 9.91654 9.91849 9.68555 10.2024 9.68555C10.4863 9.68555 10.7173 9.91654 10.7173 10.2005C10.7173 10.4844 10.4863 10.7154 10.2024 10.7154Z" fill="white" />
          <path d="M10.2017 9.16992C10.7704 9.16992 11.2315 9.63099 11.2315 10.1997C11.2315 10.7685 10.7704 11.2296 10.2017 11.2296C9.63295 11.2296 9.17188 10.7685 9.17188 10.1997C9.17188 9.63099 9.63295 9.16992 10.2017 9.16992Z" fill="white" />
          <path d="M5.58522 11.3013C5.3013 11.3013 5.07031 11.0703 5.07031 10.7864C5.07031 10.5025 5.3013 10.2715 5.58522 10.2715C5.86914 10.2715 6.10013 10.5025 6.10013 10.7864C6.10013 11.0703 5.86914 11.3013 5.58522 11.3013Z" fill="white" />
          <path d="M5.58451 9.75586C6.15326 9.75586 6.61433 10.2169 6.61433 10.7857C6.61433 11.3544 6.15326 11.8155 5.58451 11.8155C5.01576 11.8155 4.55469 11.3544 4.55469 10.7857C4.55469 10.2169 5.01576 9.75586 5.58451 9.75586Z" fill="white" />
          <path d="M8.14772 7.62552C7.8638 7.62552 7.63281 7.39454 7.63281 7.11061C7.63281 6.82669 7.8638 6.5957 8.14772 6.5957C8.43164 6.5957 8.66263 6.82669 8.66263 7.11061C8.66263 7.39454 8.43164 7.62552 8.14772 7.62552Z" fill="white" />
          <path d="M8.14701 6.08008C8.71576 6.08008 9.17683 6.54115 9.17683 7.1099C9.17683 7.67865 8.71576 8.13972 8.14701 8.13972C7.57826 8.13972 7.11719 7.67865 7.11719 7.1099C7.11719 6.54115 7.57826 6.08008 8.14701 6.08008Z" fill="white" />
      </svg>
  )
}
