import React, { useEffect } from 'react';

import { useRoutes } from 'react-router-dom';


import { APP_ROUTES } from './Routes/MainRouter';




const App = () => {


  const pages = useRoutes(APP_ROUTES);

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     const message = "Are you sure you want to leave? You will lose your current data";
  //     event.returnValue = message; // Standard for most browsers
  //     return message; // For some older browsers
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
      
  //   };
  // }, []);

  
  return (
    <React.Fragment>{pages}</React.Fragment>
  )
}


export default App;