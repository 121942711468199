import React from 'react'

export default function ShoppingBagIcon() {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M1.17253 13.3361C1.13349 15.4914 2.84822 17.2706 5.00346 17.3112H11.7591C13.9143 17.2706 15.6291 15.4914 15.59 13.3361L15.14 8.50831C15.0258 6.68824 13.7521 5.14892 11.9857 4.69591C11.6175 4.58884 11.2362 4.53406 10.8528 4.5332H5.90971C5.52634 4.53406 5.14501 4.58884 4.7769 4.69591C3.0115 5.14906 1.73854 6.68725 1.62359 8.50625L1.17253 13.3361Z" stroke="white" strokeWidth="1.54473" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M11.9822 6.3833V4.30203C11.9433 2.34967 10.3302 0.797865 8.37781 0.834624C6.42541 0.797865 4.81231 2.34967 4.77344 4.30203V6.38227" stroke="white" strokeWidth="1.54473" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
  )
}
