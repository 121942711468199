import React from 'react'

import { useTheme } from '../context/theme';


import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";



// ----------------------------------------------------------------------------------------------------------------


export const AppLoading = (props) => {


    const { isShowInitialLoading, isFetchingData, pageLoading } = props;

    const { colorWhite, colorPrimarySpin, FONT_SIZE_25 } = useTheme().token; // Access theme tokens


    const antIcon = (
        <LoadingOutlined spin style={{
            fontSize: FONT_SIZE_25,
            color: (isShowInitialLoading || isFetchingData || pageLoading) ? colorWhite : colorPrimarySpin,
            marginTop: isShowInitialLoading ? '4rem' : '0',
        }}
        />
    );

  return <Spin indicator={antIcon} />
}
