import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import ReactGA from 'react-ga';

import { useAppDispatch, useAppSelector } from "../../redux";
import { APP_REQUEST_METHODS } from "../../config";
import { useWebSocket } from "../../context/WebSocket";
import { ScheduleSection } from "./Schedule";




export const SchedulerWrapperComponent = () => {
    const location = useLocation();

    const dispatch = useAppDispatch();


    const { socketRef } = useWebSocket();


    const reduxData = useAppSelector((state => state.app));


    const [isLoading, setIsLoading] = useState(true)


    useEffect(() => {


        if (!isLoading) {

            if (!reduxData?.userData?.userToken) {

                const reqAllData = {
                    type: APP_REQUEST_METHODS.REQ_ALL_DATA,
                }

                if (socketRef.current && socketRef.current.readyState === 1) {


                    console.log('CALL SENT FROM SCHEDULER -->', reqAllData);


                    socketRef.current.send(JSON.stringify(reqAllData));

                    socketRef.current.onerror = (event) => {
                        console.error("WebSocket error in CONVERSATION WRAPPER SECTION: ======================", event);
                    }

                }

            }

        }




    }, [dispatch, isLoading, reduxData?.userData?.userToken, socketRef]);


    useEffect(() => {
        const handleWebSocketOpen = () => {
            console.log('WebSocket connection is open.');
            setIsLoading(false); // Update loading state when WebSocket connection is open
        };

        const handleWebSocketError = (event) => {
            console.error('WebSocket error:', event);
        };

        if (socketRef.current) {
            socketRef.current.addEventListener('open', handleWebSocketOpen);
            socketRef.current.addEventListener('error', handleWebSocketError);

            return () => {
                socketRef.current.removeEventListener('open', handleWebSocketOpen);
                socketRef.current.removeEventListener('error', handleWebSocketError);
            };
        }
    }, [socketRef]);



    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);


    return <ScheduleSection />
}