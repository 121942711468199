import React from 'react'

export default function SearchIcon() {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none" style={{width:"100%"}}>
          <path fillRule="evenodd" clipRule="evenodd" d="M1.65625 15.1381C1.65528 8.68453 6.21325 3.12897 12.5427 1.86905C18.8721 0.609134 25.2101 3.99577 27.6805 9.95779C30.151 15.9198 28.0658 22.7967 22.7003 26.3828C17.3347 29.9688 10.1832 29.2653 5.61933 24.7024C3.08214 22.1661 1.65655 18.7256 1.65625 15.1381Z" stroke="white" strokeWidth="2.77422" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M24.75 24.7031L32.4802 32.4333" stroke="white" strokeWidth="2.77422" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
  )
}
