import debounce from 'lodash.debounce';



// Assets

// import bankIcon from "./assets/svg/FoldersSvgs/banks.svg";
// import userIcon from "./assets/svg/FoldersSvgs/banks.svg";
// import otherTelecom from "./assets/svg/FoldersSvgs/banks.svg";
// import othersIcon from "./assets/svg/FoldersSvgs/others.svg";

// Category Folders Icon
import appIcon from "./assets/svg/FolderCategorySvgs/App.svg";
import bankIcon from "./assets/svg/FolderCategorySvgs/Bank.svg";
import cinemasIcon from "./assets/svg/FolderCategorySvgs/Cinemas.svg";
import clothingIcon from "./assets/svg/FolderCategorySvgs/Clothing.svg";
import cosmeticsIcon from "./assets/svg/FolderCategorySvgs/Cosmetics.svg";
import courierIcon from "./assets/svg/FolderCategorySvgs/Courier.svg";
import educationIcon from "./assets/svg/FolderCategorySvgs/Education.svg";
import electronicsIcon from "./assets/svg/FolderCategorySvgs/Electronics.svg";
import entertainmentIcon from "./assets/svg/FolderCategorySvgs/Entertainment.svg";
import finaneIcon from "./assets/svg/FolderCategorySvgs/Finance.svg";
import finane1Icon from "./assets/svg/FolderCategorySvgs/Finance-1.svg";
import foodAuthorityIcon from "./assets/svg/FolderCategorySvgs/Food authority.svg";
import foodIcon from "./assets/svg/FolderCategorySvgs/food.svg";
import foodWearIcon from "./assets/svg/FolderCategorySvgs/Foot wear.svg";
import fuelIcon from "./assets/svg/FolderCategorySvgs/Fuel.svg";
import govtIcon from "./assets/svg/FolderCategorySvgs/govt.svg";
import healthCareIcon from "./assets/svg/FolderCategorySvgs/healthcare.svg";
import hospitalIcon from "./assets/svg/FolderCategorySvgs/Hospitals.svg";
import hotelIcon from "./assets/svg/FolderCategorySvgs/Hotels.svg";
import jazzIcon from "./assets/svg/FolderCategorySvgs/jazz.svg";
import laboratoriesIcon from "./assets/svg/FolderCategorySvgs/Laboratories.svg";
import mobileWalletIcon from "./assets/svg/FolderCategorySvgs/Mobile Wallet.svg";
import newsIcon from "./assets/svg/FolderCategorySvgs/news.svg";
import othersIcon from "./assets/svg/FolderCategorySvgs/Others.svg";
import pharmaIcon from "./assets/svg/FolderCategorySvgs/Pharma.svg";
import radioIcon from "./assets/svg/FolderCategorySvgs/Radio.svg";
import realEstateIcon from "./assets/svg/FolderCategorySvgs/Real Estate.svg";
import recreationalIcon from "./assets/svg/FolderCategorySvgs/Recreational.svg";
import restaurantIcon from "./assets/svg/FolderCategorySvgs/Restaurant.svg";
import retailIcon from "./assets/svg/FolderCategorySvgs/Retail.svg";
import shoesIcon from "./assets/svg/FolderCategorySvgs/Shoes.svg";
import socialMediaIcon from "./assets/svg/FolderCategorySvgs/Social Media.svg";
import sportIcon from "./assets/svg/FolderCategorySvgs/Sport.svg";
import telcoIcon from "./assets/svg/FolderCategorySvgs/Telco.svg";
import textileIcon from "./assets/svg/FolderCategorySvgs/Textile.svg";
import trackerIcon from "./assets/svg/FolderCategorySvgs/Tracker.svg";
import transportIcon from "./assets/svg/FolderCategorySvgs/Transport.svg";
import travelIcon from "./assets/svg/FolderCategorySvgs/Travel.svg";
// import tvChannelIcon from "./assets/svg/FolderCategorySvgs/TV channel.svg";
import vasIcon from "./assets/svg/FolderCategorySvgs/VAS.svg";
import welfareIcon from "./assets/svg/FolderCategorySvgs/Welfare.svg";
import callIcon from "./assets/svg/FolderCategorySvgs/call.svg";
import eyeIcon from "./assets/svg/FolderCategorySvgs/eye.svg";
import arrowIcon from "./assets/svg/FolderCategorySvgs/arrow.svg";
import cameraIcon from "./assets/svg/FolderCategorySvgs/camera.svg";
import basketIcon from "./assets/svg/FolderCategorySvgs/basket.svg";
import bicycleIcon from "./assets/svg/FolderCategorySvgs/bicycle.svg";
import clockIcon from "./assets/svg/FolderCategorySvgs/clock.svg";
import giftIcon from "./assets/svg/FolderCategorySvgs/gift.svg";
import homeIcon from "./assets/svg/FolderCategorySvgs/home.svg";
import ice_creamIcon from "./assets/svg/FolderCategorySvgs/ice_cream.svg";
import ironIcon from "./assets/svg/FolderCategorySvgs/iron.svg";
import likeIcon from "./assets/svg/FolderCategorySvgs/like.svg";
import lockIcon from "./assets/svg/FolderCategorySvgs/lock.svg";
import money_bagIcon from "./assets/svg/FolderCategorySvgs/money_bag.svg";
import pizzaIcon from "./assets/svg/FolderCategorySvgs/pizza.svg";
import sodaIcon from "./assets/svg/FolderCategorySvgs/soda.svg";
import sortIcon from "./assets/svg/FolderCategorySvgs/sort.svg";
import trashIcon from "./assets/svg/FolderCategorySvgs/trash.svg";
import tvIcon from "./assets/svg/FolderCategorySvgs/tv.svg";
import userIcon from "./assets/svg/FolderCategorySvgs/user.svg";
import carIcon from "./assets/svg/FolderCategorySvgs/car.svg";
import moment from "moment";
// 




export const getMessageNotificationInfo = (status) => {
    switch (status) {
        case 1:
            return { text: 'Inbox message', variant: 'info' };
        case 2:
            return { text: 'Sent message', variant: 'success' };
        case 3:
            return { text: 'Draft message', variant: 'info' };
        case 4:
            return { text: 'Outbox message', variant: 'info' };
        case 5:
            return { text: 'Failed message', variant: 'error' };
        case 6:
            return { text: 'Queued message', variant: 'info' };
        default:
            return { text: 'Something went wrong! Try Again.', variant: 'error' };
    }
};



export const formatMessageTime = (timestamp) => {
    const momentTimestamp = moment.unix(timestamp / 1000);
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');

    if (momentTimestamp.isSame(today, 'day')) {
        return momentTimestamp.format("MMM D hh:mm A");
    } else if (momentTimestamp.isSame(yesterday, 'day')) {
        return "Yesterday " + momentTimestamp.format("hh:mm A");
    } else {
        return momentTimestamp.format("MMM D hh:mm A");
    }
};




export const getFolderIcon = (folderID) => {
    const folderIconMapping = {
        'Apps': appIcon,
        'Banks': bankIcon,
        'Car dealer': othersIcon, //
        'Cinemas': cinemasIcon,
        'Clothing': clothingIcon,
        'Cosmetics': cosmeticsIcon,
        'Courier': courierIcon,
        'Education': educationIcon,
        'Electronics': electronicsIcon,
        'Entertainment': entertainmentIcon,
        'Food': foodIcon,
        'Food authority': foodAuthorityIcon,
        'Footwear': foodWearIcon,
        'Furniture': othersIcon,
        'Government': govtIcon,
        'Healthcare': healthCareIcon,
        'Hospitals': hospitalIcon,
        'Hotels': hotelIcon,
        'Jazz': jazzIcon,
        'Laboratories': laboratoriesIcon,
        'Mobile Wallet': mobileWalletIcon,
        'News': newsIcon,
        'Others': othersIcon,
        'Other Telecom': othersIcon,
        'Pharma': pharmaIcon,
        'Radio': radioIcon,
        'Real Estate': realEstateIcon,
        'Recreational': recreationalIcon,
        'Restaurant': restaurantIcon,
        'Retail': retailIcon,
        'Shoes': shoesIcon,
        'Social Media': socialMediaIcon,
        'Sports': sportIcon,
        'Telco': telcoIcon,
        'Textile': textileIcon,
        'Tracker': trackerIcon,
        'Transport': transportIcon,
        'Travel': travelIcon,
        'TV channel': tvIcon,
        'Utilities': othersIcon,
        'Utility': othersIcon,
        'Vas': vasIcon,
        'Welfare': welfareIcon,
        'iron': ironIcon,
        'ice_cream': ice_creamIcon,
        'store': othersIcon,
        'clock': clockIcon,
        'eye': eyeIcon,
        'gift': giftIcon,
        'pizza_slice': pizzaIcon,
        'bicycle': bicycleIcon,
        'users': userIcon,
        'home': homeIcon,
        'lock': lockIcon,
        'tv': tvIcon,
        'camera': cameraIcon,
        'basketball': basketIcon,
        'trash': trashIcon,
        'kid_bag': othersIcon,
        'car_side': carIcon,
        'call': callIcon,
        'line': othersIcon,
        'lightbulb_on': othersIcon,
        'shopping_bag': othersIcon,
        'suitcase': othersIcon,
        'soda': sodaIcon,
        'money_bag': money_bagIcon,
        'trophy': othersIcon,
        'like': likeIcon,
        'sort': sortIcon,
        'arrow': arrowIcon,
        'ic_folder': othersIcon,
        'default': othersIcon

    };

    // Check if the folder name exists in the mapping, otherwise use the default icon
    return folderIconMapping[folderID] || folderIconMapping.default;
};



const DEBOUNCE_DELAY = 1000;
export const debouncedSearch = debounce((value, setSearchBy) => {
    setSearchBy(value);
}, DEBOUNCE_DELAY);



export const findContactName = (allContacts, targetNumber, appShortCodes) => {

    const matchingContact = allContacts && allContacts.find(contact => contact.number === targetNumber);
    // If there's a matching contact, return its name
    if (matchingContact) {
      return matchingContact.name || targetNumber;
    }
  
  
    // If there's no matching contact, and appShortCodes is an array, check each shortcode in appShortCodes
    if (Array.isArray(appShortCodes)) {
      for (const shortcode of appShortCodes) {
  
        if (shortcode.short_code === "jazz" && shortcode.short_code === "Jazz" && shortcode.short_code === "JAZZ") {
          return shortcode.short_code
        }
  
        // If the shortcode's number matches the targetNumber, update its name and return the new name
        else if (shortcode.short_code === targetNumber) {
  
          if (shortcode.client !== null && shortcode.client !== '-') {
            shortcode.name = `${shortcode.client} - ${shortcode.short_code}`;
            return shortcode.name;
          }
          else {
            return shortcode.short_code
          }
        }
  
      }
    }
  
  
  
    // If no matching contact or shortcode was found, return the targetNumber
    return targetNumber;
  
  
    // return matchingContact ? matchingContact.name : targetNumber
  }

  