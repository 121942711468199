import React from 'react'


import { Outlet } from "react-router-dom";


import SiderLayout from './sider';


export default function Index() {
    return <SiderLayout outlet={<Outlet />} />
}

