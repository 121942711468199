import React from 'react'

export default function HomeIcon() {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M3.02637 3.62745L5.83984 2.06212C7.21189 1.30221 8.87851 1.30221 10.2506 2.06212L13.064 3.62745C14.4067 4.36145 15.2451 5.7665 15.2534 7.2967V11.6034C15.2514 12.7819 14.7584 13.9063 13.893 14.7063C13.0578 15.4837 11.958 15.9141 10.817 15.9101H5.27241C4.13136 15.9141 3.03158 15.4837 2.19633 14.7063C1.33095 13.9063 0.837997 12.7819 0.835938 11.6034V7.2967C0.844478 5.76629 1.68328 4.36118 3.02637 3.62745Z" stroke="white" strokeWidth="1.54473" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M5.64062 15.9112V14.4695C5.64062 13.1423 6.71652 12.0664 8.04371 12.0664C9.3709 12.0664 10.4468 13.1423 10.4468 14.4695V15.9112" stroke="white" strokeWidth="1.54473" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
  )
}
