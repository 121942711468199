import React from 'react'

export default function EyeIcon() {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M15 8.71469C15 11.0817 11.866 13.0007 8 13.0007C4.134 13.0007 1 11.0817 1 8.71469C1 6.34769 4.134 4.42969 8 4.42969C11.866 4.42969 15 6.34769 15 8.71469Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path fillRule="evenodd" clipRule="evenodd" d="M9.74969 8.71409C9.76431 9.425 9.34734 10.0741 8.6947 10.3563C8.04207 10.6386 7.28353 10.4979 6.77554 10.0003C6.26756 9.50279 6.11112 8.74734 6.37974 8.08898C6.64836 7.43062 7.28863 7.00025 7.99968 7.00009C8.45905 6.9953 8.9015 7.17319 9.22969 7.49463C9.55789 7.81607 9.74494 8.25473 9.74969 8.71409V8.71409Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M7.25 4.429C7.25 4.84321 7.58579 5.179 8 5.179C8.41421 5.179 8.75 4.84321 8.75 4.429H7.25ZM8.75 1C8.75 0.585786 8.41421 0.25 8 0.25C7.58579 0.25 7.25 0.585786 7.25 1H8.75ZM14.7931 3.05471C14.9813 2.68571 14.8347 2.23403 14.4657 2.04586C14.0967 1.85769 13.645 2.00428 13.4569 2.37329L14.7931 3.05471ZM12.0199 5.19129C11.8317 5.5603 11.9783 6.01197 12.3473 6.20014C12.7163 6.38831 13.168 6.24172 13.3561 5.87271L12.0199 5.19129ZM2.54314 2.37329C2.35497 2.00428 1.90329 1.85769 1.53429 2.04586C1.16528 2.23403 1.01869 2.68571 1.20686 3.05471L2.54314 2.37329ZM2.64386 5.87271C2.83203 6.24172 3.28371 6.38831 3.65271 6.20014C4.02172 6.01197 4.16831 5.5603 3.98014 5.19129L2.64386 5.87271ZM8.75 4.429V1H7.25V4.429H8.75ZM13.4569 2.37329L12.0199 5.19129L13.3561 5.87271L14.7931 3.05471L13.4569 2.37329ZM1.20686 3.05471L2.64386 5.87271L3.98014 5.19129L2.54314 2.37329L1.20686 3.05471Z" fill="white" />
      </svg>
  )
}
