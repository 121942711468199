import React, { useCallback, useEffect, useRef, useState } from 'react';



/******** Context ********/
import { addData } from "../../redux/AppSlice";
import { useWebSocket } from '../../context/WebSocket';
import { useAppDispatch, useAppSelector } from '../../redux';




/******** Packages ********/
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import { enqueueSnackbar } from 'notistack';



import { APP_INTERNAL_ROUTES, APP_STATUS } from '../../config';



/******** Assets ********/
import queueIcon from "../../assets/svg/queue.svg";
import ProfilePicIcon from "../../assets/placeholder-user.svg";
import sendMessageIcon from "../../assets/sendMessageIcon.svg";
import DoubleTickIcon from "../../assets/images/Chat/DoubleTickIcon.svg";
import BackButtonIcon from "../../assets/images/Chat/BackButtonIcon.svg";
import { Link } from 'react-router-dom';
import { findContactName } from '../../utils';
import appLogo from "../../assets/logo.svg";




// ----------------------------------------------------------------------------------------------------------------

// NEW MESSAGE HEADER
const ChatHeader = (props) => {

    const { name, number } = props;

    const dispatch = useAppDispatch();

    const appSelector = useAppSelector(state => state.app);


    // const handleCloseSectionToShow = () => {
    //     if (appSelector.currentSectionToShow === SECTION_STATUS.IS_NEW_MESSAGE) {
    //         dispatch(handleSectionToShow(SECTION_STATUS.CONVERSATION))
    //     }
    // }





    return (
        <div className="chatHeader-div">
            {appSelector?.contactToSendMessage ? (
                <div className="name-and-back-button-div">
                    <Link to={APP_INTERNAL_ROUTES.CONVERSATION}>
                        <img
                            src={BackButtonIcon}
                            alt="backButtonIcon"
                            // onClick={handleCloseSectionToShow}
                            style={{ width: "12px", margin: "2px 2px 2px 20px", cursor: 'pointer' }}
                        />
                    </Link>
                    <img
                        src={ProfilePicIcon}
                        alt="Pic"
                    />
                    <div className='name-text'>
                        <h4 className="">{findContactName(appSelector?.userData?.contacts, name) || 'Salaam'}</h4>
                        <p className="">{number || '03xx xxxxxxxx'}</p>
                    </div>
                </div>
            ) : null}

        </div>
    );
};




export const NewMessageSection = () => {


    const dispatch = useAppDispatch();
    const messagesEndRef = useRef(null);
    const { socketRef } = useWebSocket();
    const { contactToSendMessage, conversations: reduxConversations } = useAppSelector(state => state.app);

    const [newMessage, setNewMessage] = useState();
    const appSelector = useAppSelector(state => state.app);


    // const [messagesData, setMessagesData] = useState(initialData?.conversation[contactToSendMessage]?.messages);
    const currentNumberConversation = appSelector?.conversations.some(conv => conv.conversation.number === contactToSendMessage?.number);
    const [messagesData, setMessagesData] = useState(currentNumberConversation?.message);



    const formatMessageTime = (timestamp) => {
        const momentTimestamp = moment.unix(timestamp / 1000);
        const today = moment().startOf('day');
        const yesterday = moment().subtract(1, 'days').startOf('day');

        if (momentTimestamp.isSame(today, 'day')) {
            return momentTimestamp.format("MMM D hh:mm A");
        } else if (momentTimestamp.isSame(yesterday, 'day')) {
            return "Yesterday " + momentTimestamp.format("hh:mm A");
        } else {
            return momentTimestamp.format("MMM D hh:mm A");
        }
    };


    const updateGroupedFolderData = useCallback((userData) => {

        // Update groupedFolderData state
        const groupedFolderData = userData.userData.folders.map(folder => {
            const folderConversations = userData?.conversations.filter(conversation => {
                const contactNumber = conversation.conversation.number;
                return folder.contactList.some(contact => contact.number === contactNumber);
            });

            return { ...folder, conversations: folderConversations };
        });

        // Update existingData state
        userData = {
            ...userData,
            groupedFolderData: groupedFolderData
        };

        // Update state
        // setUserData(userData);
        // sessionStorage.setItem("userData", JSON.stringify(userData));
        dispatch(addData(userData));
    }, [dispatch]);




    // const updateUserWholeData = useCallback((receivedConversation, receivedMessages) => {




    //     const commonConversationNumber = receivedConversation?.number;



    //     // Retrieve existing data from sessionStorage
    //     const existingDataString = sessionStorage.getItem("userData");
    //     const existingData = existingDataString ? JSON.parse(existingDataString) : {};

    //     // Check if the conversation already exists in the data
    //     if (existingData?.conversation[commonConversationNumber]) {
    //         // Conversation exists, update the messages (create a deep copy)
    //         const oldMessages = JSON.parse(JSON.stringify(existingData?.conversation[commonConversationNumber].messages));


    //         // Check if the message ID is already present in the existing messages
    //         // const isMessageExists = oldMessages.find(message => message.id === newMessage.id);

    //         const isMessageExists = oldMessages.find(oldMessage => {
    //             return receivedMessages.some(receivedMessage => receivedMessage.id === oldMessage.id);
    //         });



    //         if (isMessageExists && isMessageExists?.type === 6) {



    //             // Update the message statuses based on message IDs
    //             const updatedMessagesWithStatus = oldMessages.map(message => {
    //                 // Check if the message ID needs a status update and type is not equal to 1
    //                 if (isMessageExists.id === message.id && message.type === 6) {
    //                     return { ...message, type: 2 };
    //                 }
    //                 return message;
    //             });


    //             // Set the updated messages with status
    //             existingData.conversation[commonConversationNumber].messages = updatedMessagesWithStatus;

    //         } else {

    //             // Message ID not found, push the new message to the array
    //             existingData.conversation[commonConversationNumber].messages.push(...receivedMessages);
    //         }


    //         // Update the conversation with the new messages
    //         existingData.conversation[commonConversationNumber] = {
    //             ...existingData.conversation[commonConversationNumber],
    //             messages: existingData.conversation[commonConversationNumber].messages,
    //         };
    //     } else {
    //         // Conversation doesn't exist, create a new one
    //         existingData.conversation[commonConversationNumber] = {
    //             conversation: receivedConversation,
    //             messages: receivedMessages,
    //         };
    //     }

    //     // Set the messages data in the state
    //     // setMessagesData(existingData.conversation[conversationNumber].messages);

    //     // Update sessionStorage with the modified data
    //     // sessionStorage.setItem("userData", JSON.stringify(existingData));


    //     updateGroupedFolderData(existingData);
    // }, [updateGroupedFolderData]);


    const handleSendNewMessage = () => {


        if (newMessage.trim() === "") {
            return;
        }

        const currentTime = new Date().getTime();
        const uniqueID = uuidv4();

        // const newMessageData = {
        //     text: newMessage,
        //     time: currentTime,
        //     id: uniqueID,
        //     number: contactToSendMessage?.number,
        //     delivered: false,
        //     subscription: -1,
        //     type: APP_STATUS.SENT
        // };

        const message = {
            type: 'Chat',
            data: {
                text: newMessage,
                number: contactToSendMessage?.number,
                delivered: false,
                id: uniqueID,
                subscription: -1,
                time: currentTime,
                type: APP_STATUS.SENT
            }
        };


        // -------------------------------------
        if (socketRef.current && socketRef.current.readyState === 1) {

            // Update local storage with the new message
            // updateUserWholeData(
            //     contactToSendMessage?.number,
            //     newMessageData
            // );


            // addMessageWithDateToData(newMessageData);
            // updateUserWholeData(currentChatData?.conversation?.number, newMessageData);

            socketRef.current.send(JSON.stringify(message));

            // Set up event handlers to check for success or failure
            socketRef.current.onclose = (event) => {
                console.error("WebSocket closed ======================", event);
                enqueueSnackbar('WebSocket is Closed, Try connecting again.', {
                    variant: 'error',
                });
            };

            socketRef.current.onerror = (event) => {
                console.error("WebSocket error: ======================", event);
                // showMessage({ status: 'error', messageToDisplay: 'Some error occurred.' });
            }
            setNewMessage("");

        }

        socketRef.current.onclose = (event) => {
            console.error("WebSocket closed ======================", event);
            enqueueSnackbar('WebSocket is Closed, Try connecting again.', {
                variant: 'error',
            });
            // showMessage({ status: 'error', messageToDisplay: 'Some error occurred.' });
        };



    }







    // -------------------------------------
    const updateUserWholeData = useCallback((receivedConversation, receivedMessages) => {
        let copyOfReduxData = JSON.parse(JSON.stringify(appSelector));
        if (Array.isArray(receivedConversation)) {
            console.log('=================== CONVERSATION IS ARRAY ===================================== ');
            // Update conversations array
            receivedConversation.forEach(conversation => {

                console.log('single conversation =========>>>>', conversation);

                const { number } = conversation;
                const existingIndex = copyOfReduxData.conversations.findIndex(conv => conv.conversation.number === number);
                if (existingIndex !== -1) {
                    // Update existing conversation
                    const existingConversation = copyOfReduxData.conversations[existingIndex];
                    const updatedMessages = receivedMessages ? [...existingConversation.message, ...receivedMessages] : existingConversation.message;
                    copyOfReduxData.conversations[existingIndex] = { ...existingConversation, message: updatedMessages };
                } else {
                    // Add new conversation to array
                    copyOfReduxData.conversations.push({ conversation: conversation?.conversation, message: conversation?.message || [] });
                }
            });
        } else {

            console.log('=================== CONVERSATION IS OBJECT ===================================== ');
            // Handle object scenario
            const { number } = receivedConversation;
            const existingIndex = copyOfReduxData.conversations.findIndex(conv => conv.conversation.number === number);
            if (existingIndex !== -1) {
                // Update existing conversation
                const existingConversation = copyOfReduxData.conversations[existingIndex];

                // const updatedMessages = receivedMessages ? [...existingConversation.message, ...receivedMessages] : existingConversation.message;

                // copyOfReduxData.conversations[existingIndex] = { ...existingConversation, message: updatedMessages };


                let updatedMessages = [...existingConversation.message];

                // Iterate through received messages
                if (receivedMessages) {
                    for (const receivedMessage of receivedMessages) {
                        // Check if the received message ID already exists in the existing messages and has status 6
                        const existingMessageIndex = updatedMessages.findIndex(msg => msg.id === receivedMessage.id && msg.type === 6);
                        if (existingMessageIndex !== -1) {
                            // Update the status of the existing message to 2
                            updatedMessages[existingMessageIndex] = { ...updatedMessages[existingMessageIndex], type: 2 };
                        } else {
                            // Add the received message to the updated messages array
                            updatedMessages.push(receivedMessage);
                        }
                    }
                }



                console.log('updatedMessages', updatedMessages);


                // Update the conversation in the copy of Redux data
                copyOfReduxData.conversations[existingIndex] = { ...existingConversation, message: updatedMessages };



            } else {
                // Add new conversation to array
                copyOfReduxData.conversations.push({ conversation: receivedConversation, message: receivedMessages || [] });
            }
        }

        console.log('copyOfReduxData', copyOfReduxData.conversations);

        // Dispatch action to update Redux store
        dispatch(addData(copyOfReduxData));
    }, [appSelector, dispatch]);





    // 
    useEffect(() => {
        // setMessagesData(initialData?.conversations[contactToSendMessage?.number]?.messages);

        // Find conversation based on number ID
        const conversation = appSelector?.conversations?.find(
            (conv) => conv.conversation.number === contactToSendMessage?.number
        );

        console.log('conversation.message', conversation?.message);

        setMessagesData(conversation?.message);

    }, [appSelector?.conversations, contactToSendMessage?.number, messagesData?.length]);









    // const groupMessagesByDate = (messages) => {
    //     let groupedMessages = {};

    //     messages.forEach((message) => {
    //         const messageDate = moment.unix(message.time / 1000);
    //         const formattedDate = messageDate.format("YYYY-MM-DD");
    //         if (!groupedMessages[formattedDate]) {
    //             groupedMessages[formattedDate] = [];
    //         }
    //         groupedMessages[formattedDate].push(message);
    //     });

    //     // Sort messages within each date group by timestamp
    //     for (const date in groupedMessages) {
    //         groupedMessages[date].sort((a, b) => a.time - b.time);
    //     }

    //     // Sort dates, putting today's messages at the bottom
    //     const sortedDates = Object.keys(groupedMessages).sort((a, b) => {
    //         const isTodayA = moment(a).isSame(moment(), 'day');
    //         const isTodayB = moment(b).isSame(moment(), 'day');

    //         if (isTodayA && !isTodayB) {
    //             return 1;
    //         } else if (!isTodayA && isTodayB) {
    //             return -1;
    //         } else {
    //             return moment(b).format("YYYY-MM-DD") - moment(a).format("YYYY-MM-DD");
    //         }
    //     });

    //     // Reverse the order of sorted dates
    //     const reversedDates = sortedDates.reverse();

    //     const sortedGroupedMessages = {};
    //     reversedDates.forEach((date) => {
    //         sortedGroupedMessages[date] = groupedMessages[date];
    //     });

    //     return sortedGroupedMessages;
    // };

    const groupMessagesByDate = (messages) => {
        let groupedMessages = {};

        messages.forEach((message) => {
            const messageDate = moment.unix(message.time / 1000);
            const formattedDate = messageDate.format("YYYY-MM-DD");
            if (!groupedMessages[formattedDate]) {
                groupedMessages[formattedDate] = [];
            }
            groupedMessages[formattedDate].push(message);
        });

        // Sort messages within each date group by timestamp
        for (const date in groupedMessages) {
            groupedMessages[date].sort((a, b) => a.time - b.time);
        }

        // Sort dates, putting today's messages at the bottom
        const sortedDates = Object.keys(groupedMessages).sort((a, b) => {
            const isTodayA = moment(a).isSame(moment(), 'day');
            const isTodayB = moment(b).isSame(moment(), 'day');

            if (isTodayA && !isTodayB) {
                return 1;
            } else if (!isTodayA && isTodayB) {
                return -1;
            } else {
                return moment(b).format("YYYY-MM-DD") - moment(a).format("YYYY-MM-DD");
            }
        });

        // Reverse the order of sorted dates
        const reversedDates = sortedDates.reverse();

        const sortedGroupedMessages = {};
        reversedDates.forEach((date) => {
            sortedGroupedMessages[date] = groupedMessages[date];
        });

        return sortedGroupedMessages;
    };

    // const sortMessagesByDate = (messages) => {
    //     return Object.keys(messages).sort((a, b) => {
    //         return moment(b).format("YYYY-MM-DD") - moment(a).format("YYYY-MM-DD");
    //     });
    // };


    const sortMessagesByDate = (messages) => {
        return Object.keys(messages).sort((a, b) => {
            const isTodayA = moment(a).isSame(moment(), 'day');
            const isTodayB = moment(b).isSame(moment(), 'day');

            if (isTodayA && !isTodayB) {
                return 1;
            } else if (!isTodayA && isTodayB) {
                return -1;
            } else {
                return moment(b).format("YYYY-MM-DD") - moment(a).format("YYYY-MM-DD");
            }
        });
    };


    const groupMessageData = groupMessagesByDate(messagesData || []);
    const groupMessageDates = sortMessagesByDate(groupMessageData).reverse();




    // useEffect(() => {
    //     const updatedMessagesData =  initialData?.conversations?.find(
    //         (conv) => conv.conversation.number === contactToSendMessage?.number
    //     );

    //     if (updatedMessagesData?.message?.length !== messagesData?.length) {
    //         setMessagesData(updatedMessagesData);
    //     }
    // }, [contactToSendMessage?.number, messagesData?.length, initialData?.conversations]);




    useEffect(() => {


        console.log(' ------------- Effect IS RUNNING IN NEW MESSAGE --------------');


        if (socketRef.current && socketRef.current.readyState === 1) {
            socketRef.current.onmessage = (event) => {
                const receivedEvent = JSON.parse(event.data);
                const parsedData = JSON.parse(receivedEvent);


                console.log(' ============ ANDROID DATA RECEIVED IN NEW_MESSAGE ------> ', parsedData);


                if (parsedData?.conversations?.number) {
                    updateUserWholeData(parsedData?.conversations, parsedData?.message);
                }

                if (parsedData?.conversation?.number) {
                    updateUserWholeData(parsedData?.conversation, parsedData?.message);
                }

                if (Array.isArray(parsedData?.conversations)) {
                    updateUserWholeData(parsedData?.conversations);
                }


            };

            // Set up event handlers to check for success or failure
            socketRef.current.onclose = (event) => {
                console.error("WebSocket closed ======================", event);
                enqueueSnackbar('WebSocket is Closed, Try connecting again.', {
                    variant: 'error',
                });
            };

            socketRef.current.onerror = (event) => {
                console.error("WebSocket error: ======================", event);
            };
        }


    }, [socketRef, updateUserWholeData]);



    console.log('messsage datat', messagesData);



    return (
        <div className="chat-container">


            <ChatHeader
                name={contactToSendMessage?.name}
                number={contactToSendMessage?.number}
            />

            {contactToSendMessage ? (
                <>
                    <div className="chats" >
                        {groupMessageDates?.length > 0 ? groupMessageDates.map((date) => {
                            const messages = groupMessageData[date];
                            const reversedMessages = [...messages].reverse();
                            const today = moment().format("YYYY-MM-DD");
                            const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");

                            return (
                                <React.Fragment key={uuidv4()}>

                                    {reversedMessages.map((mess) => {
                                        return (
                                            <React.Fragment key={uuidv4()}>
                                                <div
                                                    className={mess.type === APP_STATUS.INBOX ? "chat-left-text" : "chat-right-text"}
                                                >
                                                    <p className="chat-text">{mess.text}</p>
                                                    <p className="chat-time-text">
                                                        {/* {moment.unix(mess.time / 1000).format("hh:mm A")} */}
                                                        {formatMessageTime(mess.time)}
                                                        {mess.type === APP_STATUS.SENT && (
                                                            <img
                                                                src={DoubleTickIcon}
                                                                alt="doubleTickIcon"
                                                                style={{ marginLeft: "8px" }}
                                                            />
                                                        )}
                                                        {mess.type === APP_STATUS.QUEUED && (
                                                            <img
                                                                src={queueIcon}
                                                                alt="queueIcon"
                                                                style={{ marginLeft: "8px" }}
                                                            />
                                                            // 
                                                        )}
                                                    </p>
                                                </div>
                                            </React.Fragment>
                                        );
                                    })}
                                    <div className="date-text-div">
                                        <p className="date-text">
                                            {date === today ? "Today" : date === yesterday ? "Yesterday" : date}
                                        </p>
                                    </div>
                                </React.Fragment>
                            );
                        }) : (
                            <div style={{ color: 'white', width: "100%", textAlign: 'center', margin: '2rem 0', }}>No Conversation available for this contact, start new one!</div>
                        )}
                        <div ref={messagesEndRef} />
                    </div>


                    <form onSubmit={handleSendNewMessage}>
                        <div className="message-input">
                            <input
                                className="send-message"
                                placeholder="Message"
                                value={newMessage}
                                onInput={(e) => setNewMessage(e.target.value)}
                            />
                            <button
                                type='submit'
                                disabled={!newMessage}
                                className="cursor-pointer send-btn"
                                onClick={() => {
                                    handleSendNewMessage();
                                }}
                            >
                                <img
                                    src={sendMessageIcon}
                                    alt="sendMessage"
                                    style={{
                                        height: 50,
                                        width: 50,
                                    }}
                                />
                            </button>
                        </div>
                    </form>
                </>
            ) : (
                <div style={{ display: 'flex', color: "white", width: "100%", textAlign: 'center', alignItems: 'center', flexDirection: 'column', height: 'calc(100% - 77px)', justifyContent: 'center' }}>

                    <img src={appLogo} alt="site-logo" />

                    <h1 style={{ margin: '1rem 0 0.5rem 0' }}>Ersaal for Web</h1>
                    <h3 style={{ margin: 0 }}>Select contact and start your conversation right away!</h3>
                </div>
            )}

        </div>
    )
}
