import React from 'react'

export default function LockIcon() {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="17" viewBox="0 0 14 17" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M9.01445 15.9097H4.65831C2.70062 15.8764 1.14024 14.263 1.17236 12.3053V8.70094C1.15451 7.08029 2.22597 5.64914 3.78605 5.20984V4.73612C3.73405 2.99857 5.09891 1.54708 6.83638 1.49219C8.57385 1.54708 9.9387 2.99857 9.88671 4.73612V5.20984C11.4468 5.64914 12.5182 7.08029 12.5004 8.70094V12.3053C12.5325 14.263 10.9721 15.8764 9.01445 15.9097Z" stroke="white" strokeWidth="1.54473" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M6.05812 12.3048C6.05812 12.7313 6.40392 13.0771 6.83049 13.0771C7.25705 13.0771 7.60285 12.7313 7.60285 12.3048H6.05812ZM7.60285 10.5026C7.60285 10.076 7.25705 9.73022 6.83049 9.73022C6.40392 9.73022 6.05812 10.076 6.05812 10.5026H7.60285ZM9.68268 5.95549C10.095 6.06495 10.5179 5.81947 10.6274 5.40718C10.7368 4.9949 10.4914 4.57195 10.0791 4.46248L9.68268 5.95549ZM9.00965 5.0957V5.86807L9.01036 5.86807L9.00965 5.0957ZM4.65248 5.0957L4.65177 5.86807H4.65248V5.0957ZM3.58305 4.46248C3.17077 4.57195 2.92528 4.9949 3.03475 5.40718C3.14421 5.81947 3.56717 6.06495 3.97945 5.95549L3.58305 4.46248ZM7.60285 12.3048V10.5026H6.05812V12.3048H7.60285ZM10.0791 4.46248C9.72993 4.36978 9.37019 4.32301 9.00894 4.32334L9.01036 5.86807C9.23732 5.86786 9.46333 5.89725 9.68268 5.95549L10.0791 4.46248ZM9.00965 4.32334H4.65248V5.86807H9.00965V4.32334ZM4.65319 4.32334C4.29194 4.32301 3.9322 4.36978 3.58305 4.46248L3.97945 5.95549C4.1988 5.89725 4.42481 5.86786 4.65177 5.86807L4.65319 4.32334Z" fill="white" />
      </svg>
  )
}
