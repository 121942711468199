import React, { useState } from "react";


import { Form, Input, Button } from "antd";

/******** Assets ********/
import "../../styles/profile.scss";
import UserIcon from "../../assets/user-circle-placeholder.svg";
import { useWebSocket } from "../../context/WebSocket";
import { getProfile, updateProfile } from "../../api/requests";
import { enqueueSnackbar } from "notistack";
import { useAppDispatch, useAppSelector } from "../../redux";
import { addData } from "../../redux/AppSlice";



// ----------------------------------------------------------------------------------------------------------------


export const ProfileSection = () => {

  const [form] = Form.useForm();
  const { socketRef } = useWebSocket();

  const reduxData = useAppSelector(state => state.app);

  const {
    userData: reduxUserData,
    contacts: reduxContacts,
    currentFolder: reduxCurrentFolder,
    conversations: reduxConversations,
    groupedFolderData: reduxGroupedFolderData,
  } = useAppSelector((state => state.app));



  const [file, setFile] = useState(null);
  const dispatch = useAppDispatch();
  const [profilePicUpdating, setProfilePicUpdating] = useState(false);
  const [profileUrl, setProfileUrl] = useState(reduxUserData?.userProfile?.profile_picture || "");






  console.log('reduxUserData in PROFILE SECTION =======  > > > > >', reduxUserData);


  const onFinish = async (values) => {

    const profileObj = new FormData();


    profileObj.append('userId', reduxUserData?.userProfile?.id)
    profileObj.append('name', values.name)
    profileObj.append('email', values.email)
    // profileObj.append('number', userProfile.number)
    if (file?.name) {
      profileObj.append('file', file || null)
    }



    const PROFILE_OBJ = {
      userId: reduxUserData?.userProfile.id,
      name: values.name,
      email: values.email,
      created_at: new Date().toISOString()
    }

    const PROFILE_OBJ_JSON = {
      userId: reduxUserData?.userProfile.id,
    }








    // -------------------------------------
    if (socketRef.current && socketRef.current.readyState === 1) {

      try {
        setProfilePicUpdating(true);

        const response = await updateProfile(profileObj, reduxUserData?.userToken);


        console.log('response =========  > > > > >', response);


        if (response.success) {
          enqueueSnackbar(`Profile Updated Successfully.`, { variant: "success" });

          const getProfileResponse = await getProfile(PROFILE_OBJ_JSON, reduxUserData?.userToken);


          const sendMessageToAndroid = {
            type: 'ProfileUpdated',
            data: getProfileResponse?.userProfile
          }




          console.log('final data sent to websocket for setting', sendMessageToAndroid);

          socketRef.current.send(JSON.stringify(sendMessageToAndroid));

          // dispatch(addData())
          // Update profile picture in user data
          const updatedUserData = {
            ...reduxData,
            userData: {
              ...reduxUserData,
              userProfile: getProfileResponse?.userProfile
            }
          };


          // Dispatch action to update user data in Redux state
          dispatch(addData(updatedUserData));


        }

        if (response.message === 'Unauthorized - invalid token') {
          throw new Error('Token expired, please login again!')
        } else {
          console.error("Profile pic not updated:", response.message);
          return;
        }
      } catch (error) {
        console.error("Failed to run updating of profile pic:", error);

        enqueueSnackbar(`${error || 'Something went wrong, please try again!'}`, {
          variant: 'error',
        });


        return;
      } finally {
        setProfilePicUpdating(false);
      }

      // socketRef.current.onclose = (event) => {
      //   console.error("WebSocket closed ======================", event);

      // };

      // socketRef.current.onerror = (event) => {
      //   console.error("WebSocket error: ======================", event);
      //   // showMessage({ status: 'error', messageToDisplay: 'Some error occurred.' });
      // }

    }


    else {
      enqueueSnackbar('WebSocket is Closed, Try connecting again.', {
        variant: 'error',
      });
    }


    // socketRef.current.onclose = (event) => {
    //   console.error("WebSocket closed ======================", event);
    //   // showMessage({ status: 'error', messageToDisplay: 'Some error occurred.' });
    // };





  };


  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };




  return (
    <Form
      form={form}
      name="profileForm"
      className="profile-container"
      style={{ width: '100%' }}
      onFinish={onFinish}
      initialValues={{
        name: reduxUserData?.userProfile?.name || "",
        email: reduxUserData?.userProfile?.email || "",
        phone: reduxUserData?.userProfile?.number || "",
      }}
    >
      <div className="profile-pic-wrapper">

        <img src={profileUrl || UserIcon} alt="profilePicture" className="profile-picture" style={{ marginBottom: `${file?.name ? 0 : '20px'}` }} />

        <div className="edit-option">
          <label htmlFor="fileInput" className="edit-label">Edit</label>
          <input id="fileInput" type="file" onChange={onFileChange} accept="image/*" style={{ display: "none" }} />
        </div>
      </div>

      {file?.name && <p className="file-name" style={{ marginBottom: '20px' }}>{file?.name}</p>}

      <p className="label-text secondary-text">Username</p>
      <Form.Item
        name="name"
        className="primary-text "
        style={{ width: '100%' }}
        rules={[
          {
            required: true,
            message: "Please enter your username",
          },
        ]}
      >
        <Input className="input-text" placeholder="Enter Username" />
      </Form.Item>

      <p className="label-text secondary-text">Email</p>
      <Form.Item
        name="email"
        style={{ width: '100%' }}
        rules={[
          {
            type: "email",
            message: "Please enter a valid email address",
          },
          {
            required: true,
            message: "Please enter your email address",
          },
        ]}
      >
        <Input className="input-text" placeholder="Enter Email Address" />
      </Form.Item>

      <p className="label-text secondary-text">Mobile Number</p>



      <Form.Item
        name="phone"
        style={{ width: '100%' }}
        rules={[
          {
            required: true,
            message: "Please enter your mobile number",
          },
        ]}
      >
        <Input disabled className="input-text" placeholder="03XX-XXXXXXX" />
      </Form.Item>

      {/* <Form.Item className="save-button"> */}
      <Button type="primary" htmlType="submit" className="save-button btn">
        Save
      </Button>
      {/* </Form.Item> */}

    </Form >

  );
};
