export const APP_STATUS = {
    INBOX: 1,
    SENT: 2,
    DRAFT: 3,
    OUTBOX: 4,
    FAILED: 5,
    QUEUED: 6,
}



export const SECTION_STATUS = {
    INBOX: 'INBOX',
    CONVERSATION: 'CONVERSATION',
}


export const APP_REQUEST_METHODS = {
    REQ_ALL_DATA: 'reqAllData',
    MESSAGE_DELETE: 'messageDelete',
    MESSAGE_REQUEST: 'messageRequest',
    CONVERSATION_DELETE: 'conversationDelete',
    INITIAL_DATA_REQUEST: 'initialDataRequest',
    CONVERSATION_REQUEST: 'conversationRequest',
    FOLDER_CONVERSATION_REQUEST: 'folderConversationRequest',
}


export const APP_INTERNAL_ROUTES = {
    HOME: '/home',
    FOLDER: '/folder',
    SETTINGS: '/settings',
    SCHEDULER: '/scheduler',
    NEW_MESSAGE: '/new-message',
    CONVERSATION: '/conversation',
    CREATE_FOLDER: '/create-folder',
}