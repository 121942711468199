import { useMemo, memo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';


import { Divider, Dropdown, Space } from 'antd';


import { v4 as uuidv4 } from 'uuid';


import { findContactName, formatMessageTime } from "../utils";
import { APP_INTERNAL_ROUTES } from '../config';


import { UserPlaceholderIcon } from '../assets/svg';


// ----------------------------------------------------------------------------------------------------------------


export const AppSingleConversation = memo(({
    folderId,
    singlePost,
    contacts,
    appShortCodes,
    handleConversationValue,
    handleConversationClick,
    handleConversationDelete,
    handleCancelConversationDelete,
}) => {

    const location = useLocation();
    const navigate = useNavigate();

    // const { user: userInfo } = useAppSelector((state) => state.auth.auth)


    // console.log('data in single conversation div ========== > > > > > > > ', data);
    // console.log('data in single conversation div sortedConversations ========== > > > > > > > ', sortedConversations);
    // const isUserPremium = userInfo?.isPremium;




    const item = singlePost;
    const singleItem = singlePost?.message[0];


    // console.log('singleItem in single conversation div', item);
    // console.log('singleItem in single conversation div', singleItem);


    const items = [
        {
            label: (
                <div
                    onClick={(e) => {
                        console.log('conversationValues=============== >>>>>> e', e);
                        handleConversationDelete(e);
                    }}
                    style={{ display: "flex", alignItems: "center", gap: "8px", borderBottom: '1px solid rgb(202 202 202 / 34%)', padding: '0 2rem' }}
                >
                    <p>Delete</p>
                </div>
            ),
            key: "1",
        },
        {
            label: (
                <div
                    onClick={handleCancelConversationDelete}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        paddingTop: "10px", padding: '0 2rem'
                    }}
                >
                    <p>Cancel</p>
                </div>
            ),
            key: "2",

        },
    ];



    // Memoized function to format time
    const formatTime = useMemo(() => formatMessageTime, []);



    return (
        <Dropdown
            menu={{ items }}
            key={uuidv4()}
            rootClassName="conversation-dropdown"
            trigger={['contextMenu']}
            overlayStyle={{
                width: 'auto'
            }}
            destroyPopupOnHide
            dropdownRender={(menu) => (
                <div>
                    <Divider style={{ margin: 0 }} />
                    <Space direction="vertical" size="middle" style={{ display: 'flex', background: '#000' }}>
                        <div
                            onClick={(e) => {
                                handleConversationValue(item);
                                handleConversationDelete(e)
                            }}
                            style={{ display: "flex", alignItems: "center", gap: "8px", borderBottom: '1px solid rgb(202 202 202 / 34%)', padding: '0 2rem' }}
                        >
                            <p>Delete</p>
                        </div>
                        <div
                            onClick={handleCancelConversationDelete}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                                paddingTop: "10px", padding: '0 2rem'
                            }}
                        >
                            <p>Cancel</p>
                        </div>
                    </Space>
                </div>
            )}
        >
            <Link
                to={`${APP_INTERNAL_ROUTES.CONVERSATION}/${parseInt(folderId)}/${item?.conversation.number}`}
                // style={style}
                className="single-conversation"
                onClick={(e) => handleConversationClick(e, item)}
            >
                <div style={styles.rowBox}>
                    <div className="user-icon">
                        <UserPlaceholderIcon />
                    </div>
                    <div style={styles.columnBoxWithMargin}>
                        <div style={styles.rowBox}>
                            <p className="user-name-text primary-text" alt="username">
                                {findContactName(contacts, item?.conversation?.number, appShortCodes?.data)}
                            </p>
                        </div>
                        <p className="single-message-text secondary-text">{singleItem?.text}</p>
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '1rem', alignItems: 'flex-end', gap: '5px' }}>
                    {/* <p className="time-text">{(formatTime(singleItem?.time))}</p> */}
                    {singleItem?.time && <p className="time-text">{formatTime(singleItem?.time)}</p>}
                </div>
            </Link>
        </Dropdown>
    )
})




const styles = {
    rowBox: {
        display: "flex",
        flexDirection: "row",
        textAlign: "initial",
        width: '75%',
        ":hover": {
            pinIcon: {
                backgroundColor: "#000"
            }
        }
    },
    pinIcon: {
        width: 15,
        height: 20,
        marginLeft: 10,
    },
    columnBox: {
        display: "flex",
        flexDirection: "column",
        textAlign: "initial",
        width: "100%"
    },
    columnBoxWithMargin: {
        display: "flex",
        flexDirection: "column",
        textAlign: "initial",
        width: "100%",
        gap: "3px",
        justifyContent: "center"
    }
};