import React from 'react'

export default function EarlyMorningIcon() {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="53" height="36" viewBox="0 0 53 36" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M14.5188 33.2946H38.55V30.7198C38.7797 23.8495 33.4042 18.0901 26.5344 17.8459C19.6646 18.0901 14.2892 23.8495 14.5188 30.7198V33.2946Z" fill="#86FFC6" stroke="#86FFC6" strokeWidth="3.82797" strokeLinecap="round" strokeLinejoin="round" />
          <path fillRule="evenodd" clipRule="evenodd" d="M26.5311 2.39606V8.57551V2.39606ZM43.5274 9.6363L39.2773 14.0065L43.5274 9.6363ZM9.53754 9.6363L13.7876 14.0065L9.53754 9.6363ZM50.5603 33.294H38.5447H50.5603ZM14.5156 33.294H2.5H14.5156Z" fill="#86FFC6" />
          <path d="M28.4451 2.39606C28.4451 1.33899 27.5882 0.48207 26.5311 0.48207C25.474 0.48207 24.6171 1.33899 24.6171 2.39606H28.4451ZM24.6171 8.57551C24.6171 9.63258 25.474 10.4895 26.5311 10.4895C27.5882 10.4895 28.4451 9.63258 28.4451 8.57551H24.6171ZM44.8995 10.9707C45.6365 10.2129 45.6196 9.00114 44.8618 8.26417C44.104 7.5272 42.8922 7.54409 42.1553 8.3019L44.8995 10.9707ZM37.9052 12.6721C37.1682 13.4299 37.1851 14.6417 37.9429 15.3787C38.7007 16.1156 39.9124 16.0987 40.6494 15.3409L37.9052 12.6721ZM10.9097 8.3019C10.1727 7.54409 8.96095 7.5272 8.20314 8.26417C7.44534 9.00114 7.42845 10.2129 8.16542 10.9707L10.9097 8.3019ZM12.4155 15.3409C13.1525 16.0987 14.3642 16.1156 15.122 15.3787C15.8798 14.6417 15.8967 13.4299 15.1598 12.6721L12.4155 15.3409ZM50.5603 35.208C51.6174 35.208 52.4743 34.3511 52.4743 33.294C52.4743 32.2369 51.6174 31.38 50.5603 31.38V35.208ZM38.5447 31.38C37.4877 31.38 36.6307 32.2369 36.6307 33.294C36.6307 34.3511 37.4877 35.208 38.5447 35.208V31.38ZM14.5156 35.208C15.5727 35.208 16.4296 34.3511 16.4296 33.294C16.4296 32.2369 15.5727 31.38 14.5156 31.38V35.208ZM2.5 31.38C1.44293 31.38 0.586013 32.2369 0.586013 33.294C0.586013 34.3511 1.44293 35.208 2.5 35.208V31.38ZM24.6171 2.39606V8.57551H28.4451V2.39606H24.6171ZM42.1553 8.3019L37.9052 12.6721L40.6494 15.3409L44.8995 10.9707L42.1553 8.3019ZM8.16542 10.9707L12.4155 15.3409L15.1598 12.6721L10.9097 8.3019L8.16542 10.9707ZM50.5603 31.38H38.5447V35.208H50.5603V31.38ZM14.5156 31.38H2.5V35.208H14.5156V31.38Z" fill="#86FFC6" />
      </svg>
  )
}
