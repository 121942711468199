import React, { useEffect, useState } from "react";


/******** Ant Design ********/
import { Button, Form, Input, Select, Space } from "antd";


/******** Socket ********/
import { useWebSocket } from "../context/WebSocket";



/******** Packages ********/
import { v4 as uuidv4 } from 'uuid';
import { enqueueSnackbar } from 'notistack';



/******** Mock ********/
import { FOLDER_ICONS } from "../mock/FolderIcons";



/******** Styles and Assets ********/
import "../styles/CreateFolder.scss";
import ProfilePicIcon from "../assets/images/Chat/ProfilePicIcon.svg";
import BackButtonIcon from "../assets/images/Chat/BackButtonIcon.svg";
import { DeleteIcon } from "../assets/svg";
import UserIcon from "../assets/useIcon2.svg";
import { useAppDispatch, useAppSelector } from "../redux";
import { handleHasFolderCreated, handleSectionToShow } from "../redux/AppSlice";
import { APP_INTERNAL_ROUTES, SECTION_STATUS } from "../config";
import { Link } from "react-router-dom";



const { Option } = Select;



// ----------------------------------------------------------------------------------------------------------------

export const CreateFolderSection = () => {

  const [form] = Form.useForm();

  const [loading, setIsLoading] = useState(false);
  const [folderIconValue, setFolderIconValue] = useState('');

  const { socketRef, userData } = useWebSocket();
  const dispatch = useAppDispatch();

  const reduxData = useAppSelector(state => state.app);


  // socketRef.current.onmessage = (event) => {

  //   const parsednData = JSON.parse(event?.data);


  //   const parsedData = JSON.parse(parsednData);


  //   console.log('parsedData in', parsedData);


  //   if (parsedData?.folders && parsedData?.folders.name) {
  //     const userAndFoldersData = {
  //       ...reduxData.userData,
  //       folders: [...reduxData.userData.folders, ...parsedData?.folders],
  //     };
  //     reduxData.userData = userAndFoldersData;
  //   }


  // }





  // ==============================
  const handleCreateFolder = async (values) => {


    setIsLoading(true);



    const refactoredContacts = values?.contacts?.map(singleContact => ({
      contactId: singleContact?.title?.contactId,
      name: singleContact?.title?.name,
      number: singleContact?.title?.number,
      isSection: false,
      // profilePicUrl: singleContact?.title?.profilePicUrl,
      // conversationData: []
    }));


    // Convert your form data to a string or format you want to send via WebSocket
    const message = {
      type: 'CreateFolder',
      data: {
        name: values.folderName,
        isPinned: false,
        isAvailable: true,
        icon: folderIconValue,
        contactList: refactoredContacts,
      }
    };






    console.log('Final data to send to socket ============>', message);

    if (socketRef.current && socketRef.current.readyState === 1) {
      console.log("message has been sent to the server -------------_> ", JSON.stringify(message));
      socketRef.current.send(JSON.stringify(message));


      dispatch(handleHasFolderCreated(true));
      
      //      // Set up event handlers to check for success or failure
      // socketRef.current.onclose = (event) => {
      //   console.error("WebSocket closed ======================", event);
      // };

      socketRef.current.onerror = (event) => {
        console.error("WebSocket error: ======================", event);

      }

      enqueueSnackbar(`Folder Created Successfully.`, { variant: "success", autoClose: 3000 });


        setIsLoading(false);
        setFolderIconValue('')
        form.resetFields();


    } else {
      console.error("WebSocket connection not open.");
      setIsLoading(false);
    }



    socketRef.current.onclose = (event) => {
      console.error("WebSocket closed ======================", event);
      enqueueSnackbar('WebSocket is Closed, Try connecting again.', {
        variant: 'error',
      });
    };




  }


  // useEffect(() => {

  //   console.log('useEffect is running in create Folder');


  //   // if (socketRef.current && socketRef.current.readyState === 1) {


  //     socketRef.current.onmessage = (event) => {
  //       const receivedEvent = JSON.parse(event.data);
  //       const parsedData = JSON.parse(receivedEvent);


  //       console.log('Data received after sending from Android ------> ', parsedData);

      
  //       if (parsedData?.folders && parsedData?.folders.name) {
  //         const userAndFoldersData = {
  //           ...reduxData?.userData,
  //           folders: [...reduxData.userData.folders, ...parsedData?.folders],
  //         };
  //         reduxData.userData = userAndFoldersData;
  //       }



  //     };

  //     // Set up event handlers to check for success or failure
  //     socketRef.current.onclose = (event) => {
  //       console.error("WebSocket closed ======================", event);
  //     };

  //     socketRef.current.onerror = (event) => {
  //       console.error("WebSocket error: ======================", event);
  //     };


  //   // }


  // }, [socketRef, loading, reduxData])

  
  const filterContactOption = (input, option) => {
    const inputValue = input.toLowerCase();


    console.log('--------optionsss----------', option);

    // Check if option.label and option.label.props are defined
    if (option.label && option.props) {
      const contactName = option.props.children[1]?.toLowerCase() || ''; // Extracting the contact name
      const contactNumber = option.props.children[1]?.toLowerCase() || ''; // Extracting the contact number

      return (
        contactName.includes(inputValue) || // Check if the contact name contains the input value
        contactNumber.includes(inputValue) // Check if the contact number contains the input value
      );
    }

    return false;
  };

  



  const handleContactChange = (x) => {
    console.log('contact -------< ', x);
  };


  const handleFolderIcon = (icon) => {
    setFolderIconValue(icon)


    // Manually setting form value
    form.setFields([
      {
        name: 'folderIcon',
        value: icon,
        errors: [],
      },
    ]);
  }


  const usersArray = [
    {
      contactName: "Atif",
      contactNumber: '03347404607',
      profilePicUrl: ProfilePicIcon
    },
    {
      contactName: "Abdullah",
    },
    {
      contactName: "Zia",
    },
    {
      contactName: "Awais",
    },
    {
      contactName: "Bilal",
    },
    {
      contactName: "Ali",
    },
    {
      contactName: "Faraz",
    },
    {
      contactName: "Hassan",
    },
    {
      contactName: "Salman",
    },
  ];


  const userSearch = [];
  (reduxData?.contacts || usersArray)?.map((item, index) =>
    userSearch.push(
      <Option key={uuidv4()} value={item?.name || item?.number} label={item?.name || item?.number}>
        <Space
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            color: "#fff",
            width:"100%",
            // background:"none"
          }}
        >
          <img
            src={item?.profilePicUrl || UserIcon}
            alt="Pic"
            style={{ width: "30px" }}
          />
          {item?.name || item?.number}

        </Space>
      </Option>
    )
  );


  return (
    <div className="create-folder-container">


      <Link className="create-folder-header" to={APP_INTERNAL_ROUTES.CONVERSATION}>
        <img
          src={BackButtonIcon}
          alt="arrow left"
          style={{ width: "12px", margin: "2px 2px 2px 20px", cursor: 'pointer' }}
          // onClick={() => dispatch(handleSectionToShow(SECTION_STATUS.CONVERSATION))}

        />
        <p className="folder-text primary-heading">Create Folder</p>
      </Link>
      <Form form={form} onFinish={handleCreateFolder} autoComplete="off" layout="horizontal" className="create-folder-form">

        <div style={{ height: '100%' }}>
          <div className="folder-label-and-input-div">
            <label className="folder-label">
              Folder Name <label style={{ color: "rgba(249, 53, 53, 1)" }}>*</label>
            </label>
            <Form.Item
              name="folderName"
              style={{ width: '100%', fontSize: "20px" }}
              rules={[{ required: true, message: "Folder name is required" }]}
            >
              <Input className="folder-input" style={{ width: '100%' }} />
            </Form.Item>
          </div>


          <div className="add-contacts-label-and-icon-div">
            <label className="assign-icon-label">Assign Icon</label>

            <Form.Item
              name="folderIcon"
              style={{ width: '100%', fontSize: "20px" }}
              rules={[{ required: true, message: "Folder icon is required" }]}
            >
              <div className="icon-div">
                {FOLDER_ICONS.map(item => (
                  <div key={uuidv4()}
                    onClick={(e) => handleFolderIcon(item?.value, e)}
                    className={`${folderIconValue === item?.value && 'folder-active'}`}
                  >
                    {item?.img}
                  </div>
                ))}
              </div>
            </Form.Item>
          </div>

          <div className="add-contacts-label-and-search-div">
            <label className="add-contacts-label">
              Add Contacts{" "}
              <label style={{ color: "rgba(249, 53, 53, 1)" }}>*</label>
            </label>

            <Form.Item
              name="contacts"
              rules={[{ required: true, message: "Add at least one contact" }]}
              style={{ width: '100%' }}
            >
              <Select
                // ref={dropdownRef}
                // onDropdownVisibleChange={handleDropdownVisibleChange}
                mode="multiple"
                style={{ width: "100%" }}
                // open={true}
                // popupClassName="folder-dropdown"
                className="add-contacts-search-div"
                onChange={(x) => handleContactChange(x)}
                // clearIcon={<DeleteIcon />}
                removeIcon={<DeleteIcon />}
                // suffixIcon={false}
                // removeIcon={false}
                listHeight={"100px"}
                labelInValue
                // filterOption={filterContactOption}
                // open
                dropdownStyle={{
                  background: " rgba(38, 38, 41, 1)",
                  color: "#fff",
                  // width: "90%",
                  fontSize: '15px',
                  // maxHeight:150,
                  // zIndex:1
                  // background:"none"
                }}
              // optionLabelProp="label"
              // dropdownRender={(menu) => (
              //   <div
              //     style={{
              //       ...menu,
              //       backgroundColor: "rgba(38, 38, 41, 0.60)",
              //       color: "#fff",
              //       background: "none",
              //     }}
              //   >
              //     {menu}
              //   </div>
              // )}
              >
                {userSearch}
              </Select>
            </Form.Item>
          </div>
        </div>
        {/* <div className=""> */}
        <Button htmlType="submit" className="add-folder-btn-div" loading={loading}>Create Folder +</Button>
        {/* </div> */}

      </Form >

    </div>
  );
};
