import React, { lazy, Suspense } from "react";
import { Navigate} from "react-router-dom";


import PrivateRouter from "./PrivateRouter";


import { AppLoading } from "../components";


import Layout from "../layout";
import LoginSubscription from "../screens/LoginSubscription";



const Loadable = (Component) => (props) =>
(
  <Suspense
    fallback={
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "4rem",
        }}
      >
        <AppLoading pageLoading />
      </div>
    }
  >
    <Component {...props} />
  </Suspense>
);

/******** Screens ********/
const LoginScreen = Loadable(lazy(() => import("../screens/Login")));
const HomeScreen = Loadable(lazy(() => import("../screens/HomeScreen")));
const ChatScreen = Loadable(lazy(() => import("../screens/ChatScreen")));
const NotFoundScreen = Loadable(lazy(() => import("../screens/NotFound")));
const SubscriptionScreen = Loadable(
  lazy(() => import("../screens/Subscription"))
);


const ConversationScreen = Loadable(lazy(() => import("../screens/Conversation")));


const CreateFolderScreen = Loadable(
  lazy(() => import("../screens/CreateFolder"))
);
const NewMessageScreen = Loadable(lazy(() => import("../screens/new-message")));
const SettingsScreen = Loadable(lazy(() => import("../screens/Settings")));
const SchedulerScreen = Loadable(lazy(() => import("../screens/Scheduler")));
const AccountDeletionPolicy = Loadable(
  lazy(() => import("../sections/AccountDeletionPolicy"))
);

// import LoginWithSocket from "../screens/Login";

/******** Sections ********/
const SideBar = Loadable(lazy(() => import("../sections/SideBar")));
const ProfileSection = Loadable(
  lazy(() => import("../sections/Settings/Profile"))
);
const ConversationSection = Loadable(
  lazy(() => import("../sections/Conversation/Conversation"))
);
const BlockedAccounts = Loadable(
  lazy(() => import("../sections/Settings/BlockedAccounts"))
);

// ----------------------------------------------------------------------------------------------------------

// const MainRoutes = () => {
//   return (
//     <Routes>
//       {/* <Route exact path="/" element={<LoginScreen />} /> */}

//       <Route exact path="/h" element={<Layout />}>

//         <Route index path="home" element={<p>HOME SCREEEN</p>} />

//         <Route path="create-folder" element={<CreateFolderScreen />} />

//         <Route path="conversation" element={<ConversationScreen />}>
//           <Route exact path=":id" element={<ConversationScreen />} />
//         </Route>

//       </Route>

//       <Route exact path="/sideBar" element={<SideBar />} />
//       <Route exact path="/deletion-policy" element={<AccountDeletionPolicy />} />
//       <Route exact path="/conversations" element={<ConversationSection />} />
//       <Route exact path="/chat" element={<ChatScreen />} />
//       <Route exact path="/profile" element={<ProfileSection />} />
//       <Route exact path="/blockedAccounts" element={<BlockedAccounts />} />
//       <Route exact path="/subscription" element={<SubscriptionScreen />} />

//       {/* Route for handling 404 pages */}
//       <Route path="*" element={<NotFoundScreen />} />
//     </Routes>
//   );
// };

export const APP_ROUTES = [
  { path: "/", element: <Navigate to="/loginSubscription" /> },
  { path: "loginSubscription", element: <LoginSubscription /> },
  // { path: "/", element: <Navigate to='/login' /> },

  { path: "login", element: <LoginScreen /> },

  { path: "subscription", element: <SubscriptionScreen /> },
  { path: "deletion-policy", element: <AccountDeletionPolicy /> },

  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "home",
        element: (
          <PrivateRouter>
            <HomeScreen />
          </PrivateRouter>
        ),
      },

      {
        path: "create-folder",
        element: (
          <PrivateRouter>
            <CreateFolderScreen />
          </PrivateRouter>
        ),
      },

      // {
      //   path: "subscription",
      //   element: (
      //     <>
      //       <SubscriptionScreen />
      //     </>
      //   ),
      // },

      {
        path: "scheduler",
        element: (
          <PrivateRouter>
            <SchedulerScreen />
          </PrivateRouter>
        ),
      },

      {
        path: "new-message",
        element: (
          <PrivateRouter>
            <NewMessageScreen />
          </PrivateRouter>
        ),
      },

      {
        path: "settings",
        element: (
          <PrivateRouter>
            <SettingsScreen />
          </PrivateRouter>
        ),
      },

      {
        path: "conversation",
        element: (
          <PrivateRouter>
            <ConversationScreen />
          </PrivateRouter>
        ),

        children: [
          {
            path: ":folderId",
            element: (
              <PrivateRouter>
                <ConversationScreen />
              </PrivateRouter>
            ),
          },
        ],
      },
    ],
  },
];

// export default MainRouter;
