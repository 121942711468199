import { ArrowBackIcon, BasketBallIcon, BicycleIcon, CallIcon, CameraIcon, CarIcon, ClockIcon, DeleteIcon, EyeIcon, GiftIcon, HomeIcon, IceCreamIcon, IronIcon, KidBagIcon, LightIcon, LikeIcon, LockIcon, MoneyBagIcon, PizzaSliceIcon, ShoppingBagIcon, SodaIcon, SortIcon, StoreIcon, SuitcaseIcon, TrophyIcon, TvIcon, UserIcon } from "../assets/svg/FolderIcons";

export const FOLDER_ICONS = [
    {
        id: "01",
        value: "arrow",
        img: <ArrowBackIcon />
    },

    {
        id: "02",
        value: "basketball",
        img: <BasketBallIcon />
    },

    {
        id: "03",
        value: "bicycle",
        img: <BicycleIcon />
    },

    // {
    //     id: "04",
    //     value: "shopping_bag",
    //     img: <BoxIcon />
    // },

    {
        id: "05",
        value: "call",
        img: <CallIcon />
    },
    {
        id: "06",
        value: "camera",
        img: <CameraIcon />
    },
    {
        id: "07",
        value: "car_side",
        img: <CarIcon />
    },
    {
        id: "08",
        value: "clock",
        img: <ClockIcon />
    },
    {
        id: "09",
        value: "trash",
        img: <DeleteIcon />
    },
    {
        id: "10",
        value: "eye",
        img: <EyeIcon />
    },
    {
        id: "11",
        value: "gift",
        img: <GiftIcon />
    },
    {
        id: "12",
        value: "home",
        img: <HomeIcon />
    },
    {
        id: "13",
        value: "ice_cream",
        img: <IceCreamIcon />
    },
    {
        id: "14",
        value: "iron",
        img: <IronIcon />
    },
    {
        id: "15",
        value: "kid_bag",
        img: <KidBagIcon />
    },
    {
        id: "16",
        value: "lightbulb_on",
        img: <LightIcon />
    },
    {
        id: "17",
        value: "like",
        img: <LikeIcon />
    },
    {
        id: "18",
        value: "lock",
        img: <LockIcon />
    },
    {
        id: "19",
        value: "money_bag",
        img: <MoneyBagIcon />
    },
    {
        id: "20",
        value: "pizza_slice",
        img: <PizzaSliceIcon />
    },
    {
        id: "21",
        value: "shopping_bag",
        img: <ShoppingBagIcon />
    },
    {
        id: "22",
        value: "soda",
        img: <SodaIcon />
    },
    {
        id: "23",
        value: "sort",
        img: <SortIcon />
    },
    {
        id: "24",
        value: "store",
        img: <StoreIcon />
    },
    {
        id: "25",
        value: "suitcase",
        img: <SuitcaseIcon />
    },
    {
        id: "26",
        value: "trophy",
        img: <TrophyIcon />
    },
    {
        id: "27",
        value: "tv",
        img: <TvIcon />
    },
    {
        id: "28",
        value: "users",
        img: <UserIcon />
    },
]

// 